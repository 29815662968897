Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function () {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.VPŽaidimas.showSpeechBubble = (mod) => function() {
        const [...text] = arguments;
        const textToShow = text.map(t => Sk.Angis.Utils.tryRemapToJs(t)).join(" ");

        if (Sk.tracing) {
            Sk.tracer.emit('žaidimas:showSpeechBubble', { text: textToShow }, SKTRACEID);
        }

        const scene = Sk.VPŽaidimas.getOrCreateScene();

        const closeable = Pixi.createSpeechBubble(scene, textToShow);

        Sk.misceval.callsimOrSuspendArray(Sk.Angis.PromiseCloser(mod), []).init(closeable, 10000);

        return Sk.misceval.promiseToSuspension(closeable.promise);
    }

    Sk.VPŽaidimas.showSpeechBubbleNonBlocking = (mod) => function(){
        const [...text] = arguments;
        const textToShow = text.map(t => Sk.Angis.Utils.tryRemapToJs(t)).join(" ");

        if (Sk.tracing) {
            Sk.tracer.emit('žaidimas:showSpeechBubble', { text: textToShow }, SKTRACEID);
        }

        const scene = Sk.VPŽaidimas.getOrCreateScene();

        const closeable = Sk.Angis.Pixi.createSpeechBubble(scene, textToShow);

        return Sk.misceval.callsimOrSuspendArray(Sk.Angis.PromiseCloser(mod), []).init(closeable, 10000);
    }

    Sk.VPŽaidimas.Tekstas = (mod) => Sk.misceval.buildClass(mod, function ($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function (kwa) {
            let { self, tekstas, x, y, dydis, spalva, tag } = Utils.mapArgs(arguments, kwa, [
                                                            { tekstas: "" }, 
                                                            { x: -1 }, { y: -1 }, 
                                                            { dydis: 17 },
                                                            { spalva: "#00FF00" },
                                                            { tag: undefined }]);

            tekstas = (tekstas || "") + "";
            x = x - 0;
            y = y - 0;
            dydis = dydis - 0;
            TekstasConstructor(self, tekstas, x, y, dydis, spalva, tag);
        });

        $loc.duokKoordinates = Utils.createMethod(getCoordinates);

        $loc.sukis = Utils.createMethodKWA(rotate);
        $loc.rotate = $loc.sukis;
        $loc.pasisuk = $loc.sukis;

        $loc.pasislėpk = Utils.createMethod(function (self) { self.hide(); });
        $loc.slėpkis = $loc.pasislėpk;
        $loc.hide = $loc.pasislėpk;

        $loc.teleportuokis = Utils.createMethodKWA(teleport);
        $loc.atsirask = $loc.teleportuokis;
        $loc.show = $loc.atsirask;
        $loc.teleport = $loc.atsirask;

        $loc.arMatomas = Utils.createMethod(function(self) { return new Sk.builtin.bool (self && self.v && self.v.isVisible && self.v.isVisible()); });
        $loc.yraMatomas = $loc.arMatomas;
        $loc.matomas = $loc.arMatomas;
        $loc.isVisible = $loc.arMatomas;
        $loc.visible = $loc.arMatomas;

        $loc.spalva = Utils.createMethodKWA(setColor);
        $loc.duokSpalvą = Utils.createMethod(getColor);

        $loc.dydis = Utils.createMethodKWA(setSize);
        $loc.duokDydį = Utils.createMethod(getSize);

        $loc.tekstas = Utils.createMethod(setText);
        $loc.rodykTekstą = $loc.tekstas;
        $loc.užrašas = $loc.tekstas;

        $loc.duokTekstą = Utils.createMethod(getText);
        $loc.duokUžrašą = $loc.duokTekstą;

        $loc.duokKliūtį = Utils.createMethod(getObstacleArea);

    }, "Tekstas", []);

    Sk.VPŽaidimas.Tekstas.ctor = TekstasConstructor;

    function TekstasConstructor(self, tekstas, x, y, dydis, spalva, tag) {
        self.dydis = dydis || 17;
        self.spalva = spalva || "00FF00";

        // Tags are only used in tracing. They are generally not exposed to python.
        // Correct applyTag implementation will use Symbol (prefered) or 
        // WeakMap (for max hiding) to attach a tag to the instance so that it was 
        // not accessible to python script.
        if (Sk.tracing && tag) {
            Sk.tracer.emit('tag:apply', { target: self, tag: tag }, SKTRACEID); 
        }

        const textStyle = new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: dydis || 17,
            // fontStyle: 'italic',
            // fontWeight: 'bold',
            fill: spalva,
            stroke: spalva,
            // strokeThickness: 2

            dropShadow: true,
            dropShadowAlpha: 0.8,
            dropShadowAngle: 2.1,
            dropShadowBlur: 4,
            dropShadowColor: "0x111111",
            dropShadowDistance: 3,
        });

        self.container = Pixi.createContainer();
        
        self.textBox = new PIXI.Text(tekstas, textStyle);
        self.container.addChild(self.textBox);

        // my creator will add(tekstas.container) to it's own container
        self.container.x = x;
        self.container.y = y;
        self.container.zIndex = 1;

        if (self.x <= 0 && self.y <= 0)
            self.container.visible = false;

        self.obstacleArea = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };

        self.hitArea = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };

        // virtual (overridable) methods
        self.show = () => show(self);
        self.hide = () => hide(self);
        self.isVisible = function(){ return self.container.visible; };

        // Workaround for remapToJs
        self.v = self;

        if (Sk.tracing) {
            Sk.tracer.emit('Tekstas:new', { target: self, text: tekstas }, SKTRACEID);
        }
        
        Sk.VPŽaidimas.getOrCreateLayer().v.addElement(self, x, y);

        self.container.zOrder = 1000;
    }

    function getCoordinates(self) {
        return new Sk.builtin.tuple([
            new Sk.builtin.int_(self.container.x),
            new Sk.builtin.int_(self.container.y)
        ]);
    }

    function teleport(kwa) {
        const { self, x, y } = Utils.mapArgs(arguments, kwa, [{ x: null }, { y: null }]);

        if (Sk.tracing) {
            Sk.tracer.emit('tekstas:teleport', { target: self, x: x, y: y }, SKTRACEID);
        }

        return __teleportTo(self, x, y);
    }

    function __teleportTo(self, newX, newY) {
        self.show();

        if (newX == null && newY == null)
            return true; // TODO: shoulnd't it be 'return false'? cannot  move to undefined coordinates!

        if (self.layer && !self.layer.canTeleportTo(self, newX, newY))
            return false;

        if (!self.layer)
            console.error("no layer!");

        self.container.x = newX;
        self.container.y = newY;

        return true;
    }

    function setColor(kwa) {
        const { self, spalva } = Utils.mapArgs(arguments, kwa, [{ spalva: null }]);
        if (spalva != null){
            self.textBox.style.fill = spalva;
            self.textBox.style.stroke = spalva;
        }

        return getColor(self);
    }

    function setSize(kwa) {
        const { self, dydis } = Utils.mapArgs(arguments, kwa, [{ dydis: null }]);
        if (dydis != null)
            self.textBox.style.fontSize = dydis;

        return getSize(self);
    }

    function setText() {
        const [self, ...text] = arguments;
        const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");

        if (Sk.tracing) {
            Sk.tracer.emit('tekstas:setText', { target: self, text: textToShow }, SKTRACEID);
        }

        if (textToShow != null)
            self.textBox.text = textToShow;

        return getText(self);
    }

    function getColor(self) {
        return new Sk.builtin.str(self.textBox.style.color);
    }

    function getSize(self) {
        return new Sk.builtin.int_(self.textBox.style.fontSize);
    }

    function getText(self) {
        return new Sk.builtin.str(self.textBox.text);
    }

    function getObstacleArea(self) {
        return new Sk.builtin.tuple([
            new Sk.builtin.int_(self.container.x),
            new Sk.builtin.int_(self.container.y),
            new Sk.builtin.int_(0),
            new Sk.builtin.int_(0),
        ]);
    }

    function rotate(kwa) {
        const { self, laipsniai, greitis, kryptis } = Utils.mapArgs(arguments, kwa, [{ laipsniai: null }, { greitis: null }, { kryptis: null }]);

        if (laipsniai !== null && laipsniai !== undefined)
            return __rotateRelatively(self, laipsniai, greitis);

        if (kryptis !== null && kryptis !== undefined)
            return __rotateToDirection(self, kryptis, greitis);
    }

    function hide(self) {
        if (Sk.tracing) {
            Sk.tracer.emit('Tekstas:hide', { target: self }, SKTRACEID);
        }

        self.container.visible = false;
    }

    function show(self) {
        if (Sk.tracing) {
            Sk.tracer.emit('Tekstas:show', { target: self }, SKTRACEID);
        }

        self.container.visible = true;
    }

    function __rotateRelatively(self, laipsniai, greitis) {
        var finalAngle = Utils.normalizeAngle(self.container.angle + laipsniai);
        if (!greitis)
            return self.container.angle = finalAngle;

        return __rotateSlowly(self, laipsniai, greitis);
    }

    function __rotateToDirection(self, kryptis, greitis) {
        // the angle we need to end up at
        var finalAngle = __direction2angle(kryptis);
        if (!greitis)
            return self.container.angle = finalAngle;

        // let's make the current angle in range 0...360
        var currentAngle = Utils.normalizeAngle(self.container.angle);
        self.container.angle = currentAngle;

        // calculating the relative angle
        var relativeAngle = finalAngle - currentAngle;
        if (relativeAngle > 180)
            relativeAngle = relativeAngle - 360;
        else
            if (relativeAngle < -180)
                relativeAngle = 360 + relativeAngle;

        return __rotateSlowly(self, relativeAngle, greitis);
    }

    function __rotateSlowly(self, laipsniai, greitis) {
        ; // return promise
    }

    function __direction2angle(kryptis) {
        kryptis = (kryptis || '').toLocaleLowerCase();

        switch (kryptis) {
            case "šiaurė":
            case "viršun":
            case "į viršų":
                return 90;
            case "vakarai":
            case "kairėn":
            case "į kairę":
                return 180;
            case "pietūs":
            case "žemyn":
            case "į apačią":
                return 270;
            case "rytai":
            case "dešinėn":
            case "į dešinę":
                return 0;
            default:
                throw new Error("nežinoma sukimosi kryptis: " + kryptis);
        }
    }

})();
