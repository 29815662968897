Sk.Angis = Sk.Angis || {};

(function () {
    const Utils = Sk.Angis.Utils;

    Sk.Angis.tracerEmit = Utils.createMethodKWA(function (kwa) {

        if (!Sk.tracing) {
            return;
        }

        var args = new Sk.builtins["tuple"](Array.prototype.slice.call(arguments, 0)); /*vararg*/
        var argsarr = args.sk$asarray(args);

        var start = 0;
        if (kwa) {
            start = 1;
        }

        var positional = [];
        for (var i = start; i < argsarr.length; i++) {
            const argval = Utils.tryRemapToJs(argsarr[i]) || argsarr[i];
            positional.push(argval);
        }

        var kwargs = new Sk.builtins["dict"](kwa);
        if (kwargs && kwargs.size) {
            positional.push(Utils.tryRemapToJs(kwargs));
        }

        Sk.tracer.emit('angis:emit', positional, SKTRACEID);

        return Sk.builtin.none.none$;
    });

    Sk.Angis.tracerOnVerify = Utils.createMethod(function (callback) {

        if (!Sk.tracing) {
            return;
        }

        Sk.tracer.emit('angis:register:verify', {
            callback: function () {
                return Sk.misceval.callsimAsync(null, callback)
                    .then(function (result) {
                        return Utils.tryRemapToJs(result) || result;
                    });
            }
        }, SKTRACEID);
    });

})();
