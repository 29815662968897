Sk.Angis = Sk.Angis || {};

(function() {

    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    const textStyle = new PIXI.TextStyle({
        fontFamily: 'Arial',
        fontSize: 36,
        fontStyle: 'italic',
        fontWeight: 'bold',
        fill: ['#ffffff', '#00ff99'], // gradient
        stroke: '#4a1850',
        strokeThickness: 5
    });

    Sk.Angis.testProgressBar = Utils.createMethodKWA(testProgressBar);

    Sk.Angis.ProgressScreen = function(pixiAppInstance){
        const container = Pixi.createContainer();
        Pixi.getStage().addChild(container);

        container.visible = false;

        const w = Pixi.WIDTH;
        const h = Pixi.HEIGHT;

        const progressText = new PIXI.Text('Loading...', textStyle);
        container.addChild(progressText);

        const graphics = new PIXI.Graphics();
        container.addChild(graphics);
        drawProgressBar(0, 100);

        let prevVisible = null;
        let max = 1;
        let current = 0;
        const self = this;

        function show(maxResources){
            current = 0;
            max = maxResources;
            prevVisible = Sk.Angis.Pixi.getCurrentlyVisibleContainer();
            if (prevVisible)
                prevVisible.visible = false;

            container.visible = true;

            return self;
        }

        function hide(){
            container.visible = false;

            if (prevVisible)
                prevVisible.visible = true;

            return self;
        }

        function next(loader){
            let progress = Math.round(loader.progress);
            current++;
            progressText.text = "" + progress + "%";
            drawProgressBar(progress, 100);
            return current;
        }

        function attachToLoader(loader){
            loader.onProgress.add(() => next(loader) );
            loader.onComplete.add(() => hide() );
            loader.onError.add((err) => console.error("failed loading", err) );
            return self;
        }

        function positionText(progressText){
            progressText.x = (w / 2) - (progressText.width / 2);
            progressText.y = (h / 2) - (progressText.height) - 10;
        }
    
        function drawProgressBar(current, max){
            positionText(progressText);
            graphics.clear();
            drawOutline();
            drawBar(current, max);
        }
    
        function drawOutline(){
            graphics.lineStyle(2, 0xFF00FF, 1);
            graphics.beginFill(0x650A5A, 0.25);    
            graphics.drawRoundedRect(w/8, h/2, w*0.75, 20, 4);
            graphics.endFill();
        }
    
        function drawBar(current, max){
            if (!current)
                return;
    
            if (current > max)
                current = max;  
    
            graphics.lineStyle(2, 0x28a745, 1);
            graphics.beginFill(0x28a745);
            graphics.drawRoundedRect(w/8 + 2, h/2 + 2, (current / max) * (w * 0.75) - 4 , 16, 4);
            graphics.endFill();
        }

        //////
        // public API
        this.show = show;
        this.hide = hide;
        this.next = next;
        this.attachToLoader = attachToLoader;
    }
    
    function testProgressBar(kwa) {
        const { max } = Utils.mapArgsStatic(arguments, kwa, [{ max: 100 }]);

        return Sk.misceval.promiseToSuspension(Sk.Angis.Pixi.testProgressBar(max));
    }

})();