Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function() {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.VPŽaidimas.Animacija = (mod) => Sk.misceval.buildClass(mod, function($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function(kwa) {

            const {self, failoVardas, pavadinimas, greitis, animacijųLapas} = 
                    Utils.mapArgs(arguments, kwa, [{ failoVardas: null }, {pavadinimas: null}, {greitis: 0.1}, {animacijųLapas: null}]);
            
            return AnimacijaConstructor(self, failoVardas, pavadinimas, greitis, animacijųLapas);
        });

        $loc.grok = Utils.createMethod(play);
        $loc.play = $loc.grok;
        $loc.grokFone = Utils.createMethodKWA(playInBackground);
        $loc.playInBackground = $loc.grokFone;

        $loc.stop = Utils.createMethod(stop);
        $loc.stok = $loc.stop;

        $loc.atsirask = Utils.createMethodKWA(show);
        $loc.pasirodyk = $loc.atsirask;
        $loc.show = $loc.atsirask;
        $loc.rodykis = $loc.atsirask;

        $loc.pasislėpk = Utils.createMethod(hide);
        $loc.slėpkis = $loc.pasislėpk;
        $loc.hide = $loc.pasislėpk;

        $loc.greitis = Utils.createMethodKWA(setSpeed);
        $loc.nustatykGreitį = $loc.greitis;
        $loc.setSpeed = $loc.greitis;

        function AnimacijaConstructor(self, failoVardas, pavadinimas, greitis, animacijųLapas){
            self.name = pavadinimas;
            self.animationSpeed = greitis;

            self.container = Pixi.createContainer();
            self.animation = null; // will be loaded in promise
            self.attachedTo = null; // (optional) Angis object to attach to.

            self.play = function(){ return play(self); };
            self.playInBackground = function(loop){ return __playInBackground(self, loop === true); };
            self.stop = function() { return stop(self); };
            self.attachTo = function(angisObject, x, y) { return __attachTo(self, angisObject, x, y); };
            self.detachFrom = function(angisObject) { return __detachFrom(self, angisObject); };
            self.showAt = function(x, y){ return __showAt(self, x, y); };
            self.show = function(){ return __show(self); };
            self.hide = function() { return hide(self); };

            // Workaround for remapToJs
            self.v = self;

            if (animacijųLapas)
                return __createFromSheet(self, animacijųLapas.v.sheet);
            else
                return Utils.createSuspension(__createFromFilename(self, failoVardas));
        }

        function __createFromFilename(self, path) {
            return Pixi.createAnimation(path, self.name).then(animation => {
                self.jsonPath = path;
                __setAnimation(self, animation);
                return Sk.builtin.none.none$;
            });
        }

        function __createFromSheet(self, sheet){
            __setAnimation(self, Pixi.createAnimationFromSheet(sheet, self.name));
        }

        function setSpeed(kwa){
            let { self, greitis } = Utils.mapArgs(arguments, kwa, [{ greitis: null }]);

            if (greitis)
                self.animationSpeed = greitis;
            
            return Sk.builtin.float_(self.animationSpeed);
        }

        function __setAnimation(self, animation){
            self.animation = animation;
            self.animation.loop = false;
            self.animation.visible = false;
            self.animation.animationSpeed  = self.animationSpeed;
            
            if (self.animation.textures && self.animation.textures[0] && self.animation.textures[0].trim){
                self.animation.x = -self.animation.textures[0].trim.x;
                self.animation.y = -self.animation.textures[0].trim.y;
            }

            self.animation.onComplete = function(){ 
                ;// console.log("animacija "+self.jsonPath+"::"+self.name+" baigėsi"); 
            };

            self.container.addChild(self.animation); 
        }

        function show(kwa){
            let { self, elementas, x, y } = 
                Utils.mapArgs(arguments, kwa, [{ elementas: null },
                                               { x: undefined }, { y:undefined }]);

            if (elementas !== null && !isNaN(elementas)){
                y = x;
                x = elementas;
                elementas = null;
            }

            if (elementas)
                return __attachTo(self, elementas, x, y);               

            if (x !== undefined && y !== undefined)
                return __showAt(self, x, y);

            return __show(self);
        }

        function __attachTo(self, angisObject, x, y){
            if (self.attachedTo)
                self.attachedTo.removeChild(self.container);

            self.attachedTo = angisObject.v.container ; //.parent;
            self.attachedTo.addChild(self.container);

            const parentHitArea = angisObject.v.hitArea;

            if (x === undefined)
                self.container.x = parentHitArea.x + (parentHitArea.width / 2) - (self.animation.width / 2); 
            else
                self.container.x = x;

            if (y === undefined)
                self.container.y = parentHitArea.y + (parentHitArea.height / 2) - (self.animation.height / 2); 
            else
                self.container.y = y;
                
            self.animation.visible = true;
        }

        function __showAt(self, x, y){
            if (self.attachedTo)
                self.attachedTo.removeChild(self.container);

            Sk.VPŽaidimas.getOrCreateLayer();

            self.attachedTo = Pixi.getCurrentlyVisibleContainer();
            self.attachedTo.addChild(self.container);

            self.container.x = x;
            self.container.y = y;

            self.animation.visible = true;
        }

        function __detachFrom(self, angisObject){
            if (angisObject && angisObject.v)
                angisObject.v.container.removeChild(self);

            if (!self.attachedTo)
                return;

            self.attachedTo.removeChild(self.container);
            self.attachedTo = null;
            self.animation.visible = false;
        }

        function __show(self){
            self.animation.visible = true;
        }

        function play(self) {
            console.log("play");

            if (!self.animation)
                return;

            if (!self.animation.visible)
                self.animation.visible = true;

            return Sk.misceval.promiseToSuspension(new Promise((resolve, reject) => {
                self.animation.onComplete = resolve;
                self.animation.loop = false;
                self.animation.gotoAndPlay(self.lastFrame || 0);
            }));
        }

        function playInBackground(kwa){
            const { self, amžinai } = 
                Utils.mapArgs(arguments, kwa, [{ amžinai: false }]);

            return __playInBackground(self, amžinai);
        }

        function __playInBackground(self, forever){
            if (!self.animation)
                return;

            if (!self.animation.visible)
                self.animation.visible = true;

            self.animation.loop = forever;

            if (self.animation.playing)
                return;

            self.animation.gotoAndPlay(self.lastFrame || 0);
        }

        function stop(self){
            if (!self.animation)
                return;

            self.lastFrame = self.animation.currentFrame;
            self.animation.gotoAndStop(0);
            self.lastFrame = 0;
        }

        function hide(self){
            if (!self.animation)
                return;

            stop(self);
            self.animation.visible = false;
        }

    }, "Animacija", []);

})();
