Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function () {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.VPŽaidimas.Plytelė = (mod) => Sk.misceval.buildClass(mod, function ($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function (kwa) {
            const { self, failoVardas, x, y, tag } = Utils.mapArgs(arguments, kwa, [{ failoVardas: null }, { x: -1 }, { y: -1 }, { tag: undefined }]);

            PlytelėConstructor(self, failoVardas, x, y, tag);
        });

        $loc.naudokPaveiksliuką = Utils.createMethodKWA(naudokPaveiksliuką);
        $loc.fonas = $loc.naudokPaveiksliuką;
        $loc.background = $loc.naudokPaveiksliuką;
        $loc.naudokAnimaciją = Utils.createMethodKWA(naudokAnimaciją);
        $loc.useAnimation = $loc.naudokPaveiksliuką;

        $loc.duokKoordinates = Utils.createMethod(getCoordinates);
        $loc.duokIšmatavimus = Utils.createMethod(getDimensions);

        $loc.sukis = Utils.createMethodKWA(rotate);
        $loc.rotate = $loc.sukis;

        $loc.pasislėpk = Utils.createMethod(function (self) { self.hide(); });
        $loc.slėpkis = $loc.pasislėpk;
        $loc.hide = $loc.pasislėpk;

        $loc.pasirodyk = Utils.createMethod(function (self) { self.show(); });
        $loc.rodykis = $loc.pasirodyk;
        $loc.show = $loc.pasirodyk;

        $loc.arMatomas = Utils.createMethod(function(self) { return new Sk.builtin.bool (self && self.v && self.v.isVisible && self.v.isVisible()); });
        $loc.yraMatomas = $loc.arMatomas;
        $loc.matomas = $loc.arMatomas;
        $loc.isVisible = $loc.arMatomas;
        $loc.visible = $loc.arMatomas;

        $loc.išnyk = Utils.createMethod(function(self) {self.destroy(); } );

        $loc.pasisuk = $loc.sukis;
    }, "Plytelė", []);

    Sk.VPŽaidimas.Plytelė.ctor = PlytelėConstructor;

    function PlytelėConstructor(self, failoVardas, x, y, tag) {
        self.failoVardas = failoVardas;
        self.tag = tag;

        // Tags are only used in tracing. They are generally not exposed to python.
        // Correct applyTag implementation will use Symbol (prefered) or 
        // WeakMap (for max hiding) to attach a tag to the instance so that it was 
        // not accessible to python script.
        if (Sk.tracing && tag) {
            Sk.tracer.emit('tag:apply', { target: self, tag: tag }, SKTRACEID); 
        }

        self.sprite = Pixi.createSprite(self.failoVardas);

        // my creator will add(plytele.container) to it's own container
        self.container = Pixi.createContainer();
        self.container.addChild(self.sprite);
        self.container.x = x;
        self.container.y = y;
        self.container.zIndex = 1;

        // virtual (overridable) methods
        self.show = () => show(self);
        self.hide = () => hide(self);
        self.isVisible = function(){ return self.sprite.visible; };
        self.destroy = () => destroy(self);

        // Workaround for remapToJs
        self.v = self;

        if (Sk.tracing) {
            Sk.tracer.emit('plytelė:new', { target: self }, SKTRACEID);
        }
    }

    function naudokPaveiksliuką(kwa) {
        const { self, failoVardas } = Utils.mapArgs(arguments, kwa, [{ failoVardas: "" }]);

        if (Sk.tracing) {
            Sk.tracer.emit('plytelė:useImage', { target: self, failoVardas: failoVardas }, SKTRACEID);
        }

        self.failoVardas = failoVardas;
        Pixi.getTexture(failoVardas).then(texture => self.sprite.texture = texture);
    }

    function naudokAnimaciją(kwa) {
        const { self, animacija } = Utils.mapArgs(arguments, kwa, [{ animacija: null }]);
        self.container.removeChildren();

        const animationSprite = animacija.animation;
        self.container.addChild(animationSprite);
        animationSprite.x = 0;
        animationSprite.y = 0;
    }

    function getCoordinates(self) {
        return new Sk.builtin.tuple([
            new Sk.builtin.int_(self.container.x),
            new Sk.builtin.int_(self.container.y)
        ]);
    }

    function getDimensions(self) {
        return new Sk.builtin.tuple([
            new Sk.builtin.int_(self.container.width),
            new Sk.builtin.int_(self.container.height)
        ]);
    }

    function rotate(kwa) {
        const { self, laipsniai, greitis, kryptis } = Utils.mapArgs(arguments, kwa, [{ laipsniai: null }, { greitis: null }, { kryptis: null }]);

        if (laipsniai !== null && laipsniai !== undefined)
            return __rotateRelatively(self, laipsniai, greitis);

        if (kryptis !== null && kryptis !== undefined)
            return __rotateToDirection(self, kryptis, greitis);
    }

    function destroy(self) {
        if (Sk.tracing) {
            Sk.tracer.emit('plytelė:destroy', { target: self }, SKTRACEID);
        }
        self.hide();

        const p = self.container.parent;
        if (p)
            p.removeChild(self.container);
    }

    function hide(self) {
        if (Sk.tracing) {
            Sk.tracer.emit('plytelė:hide', { target: self }, SKTRACEID);
        }

        self.sprite.visible = false;
    }

    function show(self) {
        if (Sk.tracing) {
            Sk.tracer.emit('plytelė:show', { target: self }, SKTRACEID);
        }
        
        self.sprite.visible = true;
    }

    function __rotateRelatively(self, laipsniai, greitis) {
        var finalAngle = Utils.normalizeAngle(self.sprite.angle + laipsniai);
        if (!greitis)
            return self.sprite.angle = finalAngle;

        return __rotateSlowly(self, laipsniai, greitis);
    }

    function __rotateToDirection(self, kryptis, greitis) {
        // the angle we need to end up at
        var finalAngle = __direction2angle(kryptis);
        if (!greitis)
            return self.sprite.angle = finalAngle;

        // let's make the current angle in range 0...360
        var currentAngle = Utils.normalizeAngle(self.sprite.angle);
        self.sprite.angle = currentAngle;

        // calculating the relative angle
        var relativeAngle = finalAngle - currentAngle;
        if (relativeAngle > 180)
            relativeAngle = relativeAngle - 360;
        else
            if (relativeAngle < -180)
                relativeAngle = 360 + relativeAngle;

        return __rotateSlowly(self, relativeAngle, greitis);
    }

    function __rotateSlowly(self, laipsniai, greitis) {
        ; // return promise
    }

    function __direction2angle(kryptis) {
        kryptis = (kryptis || '').toLocaleLowerCase();

        switch (kryptis) {
            case "šiaurė":
            case "viršun":
            case "į viršų":
                return 90;
            case "vakarai":
            case "kairėn":
            case "į kairę":
                return 180;
            case "pietūs":
            case "žemyn":
            case "į apačią":
                return 270;
            case "rytai":
            case "dešinėn":
            case "į dešinę":
                return 0;
            default:
                throw new Error("nežinoma sukimosi kryptis: " + kryptis);
        }
    }

})();
