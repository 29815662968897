Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function() {
    const Utils = Sk.Angis.Utils;
    const Pixi  = Sk.Angis.Pixi;

    Sk.VPŽaidimas.Scena = (mod) => Sk.misceval.buildClass(mod, function($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function(kwa) {

            console.log("creating Scena");

            const { self, plotis, aukštis, plytelėsPlotis, plytelėsAukštis } =
                        Utils.mapArgs(arguments, kwa, [{ plotis: 800 }, { aukštis:600 }, 
                                                       { plytelėsPlotis:50 }, { plytelėsAukštis:50 }]);

            self.width = plotis || 100;
            self.height = aukštis || 100;

            // the two-dimensional model of the tiles
            self.tileWidth = plytelėsPlotis;
            self.tileHeight = plytelėsAukštis;
            
            self.tilesRows = Math.ceil(self.width / self.tileWidth) || 1;
            self.tilesCols = Math.ceil(self.height / self.tileHeight) || 1;

            self.tilesModel = __create2dArray(self.tilesRows, self.tilesCols);

            // the list of Sluoksnis objects
            self.layers = [];

            // making mySelf the only visible scene in Pixi now
            Pixi.hideAllContainers();
            self.container = Pixi.createContainer();
            self.container.interactive = true;
            self.container.hitArea = new PIXI.Rectangle(0, 0, self.width, self.height);
            Pixi.addContainer(self.container);

            Pixi.setStageSize(self.width, self.height);

            self.tilesContainer = Pixi.createContainer();
            self.container.addChild(self.tilesContainer);

            self.layersContainer = Pixi.createContainer();
            self.container.addChild(self.layersContainer);

            // the background image, to be used beneath the tiles
            self.paveiksliukas = null;
            // Workaround for remapToJs
            self.v = self;
            Sk.VPŽaidimas.currentScene = self;

            self.useBackgroundImage = (pav) => { return __setBackgroundImage(self, pav); };

            self.getLayer = function() { return getLayer(self); };
        });

        $loc.duokIšmatavimus = Utils.createMethod(getMapDimension);
        $loc.nustatykIšmatavimus = Utils.createMethodKWA(setSize);
        $loc.dydis = $loc.nustatykIšmatavimus;
        $loc.nustatykDydį = $loc.nustatykIšmatavimus;

        $loc.duokPlytelėsIšmatavimus = Utils.createMethod(getTileDimension);

        $loc.naudokPaveiksliuką = Utils.createMethodKWA(setBackgroundImage);
        $loc.naudokFoną = $loc.naudokPaveiksliuką;
        $loc.fonas = $loc.naudokPaveiksliuką;
        $loc.background = $loc.naudokPaveiksliuką;
        
        $loc.sukurkPlytelę = Utils.createMethodKWA(createTile);

        $loc.pridėkPlytelę = Utils.createMethodKWA(addTile);
        $loc.išimkPlytelę  = Utils.createMethodKWA(removeTile);
        $loc.pridėkPlyteles= Utils.createMethodKWA(setTilesModel);

        $loc.sukurkSluoksnį = Utils.createMethod(createLayer);

        $loc.duokSluoksnį   = Utils.createMethod(getLayer);
        $loc.getLayer       = $loc.duokSluoksnį;

        $loc.duokSluoksnius = Utils.createMethod(getAllLayers);

        $loc.rodykLangelius = Utils.createMethodKWA(showGridKWA);
        $loc.showGrid       = $loc.rodykLangelius;

        $loc.slėpkLangelius = Utils.createMethod(hideGrid);
        $loc.hideGrid      = $loc.slėpkLangelius;

        $loc.sakyk = Utils.createMethod(showSpeechBubble);
        $loc.say = $loc.sakyk;
        $loc.sakykFone = Utils.createMethod(showSpeechBubbleNonBlocking);
        $loc.sayInBackground = $loc.sakykFone;

        const eventMethodsMap = {
            pelęUžvedus: "mouseover",
            pelęNuvedus: "mouseout",
            pelęPaspaudus: ["mousedown", "touchstart"], // PIXI.js fires "pointerdown" for both mouse and pointer events [ "mousedown", "pointerdown" ],
            pelęAtleidus: ["mouseup", "mouseupoutside", "touchend", "touchendoutside"], //[ "mouseup", "pointerup" ],
            pelęPaspaudusDešinįKlavišą: "rightdown",
            pelęAtleidusDešinįKlavišą: "rightup",
            pelęJudinant: ["mousemove", "touchmove"]
        };

        for (let method in eventMethodsMap)
            $loc[method] = Utils.createMethodKWA(makeEventCallback(eventMethodsMap[method], method));

        function makeEventCallback(eventNames, method) {
            return function onEvent(kwa) {
                const { self, veiksmas } = Utils.mapArgs(arguments, kwa, [{ veiksmas: null }]);
    
                if (Sk.tracing) {
                    Sk.tracer.emit('scena:on:' + method, { target: self, method: veiksmas }, SKTRACEID);
                }

                __off(self.container, eventNames);
                if (veiksmas)
                    __on(self.container, eventNames, __createCallbackFn(veiksmas, eventNames));
            };
        }

        function __off(container, eventNames){
            if (Array.isArray(eventNames))
                for (const eventName of eventNames)
                    container.off(eventName);
            else
                container.off(eventNames);
        }
    
        function __on(container, eventNames, komanda){
            if (Array.isArray(eventNames))
                for (const eventName of eventNames)
                    container.on(eventName, komanda);
            else
                container.on(eventNames, komanda);
        }
    
        function __createCallbackFn(komanda, eventNames) {
            return function (event) {
                const { x, y } = event.data.global;
                const { newX, newY } = Pixi.translateCoords(x, y);
                const pyX = new Sk.builtin.int_(newX);
                const pyY = new Sk.builtin.int_(newY);
                Sk.misceval.applyAsync(undefined, komanda, undefined, undefined, undefined, [pyX, pyY]);
                event.stopPropagation();
            };
        }

        function getMapDimension(self) {
            return new Sk.builtin.tuple([
                new Sk.builtin.int_(self.width),
                new Sk.builtin.int_(self.height)
            ]);
        }

        function setSize(kwa){
            const { self, plotis, aukštis} = Utils.mapArgs(arguments, kwa, [{ plotis: null }, {aukštis: null}]);

            self.width = plotis || self.width;
            self.height = aukštis || self.height;
           
            self.tilesRows = Math.ceil(self.width / self.tileWidth) || 1;
            self.tilesCols = Math.ceil(self.height / self.tileHeight) || 1;

            self.container.hitArea = new PIXI.Rectangle(0, 0, self.width, self.height);

            Pixi.setStageSize(self.width, self.height);
        }

        function getTileDimension(self) {
            return new Sk.builtin.tuple([
                new Sk.builtin.int_(self.tileWidth),
                new Sk.builtin.int_(self.tileHeight)
            ]);        
        }

        function setBackgroundImage(kwa){
            const { self, paveiksliukas } = Utils.mapArgs(arguments, kwa, [{ paveiksliukas: null }]);

            return self.useBackgroundImage(paveiksliukas);
        }

        function __setBackgroundImage(self, paveiksliukas){
            if (self.paveiksliukas){
                self.tilesContainer.removeChild(self.paveiksliukas);
                self.paveiksliukas = null;
            }

            if (!paveiksliukas)
                return;

            if (Pixi.hasResource(paveiksliukas))
                return __setBgImg();

            return Sk.misceval.promiseToSuspension(
                        Pixi.getResource(paveiksliukas).then(__setBgImg)
                    );

            function __setBgImg(){
                self.paveiksliukas = Pixi.createSprite(paveiksliukas);
                self.tilesContainer.addChild(self.paveiksliukas);
                Pixi.resize();
                if (Sk.tracing) {
                    Sk.tracer.emit('scena:useImage', { target: self, filename: paveiksliukas }, SKTRACEID);
                }
            }
        }

        function createTile(kwa){
            const { self, failoVardas, stulpelis, eilutė } = Utils.mapArgs(arguments, kwa, [{ failoVardas: null}, {stulpelis: -1}, {eilutė: -1 }]);

            if (stulpelis<0 && eilutė <0)
                return Utils.createPy(Sk.VPŽaidimas.Plytelė(mod), [failoVardas, -1, -1]);

            __assertTileIndex(self, stulpelis, eilutė);

            const x = Sk.builtin.int_(stulpelis * self.tileWidth);
            const y = Sk.builtin.int_(eilutė * self.tileHeight);

            var ret =  Utils.createPy(Sk.VPŽaidimas.Plytelė(mod), [failoVardas, x, y]);
            self.tilesContainer.addChild(ret.v.container);
            self.tilesModel[eilutė][stulpelis] = ret;

            return ret;
        }

        function addTile(kwa){
            const { self, plytelė, stulpelis, eilutė } = Utils.mapArgs(arguments, kwa, [{ plytelė: null}, {stulpelis: 0}, {eilutė: 0 }]);

            return __insertTile(self, plytelė, eilutė, stulpelis);
        }

        function removeTile(kwa){
            return;
        }

        function setTilesModel(kwa){
            let {self, modelis, plytelėsPlotis, plytelėsAukštis} =
                     Utils.mapArgs(arguments, kwa, [{ modelis: null }, { plytelėsPlotis:null }, { plytelėsAukštis:null }]);

            if (plytelėsPlotis && plytelėsPlotis > 0)
                self.tileWidth = plytelėsPlotis;
            else
                plytelėsPlotis = self.tileWidth;
                
            if (plytelėsAukštis && plytelėsAukštis > 0)
                self.tileHeight = plytelėsAukštis;
            else
                plytelėsAukštis = self.tileHeight;

            if (!modelis || !modelis.length)
                return;

            const rowCount = modelis.length;
            const colCount = modelis[0].length;
            // TODO: check if every row contains the same number of columns

            self.tilesModel = __create2dArray(rowCount, colCount);
            self.tilesRows = rowCount;
            self.tilesCols = colCount;

            for (let row = 0; row < rowCount; row ++){
                if (!modelis[row])
                    continue; 

                for (let col = 0; col < colCount; col++)
                    __insertTile(self, modelis[row][col], row, col);
            }

            self.container.hitArea = new PIXI.Rectangle(0, 0, plytelėsPlotis*colCount, plytelėsAukštis*rowCount);

            return;
        }

        function createLayer(self){
            const ret =  Utils.createPy(Sk.VPŽaidimas.Sluoksnis(mod), [self]);

            ret.zIndex = self.layers.length * 100;

            self.layersContainer.addChild(ret.v.container);
            self.layers.push(ret);

            return ret;
        }

        function getAllLayers(self) {
            return Sk.ffi.remapToPy(self.layers);
        }

        function getLayer(self){
            if (self.layers.length === 0)
                return createLayer(self);

            return self.layers[self.layers.length - 1];
        }
        
        function showGridKWA(kwa){
            let {self, langelioPlotis, langelioAukštis} =
                     Utils.mapArgs(arguments, kwa, [{ langelioPlotis: null }, { langelioAukštis:null }]);

            hideGrid(self);

            self.grid = new PIXI.Graphics();
            self.grid.lineStyle(1, 0xeeFFee, 0.3);
            self.grid.beginFill(0xeeeeee, 0.1);
    
            langelioAukštis = langelioAukštis || self.tileHeight;
            langelioPlotis  = langelioPlotis  || self.tileWidth;

            const vnum = self.height / langelioAukštis;
            const hnum = self.width  / langelioPlotis;

            for (let i=0; i<vnum + 1; i++ )
                self.grid.drawRect(0, langelioAukštis * i, self.width, 1);
    
            for (let i=0; i<hnum + 1; i++ )
                self.grid.drawRect(i * langelioPlotis, 0, 1, self.height);

            self.grid.endFill();   
            
            self.container.addChild(self.grid);
        }

        function hideGrid(self){
            if (self.grid)
                self.grid.clear();
            self.container.removeChild(self.grid);
            self.grid = null;
        }

        function showSpeechBubble() {
            const [self, ...text] = arguments;
            const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");
    
            if (Sk.tracing) {
                Sk.tracer.emit('scena:showSpeechBubble', { target: self, text: textToShow }, SKTRACEID);
            }
    
            const closeable = Pixi.createSpeechBubble(self, textToShow);

            Sk.misceval.callsimOrSuspendArray(Sk.Angis.PromiseCloser(mod), []).init(closeable, 10000);

            return Sk.misceval.promiseToSuspension(closeable.promise);
        }
    
        function showSpeechBubbleNonBlocking(){
            const [self, ...text] = arguments;
            const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");
    
            if (Sk.tracing) {
                Sk.tracer.emit('scena:showSpeechBubble', { target: self, text: textToShow }, SKTRACEID);
            }

            const closeable = Pixi.createSpeechBubble(self, textToShow);

            return Sk.misceval.callsimOrSuspendArray(Sk.Angis.PromiseCloser(mod), []).init(closeable, 10000);
        }

        function __create2dArray(rowNum, colNum){
            return new Array(rowNum || 1).fill(null).map(() => new Array(colNum || 1).fill(null));
            // return [...Array(rowNum || 1)].map(x => Array(colNum || 1).fill(null));
        }

        function __assertTileIndex(self, stulpelis, eilutė){
            if (self.tilesRows  <= eilutė)
                throw new Error('Scenos modelyje eilučių yra tik '+self.tilesRows+', o tu bandai pridėti į '+eilutė+' - netelpa!');

            if (self.tilesCols  <= stulpelis)
                throw new Error('Scenos modelyje stulpelių yra tik '+self.tilesCols+', o tu bandai pridėti į '+stulpelis+' - netelpa!');
        }

        function __insertTile(self, plytelė, eilutė, stulpelis){
            if (!plytelė)
                return null;

            var assetPath = plytelė;    // assuming param is a string

            if (plytelė.v)              
                assetPath = plytelė.v;  // ok, it's a skulpt object

            if (plytelė.failoVardas)
                assetPath = plytelė.failoVardas; // oh, it's a Plytelė Skulpt object.

            if (!assetPath)
                return null;

            if (self.tilesCols  <= stulpelis)
                throw new Error('Scenos modelyje stulpelių yra tik '+self.tilesCols+', o tu bandai pridėti į '+stulpelis+' - netelpa!');

            if (self.tilesRows  <= eilutė)
                throw new Error('Scenos modelyje eilučių yra tik '+self.tilesRows+', o tu bandai pridėti į '+eilutė+' - netelpa!');

            if (stulpelis < 0)
                throw new Error('Stulpelio indeksas turi būti neneigiamas, o tu nurodei '+stulpelis+' - klaida!');

            if (eilutė < 0)
                throw new Error('Eilutės indeksas turi būti neneigiamas, o tu nurodei '+eilutė+' - klaida!');

            const x = Sk.builtin.int_(stulpelis * self.tileWidth);
            const y = Sk.builtin.int_(eilutė * self.tileHeight);

            var ret =  Utils.createPy(Sk.VPŽaidimas.Plytelė(mod), [assetPath, x, y]);
            self.tilesContainer.addChild(ret.v.container);

            self.tilesModel[eilutė][stulpelis] = ret;

            return ret;
        }

    }, "Scena", []);
/*
    function užkraukSceną(kwa) {
        const args = Utils.mapArgs(arguments, kwa, [{ failas: "" }]);

        return Sk.misceval.promiseToSuspension(
                Pixi.loadGameJson(args.failas)
                    .then((result) => __onJsonLoaded(result))
                );
    }

    function __onJsonLoaded(result){
    }
*/
})();
