Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function() {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.VPŽaidimas.Grafika = (mod) => Sk.misceval.buildClass(mod, function($gbl, $loc) {

        $loc.__init__ = Utils.createMethod(function(self, scena) {
            self.container = Pixi.createContainer();
            self.container.zIndex = 5;

            self.lineStyle = {
                width: 5, 
                color: 0xFF00FF,
                alpha: 1,
                native: false
            };

            self.graphics = Pixi.createGraphics();
            self.graphics.lineStyle (self.lineStyle);
            self.container.addChild(self.graphics);
            
            // z-index - ordable group (see pixi-layers plugin)
            self.displayGroup = Pixi.createDisplayGroup(); 
            self.container.displayGroup  = self.displayGroup;

            self.drawLine = function(x, y, x2, y2, width, color){
                if (color != null)
                    self.setColor(color);
                    
                self.graphics.moveTo(x,y);
                self.graphics.lineTo(x2, y2);
            };

            self.drawDot = function(x, y){
                self.graphics.drawCircle(x,y, 1);
            };

            self.clear = function(){
                self.graphics.clear();
                self.graphics.lineStyle (self.lineStyle);
                self.container.addChild(self.graphics);
            };

            self.setWidth = function(w){
                w = w -0;
                if (!w || w === self.lineStyle.width)
                    return;

                self.lineStyle.width = w;
                self.graphics.lineStyle(self.lineStyle);
            };

            self.setColor = function(c){
                if (c === self.lineStyle.color)
                    return;

                self.lineStyle.color = c;
                self.graphics.lineStyle(self.lineStyle);
            };

            self.setAlpha = function(a){
                a = a -0;
                if (!a || a === self.lineStyle.alpha)
                    return;

                self.lineStyle.alpha = a;
                self.graphics.lineStyle(self.lineStyle);
            };

            self.show = function(){
                self.container.visible = true;
            };

            self.hide = function(){
                self.container.visible = false;
            };

            // Workaround for remapToJs
            self.v = self;

            // self.container shall be attached to Pixi stage by my parent, i.e. Scena.
        });

        $loc.pieškLiniją = Utils.createMethodKWA(drawLineKWA);
        $loc.linija = $loc.pieškLiniją;
        $loc.drawLine = $loc.pieškLiniją;
        $loc.line = $loc.pieškLiniją;

        $loc.pieškTašką = Utils.createMethodKWA(drawDotKWA);
        $loc.taškas = $loc.pieškTašką;
        $loc.drawDot = $loc.pieškTašką;
        $loc.dot = $loc.pieškTašką;

        $loc.išvalyk = Utils.createMethod(clear);
        $loc.išNaujo = $loc.išvalyk;
        $loc.clear = $loc.išvalyk;

        $loc.pasirodyk = Utils.createMethodKWA(function(self){ return self.show(); });
        $loc.atsirask = $loc.pasirodyk;
        $loc.show = $loc.pasirodyk;

        $loc.pasislėpk = Utils.createMethod(function(self){ return self.hide(); });
        $loc.hide = $loc.pasislėpk;

        $loc.linijosStoris = Utils.createMethodKWA(setLineWidthKWA);
        $loc.lineWidth = $loc.linijosStoris;

        $loc.linijosSpalva = Utils.createMethodKWA(setLineColorKWA);
        $loc.lineColor = $loc.linijosSpalva;

        $loc.linijosAlfa = Utils.createMethodKWA(setLineAlphaKWA);
        $loc.lineApha = $loc.linijosAlfa;

        function setLineWidthKWA(kwa){
            const {self, storis} = Utils.mapArgs(arguments, kwa, [{storis: undefined}]);

            if (storis)
                self.setWidth(storis);

            return new Sk.builtin.int_(self.lineStyle.width);
        }

        function setLineColorKWA(kwa){
            const {self, spalva} = Utils.mapArgs(arguments, kwa, [{spalva: undefined}]);

            if (spalva)
                self.setColor(spalva);

            return new Sk.builtin.str(self.lineStyle.color);
        }

        function setLineAlphaKWA(kwa){
            const {self, alpha} = Utils.mapArgs(arguments, kwa, [{alpha: undefined}]);

            if (alpha)
                self.setAlpha(alpha);

            return new Sk.builtin.int_(self.lineStyle.alpha);
        }

        function drawLineKWA(kwa){
            let {self, x1, y1, x2, y2 } = Utils.mapArgs(arguments, kwa, 
                                                                [{x1: undefined}, {y1: undefined}, 
                                                                 {x2: undefined}, {y2: undefined}]);

            if (x1 != null && x2 != null && y1 != null && y2!=null)
                self.drawLine(x1, y1, x2, y2);
        }

        function drawDotKWA(kwa){
            let {self, x, y } = Utils.mapArgs(arguments, kwa, [{x: undefined}, {y: undefined}]);

            if (x != null && y != null)
                self.drawDot(x, y);
        }

        function clear(self){
            self.clear();
        }


    }, "Grafika", []);

})();
