Sk.Angis = Sk.Angis || {};

(function () {

    let timeouts = [];
    let intervals = [];
    let suspensions = [];
    let destructors = [];

    Sk.Angis.Utils = {
        callPy: callPy,
        createPy: createPy,
        createMethod: createMethod,
        createMethodKWA: createMethodKWA,
        mapArgs: mapArgs,
        mapArgsStatic: mapArgsStatic,
        tryRemapToJs: tryRemapToJs,
        tryRemapToPy: tryRemapToPy,

        createSuspension: createSuspension,
        clearSuspensions: clearSuspensions,

        remapToPyNumber: remapToPyNumber,
        remapToPyStr: remapToPyStr,

        setTimeout: setTimeout,
        clearTimeout: clearTimeout,
        clearTimeouts: clearTimeouts,

        setInterval: setInterval,
        clearInterval: clearInterval,
        clearIntervals: clearIntervals,

        registerDestructor: registerDestructor,
        removeDestructor: removeDestructor,
        executeDestructors: executeDestructors,

        normalizeAngle: normalizeAngle
    };

    function callPy(klass, fnName, args = []) {
        return Sk.misceval.callsimOrSuspendArray(klass[fnName], [klass, ...args.map(r => Sk.ffi.remapToPy(r))]);
    }

    function createPy(fn, args) {
        return Sk.misceval.callsimOrSuspendArray(fn, args.map(r => Sk.ffi.remapToPy(r)));
    }

    // Moved from žaidimas.js. Needs refactoring/testing/cleaning up.
    function createMethod(func) {
        return new Sk.builtin.func(func);
    }

    function createMethodKWA(func) {
        func["co_kwargs"] = true;
        return new Sk.builtin.func(func);
    }

    //////
    // TODO: check argument types, check if arguments are too many, check if mandatory arguments are missing.
    // TODO: how to handle param conflicts when mixing args and kwargs: move (x, y) -> move(500, x = 400, y = 300)
    //       right now kwargs will take precedence
    function mapArgs(args, kwa, expected) {
        var kwargs = new Sk.builtins["dict"](kwa);

        var vargs = mapVargs(args, expected);
        if (!kwargs || (kwargs.size === 0))
            return vargs;

        return mapKwargs(kwargs, expected, vargs);
    }

    function mapArgsStatic(args, kwa, expected) {
        var kwargs = new Sk.builtins["dict"](kwa);

        var vargs = mapVargsStatic(args, expected);
        if (!kwargs || (kwargs.size === 0))
            return vargs;

        return mapKwargs(kwargs, expected, vargs);
    }

    function remapToPyNumber(text){
        var ret = Number(text);
        if (ret !== ret)
            ret = 0;

        if (hasDot(ret))
            return new Sk.builtin.float_(ret);

        return new Sk.builtin.int_(ret);
    }

    function hasDot(number){
        var str = ("" + number);
        return str.indexOf(".") >= 0 || 
               str.indexOf(",") >= 0;
    }

    function remapToPyStr(text){
        if (text == null)
            text = "";
        return new Sk.builtin.str(""+text);
    }

    // Internal functions

    function mapKwargs(kwargs, expected, destination) {
        var ret = destination || {}; // self if handled by destination

        for (var i = 0; i < expected.length; i++) {
            const paramName = getParamName(expected[i]);
            if (!ret.hasOwnProperty(paramName)) {

                // This should never hit if args were processed beforehand
                ret[paramName] = expected[i][paramName];
            }

            var val = kwargs.mp$lookup(new Sk.builtin.str(paramName));
            if (val) {
                ret[paramName] = remap(val);
            }
        }

        return ret;
    }

    function mapVargs(vargs, expected) {
        var args = new Sk.builtins["tuple"](Array.prototype.slice.call(vargs, 1)); /*vararg*/

        var argsarr = args.sk$asarray(args);

        var ret = { self: argsarr[0] };

        var max = argsarr.length - 1;

        for (var i = 0; i < expected.length; i++) {
            const paramName = getParamName(expected[i]);
            ret[paramName] = expected[i][paramName];
            if (i >= max) {
                continue;
            }

            const argval = remap(argsarr[i + 1]);
            if (argval !== undefined) {
                ret[paramName] = argval;
            }
        }

        return ret;
    }

    function mapVargsStatic(vargs, expected) {
        var args = new Sk.builtins["tuple"](Array.prototype.slice.call(vargs, 0)); /*vararg*/

        var argsarr = args.sk$asarray(args);

        var ret = {}; // no self

        var max = argsarr.length;

        for (var i = 0; i < expected.length; i++) {
            const paramName = getParamName(expected[i]);
            ret[paramName] = expected[i][paramName];
            if (i >= max) {
                continue;
            }

            const argval = remap(argsarr[i + 1]);
            if (argval !== undefined) {
                ret[paramName] = argval;
            }
        }

        return ret;
    }

    function remap(argument) {
        var ret = Sk.ffi.remapToJs(argument);
        if (ret !== undefined)
            return ret;

        if (argument instanceof Sk.builtin.func)
            return argument;

        if (argument instanceof Sk.builtin.object)
            return argument;

        return ret;
    }

    function getParamName(obj) {
        for (var name in obj) {
            if (obj.hasOwnProperty(name)) {
                return name;
            }
        }

        return null;
    }

    function tryRemapToJs(val) {
        try {
            return Sk.ffi.remapToJs(val);
        } catch (err) {
            return val;
        }
    }

    function tryRemapToPy(val){
        try{
            return Sk.ffi.remapToPy(val);
        } catch (err) {
            return val;
        }
    }

    function createSuspension(promise, optional = false) {
        const suspension = new Sk.misceval.Suspension();

        suspension.resume = () => Sk.builtin.none.none$;
        suspension.optional = optional;
        suspension.data = {
            type: "Sk.promise",
            promise: promise,
        };

        // suspensions.push(suspension);

        return suspension;
    }

    function clearSuspensions(){
        for (const susp of suspensions)
            if (susp && susp.data.promise)
                susp.data.promise.reject();

        suspensions = [];
    }

    /////
    // creates a "managed" timeout, that either:
    //  a) auto-destructs upon execution in "delay" msec
    //  or
    //  b) can be canceled in Sk.Angis.Utils.clearTimeout()
    //  or
    //  c) is cleaned up in Sk.Angis.Utils.clearTimeouts()
    function setTimeout(func, delay) {
        const closureLocal = {};

        const handler = window.setTimeout(function () {
            try { func(); } catch (err) { console.error(err); }
            clearTimeout(closureLocal.value);
        }, delay);

        closureLocal.value = handler;

        timeouts.push(handler);

        return handler;
    }

    function clearTimeout(handler) {
        if (!handler)
            return;

        window.clearTimeout(handler);

        const index = timeouts.indexOf(handler);
        if (index >= 0)
            timeouts.splice(index, 1);
    }

    function clearTimeouts() {
        for (const handler of timeouts)
            window.clearTimeout(handler);

        timeouts = [];
    }

    function setInterval(func, delay) {
        const handler = window.setInterval(func, delay);
        intervals.push(handler);
        return handler;
    }

    function clearInterval(handler) {
        if (!handler)
            return;

        window.clearInterval(handler);

        const index = intervals.indexOf(handler);
        if (index >= 0)
            intervals.splice(index, 1);
    }

    function clearIntervals() {
        for (const handler of intervals)
            window.clearInterval(handler);

        intervals = [];
    }

    function normalizeAngle(degrees) {
        // reduce the angle  to -360 ... 360
        degrees = degrees % 360;

        // force it to be the positive remainder, so that 0 <= angle < 360  
        if (degrees < 0)
            degrees = (degrees + 360) % 360;

        return degrees;
    }

    function registerDestructor(obj){
        if (obj && obj.destroy)
            destructors.push(obj);
    }

    function removeDestructor(obj){
        for (var i = destructors.length; i--; ) {
            if (destructors[i] === obj) {
                destructors.splice(i, 1);
            }
        }        
    }

    function executeDestructors(){
        for (var i = destructors.length; i--; ) {
            try{
                destructors[i].destroy();
            }catch(err){
                console.err(err);
            }
        }
        destructors = [];
    }

})();
