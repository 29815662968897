Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function() {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.VPŽaidimas.AnimacijųLapas = (mod) => Sk.misceval.buildClass(mod, function($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function(kwa) {

            const {self, failoVardas } = Utils.mapArgs(arguments, kwa, [{ failoVardas: null }]);
            
            self.jsonPath = failoVardas

            // Workaround for remapToJs
            self.v = self;

            return Utils.createSuspension(
                Pixi.createAnimationSheet(self.jsonPath).then(sheet => {
                    self.sheet = sheet;
                    return Sk.builtin.none.none$;
                }));
        });

        $loc.animacija = Utils.createMethodKWA(createAnimation);
        $loc.sukurk = $loc.animacija;

        function createAnimation(kwa){
            const {self, pavadinimas, greitis} = Utils.mapArgs(arguments, kwa, [ {pavadinimas: null}, {greitis: 0.1}]);

            return Utils.createPy(Sk.VPŽaidimas.Animacija(mod), [null, pavadinimas, greitis, self]);
        }

    }, "AnimacijųLapas", []);

})();
