Sk.Angis = Sk.Angis || {};

(function() {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.Angis.naudosiuFailus = Utils.createMethodKWA(loadFiles);

    function loadFiles(kwa) {
        const { failai } = Utils.mapArgsStatic(arguments, kwa, [{ failai: [] }]);

        return Sk.misceval.promiseToSuspension(Pixi.loadResources(failai));
    }

})();
