// generated by pgen/main.py
Sk.OpMap = {
"(": Sk.token.tokens.T_LPAR,
")": Sk.token.tokens.T_RPAR,
"[": Sk.token.tokens.T_LSQB,
"]": Sk.token.tokens.T_RSQB,
":": Sk.token.tokens.T_COLON,
",": Sk.token.tokens.T_COMMA,
";": Sk.token.tokens.T_SEMI,
"+": Sk.token.tokens.T_PLUS,
"-": Sk.token.tokens.T_MINUS,
"*": Sk.token.tokens.T_STAR,
"/": Sk.token.tokens.T_SLASH,
"|": Sk.token.tokens.T_VBAR,
"&": Sk.token.tokens.T_AMPER,
"<": Sk.token.tokens.T_LESS,
">": Sk.token.tokens.T_GREATER,
"=": Sk.token.tokens.T_EQUAL,
".": Sk.token.tokens.T_DOT,
"%": Sk.token.tokens.T_PERCENT,
"`": Sk.token.tokens.T_BACKQUOTE,
"{": Sk.token.tokens.T_LBRACE,
"}": Sk.token.tokens.T_RBRACE,
"@": Sk.token.tokens.T_AT,
"@=": Sk.token.tokens.T_ATEQUAL,
"==": Sk.token.tokens.T_EQEQUAL,
"!=": Sk.token.tokens.T_NOTEQUAL,
"<>": Sk.token.tokens.T_NOTEQUAL,
"<=": Sk.token.tokens.T_LESSEQUAL,
">=": Sk.token.tokens.T_GREATEREQUAL,
"~": Sk.token.tokens.T_TILDE,
"^": Sk.token.tokens.T_CIRCUMFLEX,
"<<": Sk.token.tokens.T_LEFTSHIFT,
">>": Sk.token.tokens.T_RIGHTSHIFT,
"**": Sk.token.tokens.T_DOUBLESTAR,
"+=": Sk.token.tokens.T_PLUSEQUAL,
"-=": Sk.token.tokens.T_MINEQUAL,
"*=": Sk.token.tokens.T_STAREQUAL,
"/=": Sk.token.tokens.T_SLASHEQUAL,
"%=": Sk.token.tokens.T_PERCENTEQUAL,
"&=": Sk.token.tokens.T_AMPEREQUAL,
"|=": Sk.token.tokens.T_VBAREQUAL,
"^=": Sk.token.tokens.T_CIRCUMFLEXEQUAL,
"<<=": Sk.token.tokens.T_LEFTSHIFTEQUAL,
">>=": Sk.token.tokens.T_RIGHTSHIFTEQUAL,
"**=": Sk.token.tokens.T_DOUBLESTAREQUAL,
"//": Sk.token.tokens.T_DOUBLESLASH,
"//=": Sk.token.tokens.T_DOUBLESLASHEQUAL,
"->": Sk.token.tokens.T_RARROW,
"...": Sk.token.tokens.T_ELLIPSIS
};
Sk.ParseTables = {
sym:
{and_expr: 257,
 and_test: 258,
 annassign: 259,
 arglist: 260,
 argument: 261,
 arith_expr: 262,
 assert_stmt: 263,
 async_funcdef: 264,
 async_stmt: 265,
 atom: 266,
 atom_expr: 267,
 augassign: 268,
 break_stmt: 269,
 classdef: 270,
 comp_for: 271,
 comp_if: 272,
 comp_iter: 273,
 comp_op: 274,
 comparison: 275,
 compound_stmt: 276,
 continue_stmt: 277,
 debugger_stmt: 278,
 decorated: 279,
 decorator: 280,
 decorators: 281,
 del_stmt: 282,
 dictorsetmaker: 283,
 dotted_as_name: 284,
 dotted_as_names: 285,
 dotted_name: 286,
 encoding_decl: 287,
 eval_input: 288,
 except_clause: 289,
 expr: 290,
 expr_stmt: 291,
 exprlist: 292,
 factor: 293,
 file_input: 294,
 flow_stmt: 295,
 for_stmt: 296,
 funcdef: 297,
 global_stmt: 298,
 if_stmt: 299,
 import_as_name: 300,
 import_as_names: 301,
 import_from: 302,
 import_name: 303,
 import_stmt: 304,
 kartok_stmt: 305,
 lambdef: 306,
 lambdef_nocond: 307,
 nonlocal_stmt: 308,
 not_test: 309,
 or_test: 310,
 parameters: 311,
 pass_stmt: 312,
 power: 313,
 print_stmt: 314,
 raise_stmt: 315,
 return_stmt: 316,
 shift_expr: 317,
 simple_stmt: 318,
 single_input: 256,
 sliceop: 319,
 small_stmt: 320,
 star_expr: 321,
 stmt: 322,
 subscript: 323,
 subscriptlist: 324,
 suite: 325,
 term: 326,
 test: 327,
 test_nocond: 328,
 testlist: 329,
 testlist_comp: 330,
 testlist_star_expr: 331,
 tfpdef: 332,
 trailer: 333,
 try_stmt: 334,
 typedargslist: 335,
 varargslist: 336,
 vfpdef: 337,
 while_stmt: 338,
 with_item: 339,
 with_stmt: 340,
 xor_expr: 341,
 yield_arg: 342,
 yield_expr: 343,
 yield_stmt: 344},
number2symbol:
{256: 'single_input',
 257: 'and_expr',
 258: 'and_test',
 259: 'annassign',
 260: 'arglist',
 261: 'argument',
 262: 'arith_expr',
 263: 'assert_stmt',
 264: 'async_funcdef',
 265: 'async_stmt',
 266: 'atom',
 267: 'atom_expr',
 268: 'augassign',
 269: 'break_stmt',
 270: 'classdef',
 271: 'comp_for',
 272: 'comp_if',
 273: 'comp_iter',
 274: 'comp_op',
 275: 'comparison',
 276: 'compound_stmt',
 277: 'continue_stmt',
 278: 'debugger_stmt',
 279: 'decorated',
 280: 'decorator',
 281: 'decorators',
 282: 'del_stmt',
 283: 'dictorsetmaker',
 284: 'dotted_as_name',
 285: 'dotted_as_names',
 286: 'dotted_name',
 287: 'encoding_decl',
 288: 'eval_input',
 289: 'except_clause',
 290: 'expr',
 291: 'expr_stmt',
 292: 'exprlist',
 293: 'factor',
 294: 'file_input',
 295: 'flow_stmt',
 296: 'for_stmt',
 297: 'funcdef',
 298: 'global_stmt',
 299: 'if_stmt',
 300: 'import_as_name',
 301: 'import_as_names',
 302: 'import_from',
 303: 'import_name',
 304: 'import_stmt',
 305: 'kartok_stmt',
 306: 'lambdef',
 307: 'lambdef_nocond',
 308: 'nonlocal_stmt',
 309: 'not_test',
 310: 'or_test',
 311: 'parameters',
 312: 'pass_stmt',
 313: 'power',
 314: 'print_stmt',
 315: 'raise_stmt',
 316: 'return_stmt',
 317: 'shift_expr',
 318: 'simple_stmt',
 319: 'sliceop',
 320: 'small_stmt',
 321: 'star_expr',
 322: 'stmt',
 323: 'subscript',
 324: 'subscriptlist',
 325: 'suite',
 326: 'term',
 327: 'test',
 328: 'test_nocond',
 329: 'testlist',
 330: 'testlist_comp',
 331: 'testlist_star_expr',
 332: 'tfpdef',
 333: 'trailer',
 334: 'try_stmt',
 335: 'typedargslist',
 336: 'varargslist',
 337: 'vfpdef',
 338: 'while_stmt',
 339: 'with_item',
 340: 'with_stmt',
 341: 'xor_expr',
 342: 'yield_arg',
 343: 'yield_expr',
 344: 'yield_stmt'},
dfas:
{256: [[[[1, 1], [2, 1], [3, 2]], [[0, 1]], [[1, 1]]],
       {1: 1,
        4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        21: 1,
        22: 1,
        23: 1,
        24: 1,
        25: 1,
        26: 1,
        27: 1,
        28: 1,
        29: 1,
        30: 1,
        31: 1,
        32: 1,
        33: 1,
        34: 1,
        35: 1,
        36: 1,
        37: 1,
        38: 1,
        39: 1,
        40: 1,
        41: 1,
        42: 1,
        43: 1,
        44: 1}],
 257: [[[[45, 1]], [[46, 0], [0, 1]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 258: [[[[47, 1]], [[48, 0], [0, 1]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 259: [[[[49, 1]], [[50, 2]], [[51, 3], [0, 2]], [[50, 4]], [[0, 4]]], {49: 1}],
 260: [[[[52, 1]], [[53, 2], [0, 1]], [[52, 1], [0, 2]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        54: 1}],
 261: [[[[50, 1], [54, 2], [20, 2]],
        [[55, 3], [51, 2], [0, 1]],
        [[50, 3]],
        [[0, 3]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        54: 1}],
 262: [[[[56, 1]], [[5, 0], [6, 0], [0, 1]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 263: [[[[32, 1]], [[50, 2]], [[53, 3], [0, 2]], [[50, 4]], [[0, 4]]], {32: 1}],
 264: [[[[44, 1]], [[57, 2]], [[0, 2]]], {44: 1}],
 265: [[[[44, 1]], [[57, 2], [58, 2], [59, 2]], [[0, 2]]], {44: 1}],
 266: [[[[9, 1],
         [10, 2],
         [11, 3],
         [12, 4],
         [13, 4],
         [14, 5],
         [15, 4],
         [16, 4],
         [17, 4],
         [18, 4]],
        [[60, 6], [61, 6], [62, 4]],
        [[61, 7], [63, 4]],
        [[64, 8], [65, 4]],
        [[0, 4]],
        [[14, 5], [0, 5]],
        [[62, 4]],
        [[63, 4]],
        [[65, 4]]],
       {9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1, 16: 1, 17: 1, 18: 1}],
 267: [[[[8, 1], [66, 2]], [[66, 2]], [[67, 2], [0, 2]]],
       {8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 268: [[[[68, 1],
         [69, 1],
         [70, 1],
         [71, 1],
         [72, 1],
         [73, 1],
         [74, 1],
         [75, 1],
         [76, 1],
         [77, 1],
         [78, 1],
         [79, 1],
         [80, 1]],
        [[0, 1]]],
       {68: 1,
        69: 1,
        70: 1,
        71: 1,
        72: 1,
        73: 1,
        74: 1,
        75: 1,
        76: 1,
        77: 1,
        78: 1,
        79: 1,
        80: 1}],
 269: [[[[23, 1]], [[0, 1]]], {23: 1}],
 270: [[[[42, 1]],
        [[12, 2]],
        [[9, 3], [49, 4]],
        [[81, 5], [62, 6]],
        [[82, 7]],
        [[62, 6]],
        [[49, 4]],
        [[0, 7]]],
       {42: 1}],
 271: [[[[44, 1], [38, 2]],
        [[38, 2]],
        [[83, 3]],
        [[84, 4]],
        [[85, 5]],
        [[86, 6], [0, 5]],
        [[0, 6]]],
       {38: 1, 44: 1}],
 272: [[[[35, 1]], [[87, 2]], [[86, 3], [0, 2]], [[0, 3]]], {35: 1}],
 273: [[[[55, 1], [88, 1]], [[0, 1]]], {35: 1, 38: 1, 44: 1}],
 274: [[[[89, 1],
         [90, 1],
         [91, 1],
         [92, 1],
         [93, 1],
         [94, 1],
         [94, 1],
         [84, 1],
         [4, 2],
         [95, 3]],
        [[0, 1]],
        [[84, 1]],
        [[4, 1], [0, 3]]],
       {4: 1, 84: 1, 89: 1, 90: 1, 91: 1, 92: 1, 93: 1, 94: 1, 95: 1}],
 275: [[[[96, 1]], [[97, 0], [0, 1]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 276: [[[[98, 1],
         [99, 1],
         [100, 1],
         [59, 1],
         [101, 1],
         [58, 1],
         [57, 1],
         [102, 1],
         [103, 1],
         [104, 1]],
        [[0, 1]]],
       {35: 1, 36: 1, 37: 1, 38: 1, 39: 1, 40: 1, 41: 1, 42: 1, 43: 1, 44: 1}],
 277: [[[[24, 1]], [[0, 1]]], {24: 1}],
 278: [[[[34, 1]], [[0, 1]]], {34: 1}],
 279: [[[[105, 1]], [[102, 2], [57, 2], [106, 2]], [[0, 2]]], {43: 1}],
 280: [[[[43, 1]],
        [[107, 2]],
        [[9, 3], [1, 4]],
        [[81, 5], [62, 6]],
        [[0, 4]],
        [[62, 6]],
        [[1, 4]]],
       {43: 1}],
 281: [[[[108, 1]], [[108, 1], [0, 1]]], {43: 1}],
 282: [[[[21, 1]], [[83, 2]], [[0, 2]]], {21: 1}],
 283: [[[[50, 1], [54, 2], [109, 3]],
        [[49, 4], [55, 5], [53, 6], [0, 1]],
        [[96, 7]],
        [[55, 5], [53, 6], [0, 3]],
        [[50, 7]],
        [[0, 5]],
        [[50, 8], [109, 8], [0, 6]],
        [[55, 5], [53, 9], [0, 7]],
        [[53, 6], [0, 8]],
        [[50, 10], [54, 11], [0, 9]],
        [[49, 12]],
        [[96, 13]],
        [[50, 13]],
        [[53, 9], [0, 13]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        54: 1}],
 284: [[[[107, 1]], [[110, 2], [0, 1]], [[12, 3]], [[0, 3]]], {12: 1}],
 285: [[[[111, 1]], [[53, 0], [0, 1]]], {12: 1}],
 286: [[[[12, 1]], [[112, 0], [0, 1]]], {12: 1}],
 287: [[[[12, 1]], [[0, 1]]], {12: 1}],
 288: [[[[113, 1]], [[1, 1], [114, 2]], [[0, 2]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1}],
 289: [[[[115, 1]],
        [[50, 2], [0, 1]],
        [[110, 3], [53, 3], [0, 2]],
        [[50, 4]],
        [[0, 4]]],
       {115: 1}],
 290: [[[[116, 1]], [[117, 0], [0, 1]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 291: [[[[118, 1]],
        [[119, 2], [120, 3], [51, 4], [0, 1]],
        [[0, 2]],
        [[60, 2], [113, 2]],
        [[60, 5], [118, 5]],
        [[51, 4], [0, 5]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1}],
 292: [[[[96, 1], [109, 1]], [[53, 2], [0, 1]], [[96, 1], [109, 1], [0, 2]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        20: 1}],
 293: [[[[5, 1], [6, 1], [7, 1], [121, 2]], [[122, 2]], [[0, 2]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 294: [[[[1, 0], [123, 0], [114, 1]], [[0, 1]]],
       {1: 1,
        4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        21: 1,
        22: 1,
        23: 1,
        24: 1,
        25: 1,
        26: 1,
        27: 1,
        28: 1,
        29: 1,
        30: 1,
        31: 1,
        32: 1,
        33: 1,
        34: 1,
        35: 1,
        36: 1,
        37: 1,
        38: 1,
        39: 1,
        40: 1,
        41: 1,
        42: 1,
        43: 1,
        44: 1,
        114: 1}],
 295: [[[[124, 1], [125, 1], [126, 1], [127, 1], [128, 1]], [[0, 1]]],
       {23: 1, 24: 1, 25: 1, 26: 1, 27: 1}],
 296: [[[[38, 1]],
        [[83, 2]],
        [[84, 3]],
        [[113, 4]],
        [[49, 5]],
        [[82, 6]],
        [[129, 7], [0, 6]],
        [[49, 8]],
        [[82, 9]],
        [[0, 9]]],
       {38: 1}],
 297: [[[[41, 1]],
        [[12, 2]],
        [[130, 3]],
        [[131, 4], [49, 5]],
        [[50, 6]],
        [[82, 7]],
        [[49, 5]],
        [[0, 7]]],
       {41: 1}],
 298: [[[[30, 1]], [[12, 2]], [[53, 1], [0, 2]]], {30: 1}],
 299: [[[[35, 1]],
        [[50, 2]],
        [[49, 3]],
        [[82, 4]],
        [[132, 1], [129, 5], [0, 4]],
        [[49, 6]],
        [[82, 7]],
        [[0, 7]]],
       {35: 1}],
 300: [[[[12, 1]], [[110, 2], [0, 1]], [[12, 3]], [[0, 3]]], {12: 1}],
 301: [[[[133, 1]], [[53, 2], [0, 1]], [[133, 1], [0, 2]]], {12: 1}],
 302: [[[[29, 1]],
        [[112, 2], [15, 2], [107, 3]],
        [[112, 2], [15, 2], [107, 3], [28, 4]],
        [[28, 4]],
        [[20, 5], [9, 6], [134, 5]],
        [[0, 5]],
        [[134, 7]],
        [[62, 5]]],
       {29: 1}],
 303: [[[[28, 1]], [[135, 2]], [[0, 2]]], {28: 1}],
 304: [[[[136, 1], [137, 1]], [[0, 1]]], {28: 1, 29: 1}],
 305: [[[[37, 1]], [[50, 2]], [[49, 3]], [[82, 4]], [[0, 4]]], {37: 1}],
 306: [[[[19, 1]], [[138, 2], [49, 3]], [[49, 3]], [[50, 4]], [[0, 4]]],
       {19: 1}],
 307: [[[[19, 1]], [[138, 2], [49, 3]], [[49, 3]], [[87, 4]], [[0, 4]]],
       {19: 1}],
 308: [[[[31, 1]], [[12, 2]], [[53, 1], [0, 2]]], {31: 1}],
 309: [[[[4, 1], [139, 2]], [[47, 2]], [[0, 2]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 310: [[[[140, 1]], [[141, 0], [0, 1]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 311: [[[[9, 1]], [[142, 2], [62, 3]], [[62, 3]], [[0, 3]]], {9: 1}],
 312: [[[[22, 1]], [[0, 1]]], {22: 1}],
 313: [[[[143, 1]], [[54, 2], [0, 1]], [[122, 3]], [[0, 3]]],
       {8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 314: [[[[33, 1]],
        [[50, 2], [144, 3], [0, 1]],
        [[53, 4], [0, 2]],
        [[50, 5]],
        [[50, 2], [0, 4]],
        [[53, 6], [0, 5]],
        [[50, 7]],
        [[53, 8], [0, 7]],
        [[50, 7], [0, 8]]],
       {33: 1}],
 315: [[[[26, 1]],
        [[50, 2], [0, 1]],
        [[29, 3], [53, 3], [0, 2]],
        [[50, 4]],
        [[53, 5], [0, 4]],
        [[50, 6]],
        [[0, 6]]],
       {26: 1}],
 316: [[[[25, 1]], [[113, 2], [0, 1]], [[0, 2]]], {25: 1}],
 317: [[[[145, 1]], [[146, 0], [144, 0], [0, 1]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 318: [[[[147, 1]], [[148, 2], [1, 3]], [[147, 1], [1, 3]], [[0, 3]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        21: 1,
        22: 1,
        23: 1,
        24: 1,
        25: 1,
        26: 1,
        27: 1,
        28: 1,
        29: 1,
        30: 1,
        31: 1,
        32: 1,
        33: 1,
        34: 1}],
 319: [[[[49, 1]], [[50, 2], [0, 1]], [[0, 2]]], {49: 1}],
 320: [[[[149, 1],
         [150, 1],
         [151, 1],
         [152, 1],
         [153, 1],
         [154, 1],
         [155, 1],
         [156, 1],
         [157, 1],
         [158, 1]],
        [[0, 1]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        21: 1,
        22: 1,
        23: 1,
        24: 1,
        25: 1,
        26: 1,
        27: 1,
        28: 1,
        29: 1,
        30: 1,
        31: 1,
        32: 1,
        33: 1,
        34: 1}],
 321: [[[[20, 1]], [[96, 2]], [[0, 2]]], {20: 1}],
 322: [[[[2, 1], [3, 1]], [[0, 1]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        21: 1,
        22: 1,
        23: 1,
        24: 1,
        25: 1,
        26: 1,
        27: 1,
        28: 1,
        29: 1,
        30: 1,
        31: 1,
        32: 1,
        33: 1,
        34: 1,
        35: 1,
        36: 1,
        37: 1,
        38: 1,
        39: 1,
        40: 1,
        41: 1,
        42: 1,
        43: 1,
        44: 1}],
 323: [[[[50, 1], [49, 2]],
        [[49, 2], [0, 1]],
        [[50, 3], [159, 4], [0, 2]],
        [[159, 4], [0, 3]],
        [[0, 4]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        49: 1}],
 324: [[[[160, 1]], [[53, 2], [0, 1]], [[160, 1], [0, 2]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        49: 1}],
 325: [[[[2, 1], [1, 2]],
        [[0, 1]],
        [[161, 3]],
        [[123, 4]],
        [[123, 4], [162, 1]]],
       {1: 1,
        4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1,
        21: 1,
        22: 1,
        23: 1,
        24: 1,
        25: 1,
        26: 1,
        27: 1,
        28: 1,
        29: 1,
        30: 1,
        31: 1,
        32: 1,
        33: 1,
        34: 1}],
 326: [[[[122, 1]], [[20, 0], [43, 0], [163, 0], [164, 0], [165, 0], [0, 1]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 327: [[[[85, 1], [166, 2]],
        [[35, 3], [0, 1]],
        [[0, 2]],
        [[85, 4]],
        [[129, 5]],
        [[50, 2]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1}],
 328: [[[[85, 1], [167, 1]], [[0, 1]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1}],
 329: [[[[50, 1]], [[53, 2], [0, 1]], [[50, 1], [0, 2]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1}],
 330: [[[[50, 1], [109, 1]],
        [[55, 2], [53, 3], [0, 1]],
        [[0, 2]],
        [[50, 4], [109, 4], [0, 3]],
        [[53, 3], [0, 4]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1}],
 331: [[[[50, 1], [109, 1]], [[53, 2], [0, 1]], [[50, 1], [109, 1], [0, 2]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        20: 1}],
 332: [[[[12, 1]], [[49, 2], [0, 1]], [[50, 3]], [[0, 3]]], {12: 1}],
 333: [[[[9, 1], [10, 2], [112, 3]],
        [[81, 4], [62, 5]],
        [[168, 6]],
        [[12, 5]],
        [[62, 5]],
        [[0, 5]],
        [[63, 5]]],
       {9: 1, 10: 1, 112: 1}],
 334: [[[[39, 1]],
        [[49, 2]],
        [[82, 3]],
        [[169, 4], [170, 5]],
        [[49, 6]],
        [[49, 7]],
        [[82, 8]],
        [[82, 9]],
        [[169, 4], [129, 10], [170, 5], [0, 8]],
        [[0, 9]],
        [[49, 11]],
        [[82, 12]],
        [[170, 5], [0, 12]]],
       {39: 1}],
 335: [[[[171, 1], [20, 2], [54, 3]],
        [[51, 4], [53, 5], [0, 1]],
        [[171, 6], [53, 7], [0, 2]],
        [[171, 8]],
        [[50, 9]],
        [[171, 1], [20, 10], [54, 3], [0, 5]],
        [[53, 7], [0, 6]],
        [[171, 11], [54, 3], [0, 7]],
        [[53, 12], [0, 8]],
        [[53, 5], [0, 9]],
        [[171, 13], [53, 14], [0, 10]],
        [[51, 15], [53, 7], [0, 11]],
        [[0, 12]],
        [[53, 14], [0, 13]],
        [[171, 16], [54, 3], [0, 14]],
        [[50, 6]],
        [[51, 17], [53, 14], [0, 16]],
        [[50, 13]]],
       {12: 1, 20: 1, 54: 1}],
 336: [[[[172, 1], [20, 2], [54, 3]],
        [[51, 4], [53, 5], [0, 1]],
        [[172, 6], [53, 7], [0, 2]],
        [[172, 8]],
        [[50, 9]],
        [[172, 1], [20, 10], [54, 3], [0, 5]],
        [[53, 7], [0, 6]],
        [[172, 11], [54, 3], [0, 7]],
        [[53, 12], [0, 8]],
        [[53, 5], [0, 9]],
        [[172, 13], [53, 14], [0, 10]],
        [[51, 15], [53, 7], [0, 11]],
        [[0, 12]],
        [[53, 14], [0, 13]],
        [[172, 16], [54, 3], [0, 14]],
        [[50, 6]],
        [[51, 17], [53, 14], [0, 16]],
        [[50, 13]]],
       {12: 1, 20: 1, 54: 1}],
 337: [[[[12, 1]], [[0, 1]]], {12: 1}],
 338: [[[[36, 1]],
        [[50, 2]],
        [[49, 3]],
        [[82, 4]],
        [[129, 5], [0, 4]],
        [[49, 6]],
        [[82, 7]],
        [[0, 7]]],
       {36: 1}],
 339: [[[[50, 1]], [[110, 2], [0, 1]], [[96, 3]], [[0, 3]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1}],
 340: [[[[40, 1]], [[173, 2]], [[53, 1], [49, 3]], [[82, 4]], [[0, 4]]],
       {40: 1}],
 341: [[[[174, 1]], [[175, 0], [0, 1]]],
       {5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1}],
 342: [[[[29, 1], [113, 2]], [[50, 2]], [[0, 2]]],
       {4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 1,
        29: 1}],
 343: [[[[27, 1]], [[176, 2], [0, 1]], [[0, 2]]], {27: 1}],
 344: [[[[60, 1]], [[0, 1]]], {27: 1}]},
states:
[[[[1, 1], [2, 1], [3, 2]], [[0, 1]], [[1, 1]]],
 [[[45, 1]], [[46, 0], [0, 1]]],
 [[[47, 1]], [[48, 0], [0, 1]]],
 [[[49, 1]], [[50, 2]], [[51, 3], [0, 2]], [[50, 4]], [[0, 4]]],
 [[[52, 1]], [[53, 2], [0, 1]], [[52, 1], [0, 2]]],
 [[[50, 1], [54, 2], [20, 2]], [[55, 3], [51, 2], [0, 1]], [[50, 3]], [[0, 3]]],
 [[[56, 1]], [[5, 0], [6, 0], [0, 1]]],
 [[[32, 1]], [[50, 2]], [[53, 3], [0, 2]], [[50, 4]], [[0, 4]]],
 [[[44, 1]], [[57, 2]], [[0, 2]]],
 [[[44, 1]], [[57, 2], [58, 2], [59, 2]], [[0, 2]]],
 [[[9, 1],
   [10, 2],
   [11, 3],
   [12, 4],
   [13, 4],
   [14, 5],
   [15, 4],
   [16, 4],
   [17, 4],
   [18, 4]],
  [[60, 6], [61, 6], [62, 4]],
  [[61, 7], [63, 4]],
  [[64, 8], [65, 4]],
  [[0, 4]],
  [[14, 5], [0, 5]],
  [[62, 4]],
  [[63, 4]],
  [[65, 4]]],
 [[[8, 1], [66, 2]], [[66, 2]], [[67, 2], [0, 2]]],
 [[[68, 1],
   [69, 1],
   [70, 1],
   [71, 1],
   [72, 1],
   [73, 1],
   [74, 1],
   [75, 1],
   [76, 1],
   [77, 1],
   [78, 1],
   [79, 1],
   [80, 1]],
  [[0, 1]]],
 [[[23, 1]], [[0, 1]]],
 [[[42, 1]],
  [[12, 2]],
  [[9, 3], [49, 4]],
  [[81, 5], [62, 6]],
  [[82, 7]],
  [[62, 6]],
  [[49, 4]],
  [[0, 7]]],
 [[[44, 1], [38, 2]],
  [[38, 2]],
  [[83, 3]],
  [[84, 4]],
  [[85, 5]],
  [[86, 6], [0, 5]],
  [[0, 6]]],
 [[[35, 1]], [[87, 2]], [[86, 3], [0, 2]], [[0, 3]]],
 [[[55, 1], [88, 1]], [[0, 1]]],
 [[[89, 1],
   [90, 1],
   [91, 1],
   [92, 1],
   [93, 1],
   [94, 1],
   [94, 1],
   [84, 1],
   [4, 2],
   [95, 3]],
  [[0, 1]],
  [[84, 1]],
  [[4, 1], [0, 3]]],
 [[[96, 1]], [[97, 0], [0, 1]]],
 [[[98, 1],
   [99, 1],
   [100, 1],
   [59, 1],
   [101, 1],
   [58, 1],
   [57, 1],
   [102, 1],
   [103, 1],
   [104, 1]],
  [[0, 1]]],
 [[[24, 1]], [[0, 1]]],
 [[[34, 1]], [[0, 1]]],
 [[[105, 1]], [[102, 2], [57, 2], [106, 2]], [[0, 2]]],
 [[[43, 1]],
  [[107, 2]],
  [[9, 3], [1, 4]],
  [[81, 5], [62, 6]],
  [[0, 4]],
  [[62, 6]],
  [[1, 4]]],
 [[[108, 1]], [[108, 1], [0, 1]]],
 [[[21, 1]], [[83, 2]], [[0, 2]]],
 [[[50, 1], [54, 2], [109, 3]],
  [[49, 4], [55, 5], [53, 6], [0, 1]],
  [[96, 7]],
  [[55, 5], [53, 6], [0, 3]],
  [[50, 7]],
  [[0, 5]],
  [[50, 8], [109, 8], [0, 6]],
  [[55, 5], [53, 9], [0, 7]],
  [[53, 6], [0, 8]],
  [[50, 10], [54, 11], [0, 9]],
  [[49, 12]],
  [[96, 13]],
  [[50, 13]],
  [[53, 9], [0, 13]]],
 [[[107, 1]], [[110, 2], [0, 1]], [[12, 3]], [[0, 3]]],
 [[[111, 1]], [[53, 0], [0, 1]]],
 [[[12, 1]], [[112, 0], [0, 1]]],
 [[[12, 1]], [[0, 1]]],
 [[[113, 1]], [[1, 1], [114, 2]], [[0, 2]]],
 [[[115, 1]],
  [[50, 2], [0, 1]],
  [[110, 3], [53, 3], [0, 2]],
  [[50, 4]],
  [[0, 4]]],
 [[[116, 1]], [[117, 0], [0, 1]]],
 [[[118, 1]],
  [[119, 2], [120, 3], [51, 4], [0, 1]],
  [[0, 2]],
  [[60, 2], [113, 2]],
  [[60, 5], [118, 5]],
  [[51, 4], [0, 5]]],
 [[[96, 1], [109, 1]], [[53, 2], [0, 1]], [[96, 1], [109, 1], [0, 2]]],
 [[[5, 1], [6, 1], [7, 1], [121, 2]], [[122, 2]], [[0, 2]]],
 [[[1, 0], [123, 0], [114, 1]], [[0, 1]]],
 [[[124, 1], [125, 1], [126, 1], [127, 1], [128, 1]], [[0, 1]]],
 [[[38, 1]],
  [[83, 2]],
  [[84, 3]],
  [[113, 4]],
  [[49, 5]],
  [[82, 6]],
  [[129, 7], [0, 6]],
  [[49, 8]],
  [[82, 9]],
  [[0, 9]]],
 [[[41, 1]],
  [[12, 2]],
  [[130, 3]],
  [[131, 4], [49, 5]],
  [[50, 6]],
  [[82, 7]],
  [[49, 5]],
  [[0, 7]]],
 [[[30, 1]], [[12, 2]], [[53, 1], [0, 2]]],
 [[[35, 1]],
  [[50, 2]],
  [[49, 3]],
  [[82, 4]],
  [[132, 1], [129, 5], [0, 4]],
  [[49, 6]],
  [[82, 7]],
  [[0, 7]]],
 [[[12, 1]], [[110, 2], [0, 1]], [[12, 3]], [[0, 3]]],
 [[[133, 1]], [[53, 2], [0, 1]], [[133, 1], [0, 2]]],
 [[[29, 1]],
  [[112, 2], [15, 2], [107, 3]],
  [[112, 2], [15, 2], [107, 3], [28, 4]],
  [[28, 4]],
  [[20, 5], [9, 6], [134, 5]],
  [[0, 5]],
  [[134, 7]],
  [[62, 5]]],
 [[[28, 1]], [[135, 2]], [[0, 2]]],
 [[[136, 1], [137, 1]], [[0, 1]]],
 [[[37, 1]], [[50, 2]], [[49, 3]], [[82, 4]], [[0, 4]]],
 [[[19, 1]], [[138, 2], [49, 3]], [[49, 3]], [[50, 4]], [[0, 4]]],
 [[[19, 1]], [[138, 2], [49, 3]], [[49, 3]], [[87, 4]], [[0, 4]]],
 [[[31, 1]], [[12, 2]], [[53, 1], [0, 2]]],
 [[[4, 1], [139, 2]], [[47, 2]], [[0, 2]]],
 [[[140, 1]], [[141, 0], [0, 1]]],
 [[[9, 1]], [[142, 2], [62, 3]], [[62, 3]], [[0, 3]]],
 [[[22, 1]], [[0, 1]]],
 [[[143, 1]], [[54, 2], [0, 1]], [[122, 3]], [[0, 3]]],
 [[[33, 1]],
  [[50, 2], [144, 3], [0, 1]],
  [[53, 4], [0, 2]],
  [[50, 5]],
  [[50, 2], [0, 4]],
  [[53, 6], [0, 5]],
  [[50, 7]],
  [[53, 8], [0, 7]],
  [[50, 7], [0, 8]]],
 [[[26, 1]],
  [[50, 2], [0, 1]],
  [[29, 3], [53, 3], [0, 2]],
  [[50, 4]],
  [[53, 5], [0, 4]],
  [[50, 6]],
  [[0, 6]]],
 [[[25, 1]], [[113, 2], [0, 1]], [[0, 2]]],
 [[[145, 1]], [[146, 0], [144, 0], [0, 1]]],
 [[[147, 1]], [[148, 2], [1, 3]], [[147, 1], [1, 3]], [[0, 3]]],
 [[[49, 1]], [[50, 2], [0, 1]], [[0, 2]]],
 [[[149, 1],
   [150, 1],
   [151, 1],
   [152, 1],
   [153, 1],
   [154, 1],
   [155, 1],
   [156, 1],
   [157, 1],
   [158, 1]],
  [[0, 1]]],
 [[[20, 1]], [[96, 2]], [[0, 2]]],
 [[[2, 1], [3, 1]], [[0, 1]]],
 [[[50, 1], [49, 2]],
  [[49, 2], [0, 1]],
  [[50, 3], [159, 4], [0, 2]],
  [[159, 4], [0, 3]],
  [[0, 4]]],
 [[[160, 1]], [[53, 2], [0, 1]], [[160, 1], [0, 2]]],
 [[[2, 1], [1, 2]], [[0, 1]], [[161, 3]], [[123, 4]], [[123, 4], [162, 1]]],
 [[[122, 1]], [[20, 0], [43, 0], [163, 0], [164, 0], [165, 0], [0, 1]]],
 [[[85, 1], [166, 2]],
  [[35, 3], [0, 1]],
  [[0, 2]],
  [[85, 4]],
  [[129, 5]],
  [[50, 2]]],
 [[[85, 1], [167, 1]], [[0, 1]]],
 [[[50, 1]], [[53, 2], [0, 1]], [[50, 1], [0, 2]]],
 [[[50, 1], [109, 1]],
  [[55, 2], [53, 3], [0, 1]],
  [[0, 2]],
  [[50, 4], [109, 4], [0, 3]],
  [[53, 3], [0, 4]]],
 [[[50, 1], [109, 1]], [[53, 2], [0, 1]], [[50, 1], [109, 1], [0, 2]]],
 [[[12, 1]], [[49, 2], [0, 1]], [[50, 3]], [[0, 3]]],
 [[[9, 1], [10, 2], [112, 3]],
  [[81, 4], [62, 5]],
  [[168, 6]],
  [[12, 5]],
  [[62, 5]],
  [[0, 5]],
  [[63, 5]]],
 [[[39, 1]],
  [[49, 2]],
  [[82, 3]],
  [[169, 4], [170, 5]],
  [[49, 6]],
  [[49, 7]],
  [[82, 8]],
  [[82, 9]],
  [[169, 4], [129, 10], [170, 5], [0, 8]],
  [[0, 9]],
  [[49, 11]],
  [[82, 12]],
  [[170, 5], [0, 12]]],
 [[[171, 1], [20, 2], [54, 3]],
  [[51, 4], [53, 5], [0, 1]],
  [[171, 6], [53, 7], [0, 2]],
  [[171, 8]],
  [[50, 9]],
  [[171, 1], [20, 10], [54, 3], [0, 5]],
  [[53, 7], [0, 6]],
  [[171, 11], [54, 3], [0, 7]],
  [[53, 12], [0, 8]],
  [[53, 5], [0, 9]],
  [[171, 13], [53, 14], [0, 10]],
  [[51, 15], [53, 7], [0, 11]],
  [[0, 12]],
  [[53, 14], [0, 13]],
  [[171, 16], [54, 3], [0, 14]],
  [[50, 6]],
  [[51, 17], [53, 14], [0, 16]],
  [[50, 13]]],
 [[[172, 1], [20, 2], [54, 3]],
  [[51, 4], [53, 5], [0, 1]],
  [[172, 6], [53, 7], [0, 2]],
  [[172, 8]],
  [[50, 9]],
  [[172, 1], [20, 10], [54, 3], [0, 5]],
  [[53, 7], [0, 6]],
  [[172, 11], [54, 3], [0, 7]],
  [[53, 12], [0, 8]],
  [[53, 5], [0, 9]],
  [[172, 13], [53, 14], [0, 10]],
  [[51, 15], [53, 7], [0, 11]],
  [[0, 12]],
  [[53, 14], [0, 13]],
  [[172, 16], [54, 3], [0, 14]],
  [[50, 6]],
  [[51, 17], [53, 14], [0, 16]],
  [[50, 13]]],
 [[[12, 1]], [[0, 1]]],
 [[[36, 1]],
  [[50, 2]],
  [[49, 3]],
  [[82, 4]],
  [[129, 5], [0, 4]],
  [[49, 6]],
  [[82, 7]],
  [[0, 7]]],
 [[[50, 1]], [[110, 2], [0, 1]], [[96, 3]], [[0, 3]]],
 [[[40, 1]], [[173, 2]], [[53, 1], [49, 3]], [[82, 4]], [[0, 4]]],
 [[[174, 1]], [[175, 0], [0, 1]]],
 [[[29, 1], [113, 2]], [[50, 2]], [[0, 2]]],
 [[[27, 1]], [[176, 2], [0, 1]], [[0, 2]]],
 [[[60, 1]], [[0, 1]]]],
labels:
[[0, 'EMPTY'],
 [4, null],
 [318, null],
 [276, null],
 [1, 'not'],
 [14, null],
 [15, null],
 [31, null],
 [54, null],
 [7, null],
 [9, null],
 [25, null],
 [1, null],
 [2, null],
 [3, null],
 [52, null],
 [1, 'null'],
 [1, 'True'],
 [1, 'False'],
 [1, 'lambda'],
 [16, null],
 [1, 'del'],
 [1, 'pass'],
 [1, 'break'],
 [1, 'continue'],
 [1, 'return'],
 [1, 'raise'],
 [1, 'yield'],
 [1, 'import'],
 [1, 'from'],
 [1, 'global'],
 [1, 'nonlocal'],
 [1, 'assert'],
 [1, 'print'],
 [1, 'debugger'],
 [1, 'if'],
 [1, 'while'],
 [1, 'kartok'],
 [1, 'for'],
 [1, 'try'],
 [1, 'with'],
 [1, 'def'],
 [1, 'class'],
 [49, null],
 [55, null],
 [317, null],
 [19, null],
 [309, null],
 [1, 'and'],
 [11, null],
 [327, null],
 [22, null],
 [261, null],
 [12, null],
 [35, null],
 [271, null],
 [326, null],
 [297, null],
 [340, null],
 [296, null],
 [343, null],
 [330, null],
 [8, null],
 [10, null],
 [283, null],
 [26, null],
 [266, null],
 [333, null],
 [36, null],
 [37, null],
 [38, null],
 [50, null],
 [39, null],
 [40, null],
 [41, null],
 [42, null],
 [43, null],
 [44, null],
 [45, null],
 [46, null],
 [48, null],
 [260, null],
 [325, null],
 [292, null],
 [1, 'in'],
 [310, null],
 [273, null],
 [328, null],
 [272, null],
 [20, null],
 [21, null],
 [27, null],
 [30, null],
 [29, null],
 [28, null],
 [1, 'is'],
 [290, null],
 [274, null],
 [299, null],
 [338, null],
 [305, null],
 [334, null],
 [270, null],
 [279, null],
 [265, null],
 [281, null],
 [264, null],
 [286, null],
 [280, null],
 [321, null],
 [1, 'as'],
 [284, null],
 [23, null],
 [329, null],
 [0, null],
 [1, 'except'],
 [341, null],
 [18, null],
 [331, null],
 [259, null],
 [268, null],
 [313, null],
 [293, null],
 [322, null],
 [269, null],
 [277, null],
 [316, null],
 [315, null],
 [344, null],
 [1, 'else'],
 [311, null],
 [51, null],
 [1, 'elif'],
 [300, null],
 [301, null],
 [285, null],
 [303, null],
 [302, null],
 [336, null],
 [275, null],
 [258, null],
 [1, 'or'],
 [335, null],
 [267, null],
 [34, null],
 [262, null],
 [33, null],
 [320, null],
 [13, null],
 [291, null],
 [282, null],
 [312, null],
 [295, null],
 [304, null],
 [298, null],
 [308, null],
 [263, null],
 [314, null],
 [278, null],
 [319, null],
 [323, null],
 [5, null],
 [6, null],
 [17, null],
 [24, null],
 [47, null],
 [306, null],
 [307, null],
 [324, null],
 [289, null],
 [1, 'finally'],
 [332, null],
 [337, null],
 [339, null],
 [257, null],
 [32, null],
 [342, null]],
keywords:
{'False': 18,
 'Neteisingai': 18,
 'null': 16,
 'True': 17,
 'Teisingai':17,
 'and': 48,
 'ir':48,
 'as': 110,
 'kaip': 110,
 'assert': 32,
 'break': 23,
 'nutrauk': 23,
 'class': 42,
 'continue': 24,
 'tęsk': 24,
 'debugger': 34,
 'def': 41,
 'komanda':41,
 'del': 21,
 'elif': 132,
 'ojei': 132,
 'ojeigu': 132,
 'else': 129,
 'kituatveju': 129,
 'except': 115,
 'nepavykus': 115,
 'finally': 170,
 'galiausiai':170,
 'for': 38,
 'kiekvienam': 38,
 'from': 29,
 'bibliotekoje': 29,
 'global': 30,
 'globalus': 30,
 'globali': 30,
 'if': 35,
 'jei':35,
 'jeigu':35,
 'import': 28,
 'reikalingas': 28,
 'reikalinga': 28,
 'in': 84,
 'iš': 84,
 'is': 95,
 'yra': 95,
 'kartok': 37,
 'lambda': 19,
 'nonlocal': 31,
 'not': 4,
 'ne': 4,
 'or': 141,
 'arba': 141,
 'pass': 22,
 'tiekto': 22,
 'print': 33,
 'rašyk': 33,
 'raise': 26,
 'sukelk': 26,
 'return': 25,
 'grąžink': 25,
 'grįžk':25,
 'try': 39,
 'bandyk': 39,
 'while': 36,
 'kol': 36,
 'with': 40,
 'su': 40,
 'yield': 27},
tokens:
{0: 114,
 1: 12,
 2: 13,
 3: 14,
 4: 1,
 5: 161,
 6: 162,
 7: 9,
 8: 62,
 9: 10,
 10: 63,
 11: 49,
 12: 53,
 13: 148,
 14: 5,
 15: 6,
 16: 20,
 17: 163,
 18: 117,
 19: 46,
 20: 89,
 21: 90,
 22: 51,
 23: 112,
 24: 164,
 25: 11,
 26: 65,
 27: 91,
 28: 94,
 29: 93,
 30: 92,
 31: 7,
 32: 175,
 33: 146,
 34: 144,
 35: 54,
 36: 68,
 37: 69,
 38: 70,
 39: 72,
 40: 73,
 41: 74,
 42: 75,
 43: 76,
 44: 77,
 45: 78,
 46: 79,
 47: 165,
 48: 80,
 49: 43,
 50: 71,
 51: 131,
 52: 15,
 54: 8,
 55: 44},
start: 256
};

