Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function () {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.VPŽaidimas.Indikatorius = (mod) => Sk.misceval.buildClass(mod, function ($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function (kwa) {
            let { self, x, y, ilgis, aukštis, spalva } = Utils.mapArgs(arguments, kwa, [
                                                            { x: -1 }, { y: -1 }, 
                                                            { ilgis: 100}, { aukštis: 20 },
                                                            { spalva: "#00FF00" }]);

            x = x - 0;
            y = y - 0;
            ilgis = ilgis - 0;
            aukštis = aukštis - 0;
            IndikatoriusConstructor(self, x, y, ilgis, aukštis, spalva);
        });

        $loc.duokKoordinates = Utils.createMethod(getCoordinates);

        $loc.reikšmė = Utils.createMethodKWA(setValueKwa);

        $loc.pasislėpk = Utils.createMethod(function (self) { self.hide(); });
        $loc.slėpkis = $loc.pasislėpk;
        $loc.hide = $loc.pasislėpk;

        $loc.teleportuokis = Utils.createMethodKWA(teleport);
        $loc.atsirask = $loc.teleportuokis;
        $loc.show = $loc.atsirask;
        $loc.teleport = $loc.atsirask;

        $loc.arMatomas = Utils.createMethod(function(self) { return new Sk.builtin.bool (self && self.v && self.v.isVisible && self.v.isVisible()); });
        $loc.yraMatomas = $loc.arMatomas;
        $loc.matomas = $loc.arMatomas;
        $loc.isVisible = $loc.arMatomas;
        $loc.visible = $loc.arMatomas;

    }, "Indikatorius", []);

    Sk.VPŽaidimas.Indikatorius.ctor = IndikatoriusConstructor;

    function IndikatoriusConstructor(self, x, y, ilgis, aukštis, spalva) {
        console.log("Indikatorius ctor");

        self.ilgis = ilgis;
        self.aukštis = aukštis;
        self.spalva = spalva || "00FF00";

        self.container = Pixi.createContainer();

        self.graphics = new PIXI.Graphics();
        self.container.addChild(self.graphics);

        self.value = 0;

        drawProgressBar(self);
    
        // my creator will add(indikatorius.container) to it's own container
        self.container.x = x;
        self.container.y = y;
        self.container.zIndex = 1;

        if (self.x <= 0 && self.y <= 0)
            self.container.visible = false;

        self.obstacleArea = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };

        self.hitArea = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };

        // virtual (overridable) methods
        self.show = () => show(self);
        self.hide = () => hide(self);
        self.isVisible = function(){ return self.container.visible; };

        // Workaround for remapToJs
        self.v = self;
        
        Sk.VPŽaidimas.getOrCreateLayer().v.addElement(self, x, y);

        self.container.zOrder = 1000;
    }

    function drawProgressBar(self){
        self.graphics.clear();
        drawOutline();
        drawBar(self.value, 100);
        
        function drawOutline(){
            self.graphics.lineStyle(2, 0xFF00FF, 1);
            self.graphics.beginFill(0x650A5A, 0.25);    
            self.graphics.drawRoundedRect(0, 0, self.ilgis, self.aukštis, 4);
            self.graphics.endFill();
        }
    
        function drawBar(current, max){
            if (!current)
                return;
    
            if (current > max)
                current = max;  
    
            self.graphics.lineStyle(2, self.spalva, 1);
            self.graphics.beginFill(self.spalva);
            self.graphics.drawRoundedRect(2, 2, (current / max) * self.ilgis - 4 , self.aukštis - 4, 4);
            self.graphics.endFill();
        }

    }
    
    function setValueKwa(kwa){
        const { self, value } = Utils.mapArgs(arguments, kwa, [{ value: null }]);

        if (value != null && !isNaN(value)){
            self.value = value - 0;
            if (self.value < 0)
                self.value = 0;
            drawProgressBar(self);
        }

        return new Sk.builtin.int_(self.value);
    }

    function getCoordinates(self) {
        return new Sk.builtin.tuple([
            new Sk.builtin.int_(self.container.x),
            new Sk.builtin.int_(self.container.y)
        ]);
    }

    function teleport(kwa) {
        const { self, x, y } = Utils.mapArgs(arguments, kwa, [{ x: null }, { y: null }]);

        return __teleportTo(self, x, y);
    }

    function __teleportTo(self, newX, newY) {
        self.show();

        if (newX == null && newY == null)
            return true; // TODO: shoulnd't it be 'return false'? cannot  move to undefined coordinates!

        if (self.layer && !self.layer.canTeleportTo(self, newX, newY))
            return false;

        if (!self.layer)
            console.error("no layer!");

        self.container.x = newX;
        self.container.y = newY;

        return true;
    }

    function hide(self) {
        self.container.visible = false;
    }

    function show(self) {
        self.container.visible = true;
    }

})();
