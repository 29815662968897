Sk.Angis = Sk.Angis || {};

(function() {
    
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.Angis.taikykFiltrą = Utils.createMethodKWA(filter);
    Sk.Angis.pritaikykFiltrą = Sk.Angis.taikykFiltrą;
    Sk.Angis.filter = Sk.Angis.taikykFiltrą;

    Sk.Angis.ištrinkFiltrą = Utils.createMethod(resetFilter);
    Sk.Angis.resetFilter = Sk.Angis.ištrinkFiltrą;

    const filters = Pixi.filters;

    function filter(kwa) {
        var params = [
            { contrast: null },
            { brightness: null },
            { alpha: null },
            { blur: null },
            { noise: null },
            { sepia: null },
            { vignette: null },
            { vintage: null },
            { hue: null },
            { saturation: null },
            { night: null },
            { greyscale: null },
        ];

        const { contrast, brightness, alpha, blur, noise, sepia, vignette, vintage, hue, saturation, night, greyscale } = 
            Utils.mapArgsStatic(arguments, kwa, params);

        if (Sk.tracing) {
            Sk.tracer.emit('filtrai:taikykFiltrą', { target: null, contrast, brightness, alpha, blur, noise, sepia, vignette, vintage, hue, saturation, night, greyscale}, SKTRACEID);
        }

        console.log("filtering");
        
        if (alpha != null)
            __applyAlpha(alpha);

        if (blur != null)
            __applyBlur(blur);

        if (noise != null)
            __applyNoise(noise);

        // if (vignette != null)
        //     __applyVignette(vignette);

        __applyColorMatrix(contrast, brightness, greyscale, hue, saturation, night, sepia, vintage)
    }

    function resetFilter(){
        filters.splice(0, filters.length);

        var container = Pixi.getCurrentlyVisibleContainer();
        if (container)
            container.filters = filters;
    }

    function __applyBlur(blur){
        const blurFilter = new PIXI.filters.BlurFilter();
        filters.push(blurFilter);

        blurFilter.blur = blur;

        Pixi.getCurrentlyVisibleContainer().filters = filters;
    }

    function __applyColorMatrix(contrast, brightness, greyscale, hue, saturate, night, sepia, vintage){
       
        if (contrast != null){
            const contrastMatrix = new PIXI.filters.ColorMatrixFilter();
            contrastMatrix.contrast(contrast);
            filters.push(contrastMatrix);
        }

        if (brightness != null){
            const brMatrix = new PIXI.filters.ColorMatrixFilter();
            brMatrix.brightness(brightness);
            filters.push(brMatrix);
        }

        if (greyscale != null && greyscale != 0){
            const grMatrix = new PIXI.filters.ColorMatrixFilter();
            grMatrix.greyscale(0.5);
            filters.push(grMatrix);
        }

        if (hue != null){
            const hueMatrix = new PIXI.filters.ColorMatrixFilter();
            hueMatrix.hue(hue);
            filters.push(hueMatrix);
        }

        const colorMatrix = new PIXI.filters.ColorMatrixFilter();
        filters.push(colorMatrix);

        if (saturate != null)
            colorMatrix.saturate(saturate);

        if (night != null)
            colorMatrix.night(night);

        if (sepia != null && sepia)
            colorMatrix.sepia();

        if (vintage != null && vintage)
            colorMatrix.vintage(true);

        Pixi.getCurrentlyVisibleContainer().filters = filters;
    }

    function __applyAlpha(alpha){
        const alphaFilter = new PIXI.filters.AlphaFilter();
        filters.push(alphaFilter);

        alphaFilter.alpha = alpha;

        Pixi.getCurrentlyVisibleContainer().filters = filters;
    }

    function __applyNoise(noise){
        const noiseFilter = new PIXI.filters.NoiseFilter();
        filters.push(noiseFilter);

        noiseFilter.noise = noise;

        Pixi.getCurrentlyVisibleContainer().filters = filters;
    }

    /*
    function __applyVignette(vignette){
        var vignetteFilter = new VignetteFilter({
            size: 0.5,
            amount: vignette,
            focalPointX: 0.5,
            focalPointY: 0.5
        });
        
        filters.push(vignetteFilter);

        Pixi.getCurrentlyVisibleContainer().filters = filters;
    }
    */

})();
