/* File automatically generated by asdl_js.py. */

/* Object that holds all nodes */
Sk.astnodes = {};

/* ----- expr_context ----- */
/** @constructor */
Sk.astnodes.Load = function Load() {}
/** @constructor */
Sk.astnodes.Store = function Store() {}
/** @constructor */
Sk.astnodes.Del = function Del() {}
/** @constructor */
Sk.astnodes.AugLoad = function AugLoad() {}
/** @constructor */
Sk.astnodes.AugStore = function AugStore() {}
/** @constructor */
Sk.astnodes.Param = function Param() {}

/* ----- boolop ----- */
/** @constructor */
Sk.astnodes.And = function And() {}
/** @constructor */
Sk.astnodes.Or = function Or() {}

/* ----- operator ----- */
/** @constructor */
Sk.astnodes.Add = function Add() {}
/** @constructor */
Sk.astnodes.Sub = function Sub() {}
/** @constructor */
Sk.astnodes.Mult = function Mult() {}
/** @constructor */
Sk.astnodes.MatMult = function MatMult() {}
/** @constructor */
Sk.astnodes.Div = function Div() {}
/** @constructor */
Sk.astnodes.Mod = function Mod() {}
/** @constructor */
Sk.astnodes.Pow = function Pow() {}
/** @constructor */
Sk.astnodes.LShift = function LShift() {}
/** @constructor */
Sk.astnodes.RShift = function RShift() {}
/** @constructor */
Sk.astnodes.BitOr = function BitOr() {}
/** @constructor */
Sk.astnodes.BitXor = function BitXor() {}
/** @constructor */
Sk.astnodes.BitAnd = function BitAnd() {}
/** @constructor */
Sk.astnodes.FloorDiv = function FloorDiv() {}

/* ----- unaryop ----- */
/** @constructor */
Sk.astnodes.Invert = function Invert() {}
/** @constructor */
Sk.astnodes.Not = function Not() {}
/** @constructor */
Sk.astnodes.UAdd = function UAdd() {}
/** @constructor */
Sk.astnodes.USub = function USub() {}

/* ----- cmpop ----- */
/** @constructor */
Sk.astnodes.Eq = function Eq() {}
/** @constructor */
Sk.astnodes.NotEq = function NotEq() {}
/** @constructor */
Sk.astnodes.Lt = function Lt() {}
/** @constructor */
Sk.astnodes.LtE = function LtE() {}
/** @constructor */
Sk.astnodes.Gt = function Gt() {}
/** @constructor */
Sk.astnodes.GtE = function GtE() {}
/** @constructor */
Sk.astnodes.Is = function Is() {}
/** @constructor */
Sk.astnodes.IsNot = function IsNot() {}
/** @constructor */
Sk.astnodes.In = function In() {}
/** @constructor */
Sk.astnodes.NotIn = function NotIn() {}







/* ---------------------- */
/* constructors for nodes */
/* ---------------------- */





/** @constructor */
Sk.astnodes.Module = function Module(/* {asdl_seq *} */ body, /* {string} */
                                          docstring)
{
    this.body = body;
    this.docstring = docstring;
    return this;
}

/** @constructor */
Sk.astnodes.Interactive = function Interactive(/* {asdl_seq *} */ body)
{
    this.body = body;
    return this;
}

/** @constructor */
Sk.astnodes.Expression = function Expression(/* {expr_ty} */ body)
{
    this.body = body;
    return this;
}

/** @constructor */
Sk.astnodes.Suite = function Suite(/* {asdl_seq *} */ body)
{
    this.body = body;
    return this;
}

/** @constructor */
Sk.astnodes.FunctionDef = function FunctionDef(/* {identifier} */ name, /*
                                                    {arguments__ty} */ args, /*
                                                    {asdl_seq *} */ body, /*
                                                    {asdl_seq *} */
                                                    decorator_list, /*
                                                    {expr_ty} */ returns, /*
                                                    {string} */ docstring, /*
                                                    {int} */ lineno, /* {int}
                                                    */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.name = name;
    this.args = args;
    this.body = body;
    this.decorator_list = decorator_list;
    this.returns = returns;
    this.docstring = docstring;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.AsyncFunctionDef = function AsyncFunctionDef(/* {identifier} */
                                                              name, /*
                                                              {arguments__ty}
                                                              */ args, /*
                                                              {asdl_seq *} */
                                                              body, /*
                                                              {asdl_seq *} */
                                                              decorator_list,
                                                              /* {expr_ty} */
                                                              returns, /*
                                                              {string} */
                                                              docstring, /*
                                                              {int} */ lineno,
                                                              /* {int} */
                                                              col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.name = name;
    this.args = args;
    this.body = body;
    this.decorator_list = decorator_list;
    this.returns = returns;
    this.docstring = docstring;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.ClassDef = function ClassDef(/* {identifier} */ name, /* {asdl_seq
                                              *} */ bases, /* {asdl_seq *} */
                                              keywords, /* {asdl_seq *} */
                                              body, /* {asdl_seq *} */
                                              decorator_list, /* {string} */
                                              docstring, /* {int} */ lineno, /*
                                              {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.name = name;
    this.bases = bases;
    this.keywords = keywords;
    this.body = body;
    this.decorator_list = decorator_list;
    this.docstring = docstring;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Return = function Return(/* {expr_ty} */ value, /* {int} */ lineno,
                                          /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Delete = function Delete(/* {asdl_seq *} */ targets, /* {int} */
                                          lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.targets = targets;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Assign = function Assign(/* {asdl_seq *} */ targets, /* {expr_ty}
                                          */ value, /* {int} */ lineno, /*
                                          {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.targets = targets;
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.AugAssign = function AugAssign(/* {expr_ty} */ target, /*
                                                {operator_ty} */ op, /*
                                                {expr_ty} */ value, /* {int} */
                                                lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.target = target;
    this.op = op;
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.AnnAssign = function AnnAssign(/* {expr_ty} */ target, /* {expr_ty}
                                                */ annotation, /* {expr_ty} */
                                                value, /* {int} */ simple, /*
                                                {int} */ lineno, /* {int} */
                                                col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.target = target;
    this.annotation = annotation;
    this.value = value;
    this.simple = simple;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.For = function For(/* {expr_ty} */ target, /* {expr_ty} */ iter, /*
                                    {asdl_seq *} */ body, /* {asdl_seq *} */
                                    orelse, /* {int} */ lineno, /* {int} */
                                    col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.target = target;
    this.iter = iter;
    this.body = body;
    this.orelse = orelse;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Kartok = function Kartok(/* {expr_ty} */ count, /*
    {asdl_seq *} */ body, /* {int} */ lineno, /* {int} */
    col_offset)
{
Sk.asserts.assert(lineno !== null && lineno !== undefined);
Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
this.count = count;
this.body = body;
this.lineno = lineno;
this.col_offset = col_offset;
return this;
}

/** @constructor */
Sk.astnodes.AsyncFor = function AsyncFor(/* {expr_ty} */ target, /* {expr_ty}
                                              */ iter, /* {asdl_seq *} */ body,
                                              /* {asdl_seq *} */ orelse, /*
                                              {int} */ lineno, /* {int} */
                                              col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.target = target;
    this.iter = iter;
    this.body = body;
    this.orelse = orelse;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.While = function While(/* {expr_ty} */ test, /* {asdl_seq *} */
                                        body, /* {asdl_seq *} */ orelse, /*
                                        {int} */ lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.test = test;
    this.body = body;
    this.orelse = orelse;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.If = function If(/* {expr_ty} */ test, /* {asdl_seq *} */ body, /*
                                  {asdl_seq *} */ orelse, /* {int} */ lineno,
                                  /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.test = test;
    this.body = body;
    this.orelse = orelse;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.With = function With(/* {asdl_seq *} */ items, /* {asdl_seq *} */
                                      body, /* {int} */ lineno, /* {int} */
                                      col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.items = items;
    this.body = body;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.AsyncWith = function AsyncWith(/* {asdl_seq *} */ items, /*
                                                {asdl_seq *} */ body, /* {int}
                                                */ lineno, /* {int} */
                                                col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.items = items;
    this.body = body;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Raise = function Raise(/* {expr_ty} */ exc, /* {expr_ty} */ cause,
                                        /* {expr_ty} */ inst, /* {expr_ty} */
                                        tback, /* {int} */ lineno, /* {int} */
                                        col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.exc = exc;
    this.cause = cause;
    this.inst = inst;
    this.tback = tback;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Try = function Try(/* {asdl_seq *} */ body, /* {asdl_seq *} */
                                    handlers, /* {asdl_seq *} */ orelse, /*
                                    {asdl_seq *} */ finalbody, /* {int} */
                                    lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.body = body;
    this.handlers = handlers;
    this.orelse = orelse;
    this.finalbody = finalbody;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Assert = function Assert(/* {expr_ty} */ test, /* {expr_ty} */ msg,
                                          /* {int} */ lineno, /* {int} */
                                          col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.test = test;
    this.msg = msg;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Import = function Import(/* {asdl_seq *} */ names, /* {int} */
                                          lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.names = names;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.ImportFrom = function ImportFrom(/* {identifier} */ module, /*
                                                  {asdl_seq *} */ names, /*
                                                  {int} */ level, /* {int} */
                                                  lineno, /* {int} */
                                                  col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.module = module;
    this.names = names;
    this.level = level;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Global = function Global(/* {asdl_seq *} */ names, /* {int} */
                                          lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.names = names;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Nonlocal = function Nonlocal(/* {asdl_seq *} */ names, /* {int} */
                                              lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.names = names;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Expr = function Expr(/* {expr_ty} */ value, /* {int} */ lineno, /*
                                      {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Pass = function Pass(/* {int} */ lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Break = function Break(/* {int} */ lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Continue = function Continue(/* {int} */ lineno, /* {int} */
                                              col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Print = function Print(/* {expr_ty} */ dest, /* {asdl_seq *} */
                                        values, /* {int} */ nl, /* {int} */
                                        lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.dest = dest;
    this.values = values;
    this.nl = nl;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Debugger = function Debugger(/* {int} */ lineno, /* {int} */
                                              col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.BoolOp = function BoolOp(/* {boolop_ty} */ op, /* {asdl_seq *} */
                                          values, /* {int} */ lineno, /* {int}
                                          */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.op = op;
    this.values = values;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.BinOp = function BinOp(/* {expr_ty} */ left, /* {operator_ty} */
                                        op, /* {expr_ty} */ right, /* {int} */
                                        lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.left = left;
    this.op = op;
    this.right = right;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.UnaryOp = function UnaryOp(/* {unaryop_ty} */ op, /* {expr_ty} */
                                            operand, /* {int} */ lineno, /*
                                            {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.op = op;
    this.operand = operand;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Lambda = function Lambda(/* {arguments__ty} */ args, /* {expr_ty}
                                          */ body, /* {int} */ lineno, /* {int}
                                          */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.args = args;
    this.body = body;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.IfExp = function IfExp(/* {expr_ty} */ test, /* {expr_ty} */ body,
                                        /* {expr_ty} */ orelse, /* {int} */
                                        lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.test = test;
    this.body = body;
    this.orelse = orelse;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Dict = function Dict(/* {asdl_seq *} */ keys, /* {asdl_seq *} */
                                      values, /* {int} */ lineno, /* {int} */
                                      col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.keys = keys;
    this.values = values;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Set = function Set(/* {asdl_seq *} */ elts, /* {int} */ lineno, /*
                                    {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.elts = elts;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.ListComp = function ListComp(/* {expr_ty} */ elt, /* {asdl_seq *}
                                              */ generators, /* {int} */
                                              lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.elt = elt;
    this.generators = generators;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.SetComp = function SetComp(/* {expr_ty} */ elt, /* {asdl_seq *} */
                                            generators, /* {int} */ lineno, /*
                                            {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.elt = elt;
    this.generators = generators;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.DictComp = function DictComp(/* {expr_ty} */ key, /* {expr_ty} */
                                              value, /* {asdl_seq *} */
                                              generators, /* {int} */ lineno,
                                              /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.key = key;
    this.value = value;
    this.generators = generators;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.GeneratorExp = function GeneratorExp(/* {expr_ty} */ elt, /*
                                                      {asdl_seq *} */
                                                      generators, /* {int} */
                                                      lineno, /* {int} */
                                                      col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.elt = elt;
    this.generators = generators;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Await = function Await(/* {expr_ty} */ value, /* {int} */ lineno,
                                        /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Yield = function Yield(/* {expr_ty} */ value, /* {int} */ lineno,
                                        /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.YieldFrom = function YieldFrom(/* {expr_ty} */ value, /* {int} */
                                                lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Compare = function Compare(/* {expr_ty} */ left, /* {asdl_int_seq
                                            *} */ ops, /* {asdl_seq *} */
                                            comparators, /* {int} */ lineno, /*
                                            {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.left = left;
    this.ops = ops;
    this.comparators = comparators;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Call = function Call(/* {expr_ty} */ func, /* {asdl_seq *} */ args,
                                      /* {asdl_seq *} */ keywords, /* {int} */
                                      lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.func = func;
    this.args = args;
    this.keywords = keywords;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Num = function Num(/* {object} */ n, /* {int} */ lineno, /* {int}
                                    */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.n = n;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Str = function Str(/* {string} */ s, /* {int} */ lineno, /* {int}
                                    */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.s = s;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.FormattedValue = function FormattedValue(/* {expr_ty} */ value, /*
                                                          {int} */ conversion,
                                                          /* {expr_ty} */
                                                          format_spec, /* {int}
                                                          */ lineno, /* {int}
                                                          */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.conversion = conversion;
    this.format_spec = format_spec;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.JoinedStr = function JoinedStr(/* {asdl_seq *} */ values, /* {int}
                                                */ lineno, /* {int} */
                                                col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.values = values;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Bytes = function Bytes(/* {bytes} */ s, /* {int} */ lineno, /*
                                        {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.s = s;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.NameConstant = function NameConstant(/* {singleton} */ value, /*
                                                      {int} */ lineno, /* {int}
                                                      */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Ellipsis = function Ellipsis(/* {int} */ lineno, /* {int} */
                                              col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Constant = function Constant(/* {constant} */ value, /* {int} */
                                              lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Attribute = function Attribute(/* {expr_ty} */ value, /*
                                                {identifier} */ attr, /*
                                                {expr_context_ty} */ ctx, /*
                                                {int} */ lineno, /* {int} */
                                                col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.attr = attr;
    this.ctx = ctx;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Subscript = function Subscript(/* {expr_ty} */ value, /* {slice_ty}
                                                */ slice, /* {expr_context_ty}
                                                */ ctx, /* {int} */ lineno, /*
                                                {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.slice = slice;
    this.ctx = ctx;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Starred = function Starred(/* {expr_ty} */ value, /*
                                            {expr_context_ty} */ ctx, /* {int}
                                            */ lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.value = value;
    this.ctx = ctx;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Name = function Name(/* {identifier} */ id, /* {expr_context_ty} */
                                      ctx, /* {int} */ lineno, /* {int} */
                                      col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.id = id;
    this.ctx = ctx;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.List = function List(/* {asdl_seq *} */ elts, /* {expr_context_ty}
                                      */ ctx, /* {int} */ lineno, /* {int} */
                                      col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.elts = elts;
    this.ctx = ctx;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Tuple = function Tuple(/* {asdl_seq *} */ elts, /*
                                        {expr_context_ty} */ ctx, /* {int} */
                                        lineno, /* {int} */ col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.elts = elts;
    this.ctx = ctx;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.Slice = function Slice(/* {expr_ty} */ lower, /* {expr_ty} */
                                        upper, /* {expr_ty} */ step)
{
    this.lower = lower;
    this.upper = upper;
    this.step = step;
    return this;
}

/** @constructor */
Sk.astnodes.ExtSlice = function ExtSlice(/* {asdl_seq *} */ dims)
{
    this.dims = dims;
    return this;
}

/** @constructor */
Sk.astnodes.Index = function Index(/* {expr_ty} */ value)
{
    this.value = value;
    return this;
}

/** @constructor */
Sk.astnodes.comprehension = function comprehension(/* {expr_ty} */ target, /*
                                                        {expr_ty} */ iter, /*
                                                        {asdl_seq *} */ ifs, /*
                                                        {int} */ is_async)
{
    this.target = target;
    this.iter = iter;
    this.ifs = ifs;
    this.is_async = is_async;
    return this;
}

/** @constructor */
Sk.astnodes.ExceptHandler = function ExceptHandler(/* {expr_ty} */ type, /*
                                                        {identifier} */ name,
                                                        /* {asdl_seq *} */
                                                        body, /* {int} */
                                                        lineno, /* {int} */
                                                        col_offset)
{
    Sk.asserts.assert(lineno !== null && lineno !== undefined);
    Sk.asserts.assert(col_offset !== null && col_offset !== undefined);
    this.type = type;
    this.name = name;
    this.body = body;
    this.lineno = lineno;
    this.col_offset = col_offset;
    return this;
}

/** @constructor */
Sk.astnodes.arguments_ = function arguments_(/* {asdl_seq *} */ args, /*
                                                  {arg_ty} */ vararg, /*
                                                  {asdl_seq *} */ kwonlyargs,
                                                  /* {asdl_seq *} */
                                                  kw_defaults, /* {arg_ty} */
                                                  kwarg, /* {asdl_seq *} */
                                                  defaults)
{
    this.args = args;
    this.vararg = vararg;
    this.kwonlyargs = kwonlyargs;
    this.kw_defaults = kw_defaults;
    this.kwarg = kwarg;
    this.defaults = defaults;
    return this;
}

/** @constructor */
Sk.astnodes.arg = function arg(/* {identifier} */ arg, /* {expr_ty} */
                                    annotation)
{
    this.arg = arg;
    this.annotation = annotation;
    return this;
}

/** @constructor */
Sk.astnodes.keyword = function keyword(/* {identifier} */ arg, /* {expr_ty} */
                                            value)
{
    this.arg = arg;
    this.value = value;
    return this;
}

/** @constructor */
Sk.astnodes.alias = function alias(/* {identifier} */ name, /* {identifier} */
                                        asname)
{
    this.name = name;
    this.asname = asname;
    return this;
}

/** @constructor */
Sk.astnodes.withitem = function withitem(/* {expr_ty} */ context_expr, /*
                                              {expr_ty} */ optional_vars)
{
    this.context_expr = context_expr;
    this.optional_vars = optional_vars;
    return this;
}


Sk.astnodes.Module.prototype._astname = "Module";
Sk.astnodes.Module.prototype._fields = [
    "body", function(n) { return n.body; },
    "docstring", function(n) { return n.docstring; }
];
Sk.astnodes.Interactive.prototype._astname = "Interactive";
Sk.astnodes.Interactive.prototype._fields = [
    "body", function(n) { return n.body; }
];
Sk.astnodes.Expression.prototype._astname = "Expression";
Sk.astnodes.Expression.prototype._fields = [
    "body", function(n) { return n.body; }
];
Sk.astnodes.Suite.prototype._astname = "Suite";
Sk.astnodes.Suite.prototype._fields = [
    "body", function(n) { return n.body; }
];
Sk.astnodes.FunctionDef.prototype._astname = "FunctionDef";
Sk.astnodes.FunctionDef.prototype._fields = [
    "name", function(n) { return n.name; },
    "args", function(n) { return n.args; },
    "body", function(n) { return n.body; },
    "decorator_list", function(n) { return n.decorator_list; },
    "returns", function(n) { return n.returns; },
    "docstring", function(n) { return n.docstring; }
];
Sk.astnodes.AsyncFunctionDef.prototype._astname = "AsyncFunctionDef";
Sk.astnodes.AsyncFunctionDef.prototype._fields = [
    "name", function(n) { return n.name; },
    "args", function(n) { return n.args; },
    "body", function(n) { return n.body; },
    "decorator_list", function(n) { return n.decorator_list; },
    "returns", function(n) { return n.returns; },
    "docstring", function(n) { return n.docstring; }
];
Sk.astnodes.ClassDef.prototype._astname = "ClassDef";
Sk.astnodes.ClassDef.prototype._fields = [
    "name", function(n) { return n.name; },
    "bases", function(n) { return n.bases; },
    "keywords", function(n) { return n.keywords; },
    "body", function(n) { return n.body; },
    "decorator_list", function(n) { return n.decorator_list; },
    "docstring", function(n) { return n.docstring; }
];
Sk.astnodes.Return.prototype._astname = "Return";
Sk.astnodes.Return.prototype._fields = [
    "value", function(n) { return n.value; }
];
Sk.astnodes.Delete.prototype._astname = "Delete";
Sk.astnodes.Delete.prototype._fields = [
    "targets", function(n) { return n.targets; }
];
Sk.astnodes.Assign.prototype._astname = "Assign";
Sk.astnodes.Assign.prototype._fields = [
    "targets", function(n) { return n.targets; },
    "value", function(n) { return n.value; }
];
Sk.astnodes.AugAssign.prototype._astname = "AugAssign";
Sk.astnodes.AugAssign.prototype._fields = [
    "target", function(n) { return n.target; },
    "op", function(n) { return n.op; },
    "value", function(n) { return n.value; }
];
Sk.astnodes.AnnAssign.prototype._astname = "AnnAssign";
Sk.astnodes.AnnAssign.prototype._fields = [
    "target", function(n) { return n.target; },
    "annotation", function(n) { return n.annotation; },
    "value", function(n) { return n.value; },
    "simple", function(n) { return n.simple; }
];
Sk.astnodes.For.prototype._astname = "For";
Sk.astnodes.For.prototype._fields = [
    "target", function(n) { return n.target; },
    "iter", function(n) { return n.iter; },
    "body", function(n) { return n.body; },
    "orelse", function(n) { return n.orelse; }
];
Sk.astnodes.Kartok.prototype._astname = "Kartok";
Sk.astnodes.Kartok.prototype._fields = [
    "count", function(n) { return n.count; },
    "body", function(n) { return n.body; }
];
Sk.astnodes.AsyncFor.prototype._astname = "AsyncFor";
Sk.astnodes.AsyncFor.prototype._fields = [
    "target", function(n) { return n.target; },
    "iter", function(n) { return n.iter; },
    "body", function(n) { return n.body; },
    "orelse", function(n) { return n.orelse; }
];
Sk.astnodes.While.prototype._astname = "While";
Sk.astnodes.While.prototype._fields = [
    "test", function(n) { return n.test; },
    "body", function(n) { return n.body; },
    "orelse", function(n) { return n.orelse; }
];
Sk.astnodes.If.prototype._astname = "If";
Sk.astnodes.If.prototype._fields = [
    "test", function(n) { return n.test; },
    "body", function(n) { return n.body; },
    "orelse", function(n) { return n.orelse; }
];
Sk.astnodes.With.prototype._astname = "With";
Sk.astnodes.With.prototype._fields = [
    "items", function(n) { return n.items; },
    "body", function(n) { return n.body; }
];
Sk.astnodes.AsyncWith.prototype._astname = "AsyncWith";
Sk.astnodes.AsyncWith.prototype._fields = [
    "items", function(n) { return n.items; },
    "body", function(n) { return n.body; }
];
Sk.astnodes.Raise.prototype._astname = "Raise";
Sk.astnodes.Raise.prototype._fields = [
    "exc", function(n) { return n.exc; },
    "cause", function(n) { return n.cause; },
    "inst", function(n) { return n.inst; },
    "tback", function(n) { return n.tback; }
];
Sk.astnodes.Try.prototype._astname = "Try";
Sk.astnodes.Try.prototype._fields = [
    "body", function(n) { return n.body; },
    "handlers", function(n) { return n.handlers; },
    "orelse", function(n) { return n.orelse; },
    "finalbody", function(n) { return n.finalbody; }
];
Sk.astnodes.Assert.prototype._astname = "Assert";
Sk.astnodes.Assert.prototype._fields = [
    "test", function(n) { return n.test; },
    "msg", function(n) { return n.msg; }
];
Sk.astnodes.Import.prototype._astname = "Import";
Sk.astnodes.Import.prototype._fields = [
    "names", function(n) { return n.names; }
];
Sk.astnodes.ImportFrom.prototype._astname = "ImportFrom";
Sk.astnodes.ImportFrom.prototype._fields = [
    "module", function(n) { return n.module; },
    "names", function(n) { return n.names; },
    "level", function(n) { return n.level; }
];
Sk.astnodes.Global.prototype._astname = "Global";
Sk.astnodes.Global.prototype._fields = [
    "names", function(n) { return n.names; }
];
Sk.astnodes.Nonlocal.prototype._astname = "Nonlocal";
Sk.astnodes.Nonlocal.prototype._fields = [
    "names", function(n) { return n.names; }
];
Sk.astnodes.Expr.prototype._astname = "Expr";
Sk.astnodes.Expr.prototype._fields = [
    "value", function(n) { return n.value; }
];
Sk.astnodes.Pass.prototype._astname = "Pass";
Sk.astnodes.Pass.prototype._fields = [
];
Sk.astnodes.Break.prototype._astname = "Break";
Sk.astnodes.Break.prototype._fields = [
];
Sk.astnodes.Continue.prototype._astname = "Continue";
Sk.astnodes.Continue.prototype._fields = [
];
Sk.astnodes.Print.prototype._astname = "Print";
Sk.astnodes.Print.prototype._fields = [
    "dest", function(n) { return n.dest; },
    "values", function(n) { return n.values; },
    "nl", function(n) { return n.nl; }
];
Sk.astnodes.Debugger.prototype._astname = "Debugger";
Sk.astnodes.Debugger.prototype._fields = [
];
Sk.astnodes.BoolOp.prototype._astname = "BoolOp";
Sk.astnodes.BoolOp.prototype._fields = [
    "op", function(n) { return n.op; },
    "values", function(n) { return n.values; }
];
Sk.astnodes.BinOp.prototype._astname = "BinOp";
Sk.astnodes.BinOp.prototype._fields = [
    "left", function(n) { return n.left; },
    "op", function(n) { return n.op; },
    "right", function(n) { return n.right; }
];
Sk.astnodes.UnaryOp.prototype._astname = "UnaryOp";
Sk.astnodes.UnaryOp.prototype._fields = [
    "op", function(n) { return n.op; },
    "operand", function(n) { return n.operand; }
];
Sk.astnodes.Lambda.prototype._astname = "Lambda";
Sk.astnodes.Lambda.prototype._fields = [
    "args", function(n) { return n.args; },
    "body", function(n) { return n.body; }
];
Sk.astnodes.IfExp.prototype._astname = "IfExp";
Sk.astnodes.IfExp.prototype._fields = [
    "test", function(n) { return n.test; },
    "body", function(n) { return n.body; },
    "orelse", function(n) { return n.orelse; }
];
Sk.astnodes.Dict.prototype._astname = "Dict";
Sk.astnodes.Dict.prototype._fields = [
    "keys", function(n) { return n.keys; },
    "values", function(n) { return n.values; }
];
Sk.astnodes.Set.prototype._astname = "Set";
Sk.astnodes.Set.prototype._fields = [
    "elts", function(n) { return n.elts; }
];
Sk.astnodes.ListComp.prototype._astname = "ListComp";
Sk.astnodes.ListComp.prototype._fields = [
    "elt", function(n) { return n.elt; },
    "generators", function(n) { return n.generators; }
];
Sk.astnodes.SetComp.prototype._astname = "SetComp";
Sk.astnodes.SetComp.prototype._fields = [
    "elt", function(n) { return n.elt; },
    "generators", function(n) { return n.generators; }
];
Sk.astnodes.DictComp.prototype._astname = "DictComp";
Sk.astnodes.DictComp.prototype._fields = [
    "key", function(n) { return n.key; },
    "value", function(n) { return n.value; },
    "generators", function(n) { return n.generators; }
];
Sk.astnodes.GeneratorExp.prototype._astname = "GeneratorExp";
Sk.astnodes.GeneratorExp.prototype._fields = [
    "elt", function(n) { return n.elt; },
    "generators", function(n) { return n.generators; }
];
Sk.astnodes.Await.prototype._astname = "Await";
Sk.astnodes.Await.prototype._fields = [
    "value", function(n) { return n.value; }
];
Sk.astnodes.Yield.prototype._astname = "Yield";
Sk.astnodes.Yield.prototype._fields = [
    "value", function(n) { return n.value; }
];
Sk.astnodes.YieldFrom.prototype._astname = "YieldFrom";
Sk.astnodes.YieldFrom.prototype._fields = [
    "value", function(n) { return n.value; }
];
Sk.astnodes.Compare.prototype._astname = "Compare";
Sk.astnodes.Compare.prototype._fields = [
    "left", function(n) { return n.left; },
    "ops", function(n) { return n.ops; },
    "comparators", function(n) { return n.comparators; }
];
Sk.astnodes.Call.prototype._astname = "Call";
Sk.astnodes.Call.prototype._fields = [
    "func", function(n) { return n.func; },
    "args", function(n) { return n.args; },
    "keywords", function(n) { return n.keywords; }
];
Sk.astnodes.Num.prototype._astname = "Num";
Sk.astnodes.Num.prototype._fields = [
    "n", function(n) { return n.n; }
];
Sk.astnodes.Str.prototype._astname = "Str";
Sk.astnodes.Str.prototype._fields = [
    "s", function(n) { return n.s; }
];
Sk.astnodes.FormattedValue.prototype._astname = "FormattedValue";
Sk.astnodes.FormattedValue.prototype._fields = [
    "value", function(n) { return n.value; },
    "conversion", function(n) { return n.conversion; },
    "format_spec", function(n) { return n.format_spec; }
];
Sk.astnodes.JoinedStr.prototype._astname = "JoinedStr";
Sk.astnodes.JoinedStr.prototype._fields = [
    "values", function(n) { return n.values; }
];
Sk.astnodes.Bytes.prototype._astname = "Bytes";
Sk.astnodes.Bytes.prototype._fields = [
    "s", function(n) { return n.s; }
];
Sk.astnodes.NameConstant.prototype._astname = "NameConstant";
Sk.astnodes.NameConstant.prototype._fields = [
    "value", function(n) { return n.value; }
];
Sk.astnodes.Ellipsis.prototype._astname = "Ellipsis";
Sk.astnodes.Ellipsis.prototype._fields = [
];
Sk.astnodes.Constant.prototype._astname = "Constant";
Sk.astnodes.Constant.prototype._fields = [
    "value", function(n) { return n.value; }
];
Sk.astnodes.Attribute.prototype._astname = "Attribute";
Sk.astnodes.Attribute.prototype._fields = [
    "value", function(n) { return n.value; },
    "attr", function(n) { return n.attr; },
    "ctx", function(n) { return n.ctx; }
];
Sk.astnodes.Subscript.prototype._astname = "Subscript";
Sk.astnodes.Subscript.prototype._fields = [
    "value", function(n) { return n.value; },
    "slice", function(n) { return n.slice; },
    "ctx", function(n) { return n.ctx; }
];
Sk.astnodes.Starred.prototype._astname = "Starred";
Sk.astnodes.Starred.prototype._fields = [
    "value", function(n) { return n.value; },
    "ctx", function(n) { return n.ctx; }
];
Sk.astnodes.Name.prototype._astname = "Name";
Sk.astnodes.Name.prototype._fields = [
    "id", function(n) { return n.id; },
    "ctx", function(n) { return n.ctx; }
];
Sk.astnodes.List.prototype._astname = "List";
Sk.astnodes.List.prototype._fields = [
    "elts", function(n) { return n.elts; },
    "ctx", function(n) { return n.ctx; }
];
Sk.astnodes.Tuple.prototype._astname = "Tuple";
Sk.astnodes.Tuple.prototype._fields = [
    "elts", function(n) { return n.elts; },
    "ctx", function(n) { return n.ctx; }
];
Sk.astnodes.Load.prototype._astname = "Load";
Sk.astnodes.Load.prototype._isenum = true;
Sk.astnodes.Store.prototype._astname = "Store";
Sk.astnodes.Store.prototype._isenum = true;
Sk.astnodes.Del.prototype._astname = "Del";
Sk.astnodes.Del.prototype._isenum = true;
Sk.astnodes.AugLoad.prototype._astname = "AugLoad";
Sk.astnodes.AugLoad.prototype._isenum = true;
Sk.astnodes.AugStore.prototype._astname = "AugStore";
Sk.astnodes.AugStore.prototype._isenum = true;
Sk.astnodes.Param.prototype._astname = "Param";
Sk.astnodes.Param.prototype._isenum = true;
Sk.astnodes.Slice.prototype._astname = "Slice";
Sk.astnodes.Slice.prototype._fields = [
    "lower", function(n) { return n.lower; },
    "upper", function(n) { return n.upper; },
    "step", function(n) { return n.step; }
];
Sk.astnodes.ExtSlice.prototype._astname = "ExtSlice";
Sk.astnodes.ExtSlice.prototype._fields = [
    "dims", function(n) { return n.dims; }
];
Sk.astnodes.Index.prototype._astname = "Index";
Sk.astnodes.Index.prototype._fields = [
    "value", function(n) { return n.value; }
];
Sk.astnodes.And.prototype._astname = "And";
Sk.astnodes.And.prototype._isenum = true;
Sk.astnodes.Or.prototype._astname = "Or";
Sk.astnodes.Or.prototype._isenum = true;
Sk.astnodes.Add.prototype._astname = "Add";
Sk.astnodes.Add.prototype._isenum = true;
Sk.astnodes.Sub.prototype._astname = "Sub";
Sk.astnodes.Sub.prototype._isenum = true;
Sk.astnodes.Mult.prototype._astname = "Mult";
Sk.astnodes.Mult.prototype._isenum = true;
Sk.astnodes.MatMult.prototype._astname = "MatMult";
Sk.astnodes.MatMult.prototype._isenum = true;
Sk.astnodes.Div.prototype._astname = "Div";
Sk.astnodes.Div.prototype._isenum = true;
Sk.astnodes.Mod.prototype._astname = "Mod";
Sk.astnodes.Mod.prototype._isenum = true;
Sk.astnodes.Pow.prototype._astname = "Pow";
Sk.astnodes.Pow.prototype._isenum = true;
Sk.astnodes.LShift.prototype._astname = "LShift";
Sk.astnodes.LShift.prototype._isenum = true;
Sk.astnodes.RShift.prototype._astname = "RShift";
Sk.astnodes.RShift.prototype._isenum = true;
Sk.astnodes.BitOr.prototype._astname = "BitOr";
Sk.astnodes.BitOr.prototype._isenum = true;
Sk.astnodes.BitXor.prototype._astname = "BitXor";
Sk.astnodes.BitXor.prototype._isenum = true;
Sk.astnodes.BitAnd.prototype._astname = "BitAnd";
Sk.astnodes.BitAnd.prototype._isenum = true;
Sk.astnodes.FloorDiv.prototype._astname = "FloorDiv";
Sk.astnodes.FloorDiv.prototype._isenum = true;
Sk.astnodes.Invert.prototype._astname = "Invert";
Sk.astnodes.Invert.prototype._isenum = true;
Sk.astnodes.Not.prototype._astname = "Not";
Sk.astnodes.Not.prototype._isenum = true;
Sk.astnodes.UAdd.prototype._astname = "UAdd";
Sk.astnodes.UAdd.prototype._isenum = true;
Sk.astnodes.USub.prototype._astname = "USub";
Sk.astnodes.USub.prototype._isenum = true;
Sk.astnodes.Eq.prototype._astname = "Eq";
Sk.astnodes.Eq.prototype._isenum = true;
Sk.astnodes.NotEq.prototype._astname = "NotEq";
Sk.astnodes.NotEq.prototype._isenum = true;
Sk.astnodes.Lt.prototype._astname = "Lt";
Sk.astnodes.Lt.prototype._isenum = true;
Sk.astnodes.LtE.prototype._astname = "LtE";
Sk.astnodes.LtE.prototype._isenum = true;
Sk.astnodes.Gt.prototype._astname = "Gt";
Sk.astnodes.Gt.prototype._isenum = true;
Sk.astnodes.GtE.prototype._astname = "GtE";
Sk.astnodes.GtE.prototype._isenum = true;
Sk.astnodes.Is.prototype._astname = "Is";
Sk.astnodes.Is.prototype._isenum = true;
Sk.astnodes.IsNot.prototype._astname = "IsNot";
Sk.astnodes.IsNot.prototype._isenum = true;
Sk.astnodes.In.prototype._astname = "In";
Sk.astnodes.In.prototype._isenum = true;
Sk.astnodes.NotIn.prototype._astname = "NotIn";
Sk.astnodes.NotIn.prototype._isenum = true;
Sk.astnodes.comprehension.prototype._astname = "comprehension";
Sk.astnodes.comprehension.prototype._fields = [
    "target", function(n) { return n.target; },
    "iter", function(n) { return n.iter; },
    "ifs", function(n) { return n.ifs; },
    "is_async", function(n) { return n.is_async; }
];
Sk.astnodes.ExceptHandler.prototype._astname = "ExceptHandler";
Sk.astnodes.ExceptHandler.prototype._fields = [
    "type", function(n) { return n.type; },
    "name", function(n) { return n.name; },
    "body", function(n) { return n.body; }
];
Sk.astnodes.arguments_.prototype._astname = "arguments";
Sk.astnodes.arguments_.prototype._fields = [
    "args", function(n) { return n.args; },
    "vararg", function(n) { return n.vararg; },
    "kwonlyargs", function(n) { return n.kwonlyargs; },
    "kw_defaults", function(n) { return n.kw_defaults; },
    "kwarg", function(n) { return n.kwarg; },
    "defaults", function(n) { return n.defaults; }
];
Sk.astnodes.arg.prototype._astname = "arg";
Sk.astnodes.arg.prototype._fields = [
    "arg", function(n) { return n.arg; },
    "annotation", function(n) { return n.annotation; }
];
Sk.astnodes.keyword.prototype._astname = "keyword";
Sk.astnodes.keyword.prototype._fields = [
    "arg", function(n) { return n.arg; },
    "value", function(n) { return n.value; }
];
Sk.astnodes.alias.prototype._astname = "alias";
Sk.astnodes.alias.prototype._fields = [
    "name", function(n) { return n.name; },
    "asname", function(n) { return n.asname; }
];
Sk.astnodes.withitem.prototype._astname = "withitem";
Sk.astnodes.withitem.prototype._fields = [
    "context_expr", function(n) { return n.context_expr; },
    "optional_vars", function(n) { return n.optional_vars; }
];

Sk.exportSymbol("Sk.astnodes", Sk.astnodes);
