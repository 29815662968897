Sk.Angis = Sk.Angis || {};

(function () {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.Angis.resetKeyboardEvents = reset;

    Sk.Angis.išNaujo = Utils.createMethod(reset);
    Sk.Angis.paspaudus = Utils.createMethodKWA(paspaudus);
    Sk.Angis.atleidus = Utils.createMethodKWA(atleidus);

    let handlers = {};

    function reset() {
        for (let mygtukas in handlers)
            if (handlers[mygtukas].unsubscribe)
                handlers[mygtukas].unsubscribe();

        handlers = {};

        if (Sk.Angis.timers) {

            for (const timer of Sk.Angis.timers)
                clearInterval(timer);

            Sk.Angis.timers = [];
        }
    }

    function paspaudus(kwa) {
        let { mygtukas, veiksmas } = Utils.mapArgsStatic(arguments, kwa, [{ mygtukas: null }, { veiksmas: null }]);

        if (veiksmas === null && __isFunction(mygtukas)) {
            veiksmas = mygtukas;
            mygtukas = null;
        }

        if (!__isFunction(veiksmas))
            throw new Sk.builtin.ValueError("Norint registruoti klaviatūros paspaudimus, reikia perduoti komandos pavadinimą BE skliaustelių");

        if (__countParams(veiksmas) != 1)
            throw new Sk.builtin.ValueError("Registruok tokią mygtuko paspaudimo komandą, kuri turi vieną ir tik vieną parametrą");

        if (mygtukas === null)
            mygtukas = '<any>';

        if (veiksmas)
            registerKeyDown(mygtukas, veiksmas);
        else
            unregisterKeyDown(mygtukas);
    }

    function atleidus(kwa) {
        let { mygtukas, veiksmas } = Utils.mapArgsStatic(arguments, kwa, [{ mygtukas: null }, { veiksmas: null }]);

        if (veiksmas === null && __isFunction(mygtukas)) {
            veiksmas = mygtukas;
            mygtukas = null;
        }

        if (!__isFunction(veiksmas))
            throw new Sk.builtin.ValueError("Norint registruoti mygtuko atleidimus, reikia perduoti komandos pavadinimą BE skliaustelių");

        if (__countParams(veiksmas) != 1)
            throw new Sk.builtin.ValueError("Registruok tokią mygtuko atleidimo komandą, kuri turi vieną ir tik vieną parametrą");

        if (mygtukas === null)
            mygtukas = '<any>';

        if (veiksmas)
            registerKeyUp(mygtukas, veiksmas);
        else
            unregisterKeyUp(mygtukas);
    }

    function registerKeyDown(mygtukas, komanda) {
        mygtukas = __translateKeypress(mygtukas);
        const handler = handlers[mygtukas] || Pixi.createKeyboardEventHandler(mygtukas);
        handlers[mygtukas] = handler;

        const onPress = (key) => {
            const pyKey = new Sk.builtin.str(key);
            return Sk.misceval.applyAsync(undefined, komanda, undefined, undefined, undefined, [pyKey]);
        };

        handler.press = function (key) {
            onPress(key);
        };

        if (Sk.tracing) {
            Sk.tracer.emit('key:register:down', { key: mygtukas, handler: komanda, invoke: onPress }, SKTRACEID);
        }
    }

    function registerKeyUp(mygtukas, komanda) {
        mygtukas = __translateKeypress(mygtukas);
        const handler = handlers[mygtukas] || Pixi.createKeyboardEventHandler(mygtukas);
        handlers[mygtukas] = handler;

        const onRelease = (key) => {
            const pyKey = new Sk.builtin.str(key);
            Sk.misceval.applyAsync(undefined, komanda, undefined, undefined, undefined, [pyKey]);
        };

        handler.release = function (key) {
            onRelease(key);
        };

        if (Sk.tracing) {
            Sk.tracer.emit('key:register:up', { key: mygtukas, handler: komanda, invoke: onRelease }, SKTRACEID);
        }
    }

    function unregisterKeyDown(mygtukas) {
        mygtukas = __translateKeypress(mygtukas);

        if (!handlers[mygtukas])
            return;

        handlers[mygtukas].press = function () { }

        if (Sk.tracing) {
            Sk.tracer.emit('key:unregister:down', { key: mygtukas }, SKTRACEID);
        }
    }

    function unregisterKeyUp(mygtukas) {
        mygtukas = __translateKeypress(mygtukas);

        if (!handlers[mygtukas])
            return;

        handlers[mygtukas].release = function () { }

        if (Sk.tracing) {
            Sk.tracer.emit('key:unregister:up', { key: mygtukas }, SKTRACEID);
        }
    }

    function __translateKeypress(mygtukas) {
        if (mygtukas === null)
            return '<any>';

        const lwr = (mygtukas || '<any>').toLowerCase();

        if (lwr.length < 3)
            return lwr;

        switch (lwr) {
            case 'kairėn':
            case 'kairen':
            case 'rodyklėkairėn':
            case 'rodyklekairen':
            case '<kairėn>':
            case '<rodyklėkairėn>':
                return 'ArrowLeft';

            case 'dešinėn':
            case 'desinen':
            case 'rodyklėdešinėn':
            case 'rodykledesinen':
            case '<dešinėn>':
            case '<rodyklėdešinėn>':
                return 'ArrowRight';

            case 'aukštyn':
            case 'aukstyn':
            case 'viršun':
            case 'virsun':
            case 'rodyklėviršun':
            case 'rodyklevirsun':
            case '<viršun>':
            case '<rodyklėviršun>':
                return 'ArrowUp';

            case 'žemyn':
            case 'zemyn':
            case 'rodyklėžemyn':
            case 'rodyklezemyn':
            case '<žemyn>':
            case '<rodyklėžemyn>':
                return 'ArrowDown';

            case 'tarpas':
            case 'space':
            case '<tarpas>':
            case '<space>':
                return ' ';

            case '<betkoks>':
            case '<betkuris>':
                return '<any>';
        }

        return mygtukas;
    }

    function __isFunction(item) {
        if (!item)
            return false;

        return !!item.tp$call;
    }

    function __countParams(item){
        return item.co_argcount;
    }

})();
