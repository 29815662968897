Sk.Angis = Sk.Angis || {};

(function() {
    const Utils = Sk.Angis.Utils;

    Sk.Angis.sleep = Utils.createMethodKWA(function(kwa){
        const { msek } = Utils.mapArgsStatic(arguments, kwa, [{ msek: 1000 }]);

        return Utils.createSuspension( 
                new Promise(function (resolve, reject) {
                        Utils.setTimeout(function(){ resolve("done sleeping"); }, msek);
                    })
                );
    });

    Sk.Angis.Taimeris = (mod) => Sk.misceval.buildClass(mod, function($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function(kwa) {
            const {self, veiksmas, dažnis} = 
                Utils.mapArgs(arguments, kwa, [{veiksmas: null}, {dažnis: 1}]);

            self.frequency = dažnis;
            self.callback = veiksmas;

            // Workaround for remapToJs
            self.v = self;

            if (!self.frequency)
                self.timeoutHandler = Utils.setTimeout(pyCallback(self.callback), 0);
            else
                self.intervalHandler = Utils.setInterval(pyCallback(self.callback), self.frequency * 1000);
        });

        $loc.stok = Utils.createMethod(stop);
        $loc.stop = stop;

        function stop(self) {
            if (self.intervalHandler)
                Utils.clearInterval(self.intervalHandler);
            self.intervalHandler = 0;

            if (self.timeoutHandler)
                Utils.clearTimeout(self.timeoutHandler);
            self.timeoutHandler = 0;
        }

        function pyCallback(pyFunc){
            return function(){
                Sk.misceval.applyAsync(undefined, pyFunc, undefined, undefined, undefined, []);
            };
        }

    }, "Taimeris", []);

})();
