Sk.Angis = Sk.Angis || {};

(function() {
    const Utils = Sk.Angis.Utils;

    Sk.Angis.funkcijosParametrai = Utils.createMethod(getFunctionParams);

    function getFunctionParams(func) {
        return Utils.tryRemapToPy(func.co_varnames);
    }

})();
