var tokens = Sk.token.tokens

const TokenError = Sk.builtin.SyntaxError;
const IndentationError = Sk.builtin.SyntaxError;

/**
 * @constructor
 * @param {number} type
 * @param {string} string
 * @param {Array<number>} start
 * @param {Array<number>} end
 * @param {string} line
 */
function TokenInfo(type, string, start, end, line) {
    this.type = type;
    this.string = string;
    this.start = start;
    this.end = end;
    this.line = line;
}

TokenInfo.prototype.exact_type = function() {
    if (this.type == tokens.T_OP && this.string in Sk.token.EXACT_TOKEN_TYPES)
        return Sk.token.EXACT_TOKEN_TYPES[this.string];

    return this.type;
}

var TABSIZE = 8

var TOKEN_TYPES = {
    "eof":      tokens.T_ENDMARKER,
    "name":     tokens.T_NAME,
    "num":      tokens.T_NUMBER,
    "string":   tokens.T_STRING,
    "newline":  tokens.T_NEWLINE,
    "indent":   tokens.T_INDENT,
    "dedent":   tokens.T_DEDENT,
    // regexp ???
    // question ?    
    "(": Sk.token.tokens.T_LPAR,
    ")": Sk.token.tokens.T_RPAR,
    "[": Sk.token.tokens.T_LSQB,
    "]": Sk.token.tokens.T_RSQB,
    ":": Sk.token.tokens.T_COLON,
    ",": Sk.token.tokens.T_COMMA,
    ";": Sk.token.tokens.T_SEMI,
    "+": Sk.token.tokens.T_PLUS,
    "-": Sk.token.tokens.T_MINUS,
    "*": Sk.token.tokens.T_STAR,
    "/": Sk.token.tokens.T_SLASH,
    "|": Sk.token.tokens.T_VBAR,
    "&": Sk.token.tokens.T_AMPER,
    "<": Sk.token.tokens.T_LESS,
    ">": Sk.token.tokens.T_GREATER,
    "=": Sk.token.tokens.T_EQUAL,
    ".": Sk.token.tokens.T_DOT,
    "%": Sk.token.tokens.T_PERCENT,
    "`": Sk.token.tokens.T_BACKQUOTE,
    "{": Sk.token.tokens.T_LBRACE,
    "}": Sk.token.tokens.T_RBRACE,
    "@": Sk.token.tokens.T_AT,
    "@=": Sk.token.tokens.T_ATEQUAL,
    "==": Sk.token.tokens.T_EQEQUAL,
    "!=": Sk.token.tokens.T_NOTEQUAL,
    "<>": Sk.token.tokens.T_NOTEQUAL,
    "<=": Sk.token.tokens.T_LESSEQUAL,
    ">=": Sk.token.tokens.T_GREATEREQUAL,
    "~": Sk.token.tokens.T_TILDE,
    "^": Sk.token.tokens.T_CIRCUMFLEX,
    "<<": Sk.token.tokens.T_LEFTSHIFT,
    ">>": Sk.token.tokens.T_RIGHTSHIFT,
    "**": Sk.token.tokens.T_DOUBLESTAR,
    "+=": Sk.token.tokens.T_PLUSEQUAL,
    "-=": Sk.token.tokens.T_MINEQUAL,
    "*=": Sk.token.tokens.T_STAREQUAL,
    "/=": Sk.token.tokens.T_SLASHEQUAL,
    "%=": Sk.token.tokens.T_PERCENTEQUAL,
    "&=": Sk.token.tokens.T_AMPEREQUAL,
    "|=": Sk.token.tokens.T_VBAREQUAL,
    "^=": Sk.token.tokens.T_CIRCUMFLEXEQUAL,
    "<<=": Sk.token.tokens.T_LEFTSHIFTEQUAL,
    ">>=": Sk.token.tokens.T_RIGHTSHIFTEQUAL,
    "**=": Sk.token.tokens.T_DOUBLESTAREQUAL,
    "//": Sk.token.tokens.T_DOUBLESLASH,
    "//=": Sk.token.tokens.T_DOUBLESLASHEQUAL,
    "->": Sk.token.tokens.T_RARROW,
    "...": Sk.token.tokens.T_ELLIPSIS

};

function _tokenize(filename, readfunc, encoding, yield_) {

    var tokenizer = new Sk.Tokenizer(readfunc);
    var token ;

    yieldEncoding(encoding, yield_);  // Skulpt expects first token to be encoding.

    while( (token = tokenizer.getNextToken()) ){
        if (!token)
            return;

        var type = convertFilbertTokenType(token);
        if (type === tokens.T_ENDMARKER){
           yieldEndfileDedent(tokenizer.getIndent(), token, yield_);

           return yieldToken(token, tokens.T_ENDMARKER, yield_);
        }

        if (type == tokens.T_ERRORTOKEN)
            return yieldToken(token, tokens.T_ERRORTOKEN, yield_);

        yieldToken(token, type, yield_);
    }
}               

function yieldEncoding(encoding, yield_){
    if (encoding === undefined) 
        return;

    if (encoding == "utf-8-sig") // BOM will already have been stripped.
        encoding = "utf-8";

    yield_(new TokenInfo(tokens.T_ENCODING, encoding, [0, 0], [0, 0], ''));
}

function yieldToken(token, type, yield_){
    return yield_(new TokenInfo(type, token.value, [token.startLoc.line, token.startLoc.column], [token.endLoc.line, token.endLoc.column], token.line));
}

function yieldError(token, yield_){
    return yield_(new TokenInfo(tokens.T_ERRORTOKEN, token.value, [token.startLoc.line, token.startLoc.column], [token.endLoc.line, token.endLoc.column], token.line));
}

function yieldEndfileDedent(num, token, yield_){
    //yield_(new TokenInfo(tokens.T_DEDENT, "", [token.startLoc.line, token.startLoc.column], [token.endLoc.line, token.endLoc.column], token.line));
    for (var i=0; i<num; i++)
        yield_(new TokenInfo(tokens.T_DEDENT, "", [token.startLoc.line, token.startLoc.column], [token.endLoc.line, token.endLoc.column], token.line));
}

function convertFilbertTokenType(token){
    if (!token)
        return null;

    if (!token.type || !token.type.type)
        return TOKEN_TYPES.hasOwnProperty(token.value)? TOKEN_TYPES[token.value]: tokens.T_OP;

    return TOKEN_TYPES.hasOwnProperty(token.type.type)? TOKEN_TYPES[token.type.type] : tokens.T_OP;
}

Sk._tokenize = _tokenize;

Sk.exportSymbol("Sk._tokenize", Sk._tokenize);
