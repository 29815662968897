Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function () {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    const NUMERIC_REGEX = {
        allowedValue: /^[-]?\d+(\.\d+)?$/,
        allowedChars: /^[-]?[0-9]*[\.]?[0-9]*$/
    };

    // Might merge Plytelė and Daiktas to one thing.
    Sk.VPŽaidimas.Daiktas = (mod) => Sk.misceval.buildClass(mod, function ($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function (kwa) {
            const { self, failoVardas, x, y, tag } = Utils.mapArgs(arguments, kwa, [{ failoVardas: null }, { x: -1 }, { y: -1 }, { tag: undefined }]);

            return DaiktasConstructor(self, failoVardas, x, y, tag);
        });

        $loc.teleportuokis = Utils.createMethodKWA(teleport);
        $loc.atsirask = $loc.teleportuokis;
        $loc.show = $loc.atsirask;
        $loc.teleport = $loc.atsirask;

        $loc.duokKliūtį = Utils.createMethod(getObstacleArea);
        $loc.nustatykKliūtį = Utils.createMethodKWA(setObstacleArea);

        $loc.liečia = Utils.createMethodKWA(isTouching);
        $loc.arLiečia = $loc.liečia;
       
        $loc.nustatykAlfa = Utils.createMethodKWA(setAlpha);
        $loc.setAlpha = $loc.nustatykAlfa;

        $loc.duokAlfa = Utils.createMethod(getAlpha);
        $loc.getAlpha = $loc.duokAlfa;

        $loc.nustatykMastelį = Utils.createMethodKWA(setScale);
        $loc.setScale = $loc.nustatykMastelį;

        $loc.duokMastelį = Utils.createMethod(getScale);
        $loc.getScale = $loc.duokMastelį;

        $loc.prisikabinkPriePelės = Utils.createMethod(attachToMouse);
        $loc.atsikabinkNuoPelės = Utils.createMethod(detachFromMouse);

        $loc.rodykZonas = Utils.createMethod(showZones);
        $loc.showZones = $loc.rodykZonas;
        $loc.slėpkZonas = Utils.createMethod(hideZones);
        $loc.hideZones = $loc.slėpkZonas;

        $loc.piešim = Utils.createMethod(penDown);
        $loc.pradėkPiešti = $loc.piešim;
        $loc.piešk  = $loc.piešim;
        $loc.penDown = $loc.piešim;
        $loc.pendown = $loc.piešim;

        $loc.nebepiešim = Utils.createMethod(penUp);
        $loc.nustokPiešti = $loc.nebepiešim;
        $loc.nebepiešk = $loc.nebepiešim;
        $loc.nepiešk = $loc.nebepiešim;
        $loc.nepiešim = $loc.nebepiešim;
        $loc.penUp = $loc.nebepiešim;

        $loc.trept = Utils.createMethod(stomp);
        $loc.padėkTaškelį = $loc.trept;
        $loc.pieškTaškelį = $loc.trept;

        $loc.linijosSpalva = Utils.createMethodKWA(setLineColor);
        $loc.setLineColor  = $loc.linijosSpalva;

        $loc.duokLinijosSpalvą = Utils.createMethod(getLineColor);
        $loc.getLineColor = $loc.duokLinijosSpalvą;

        $loc.atstumas = Utils.createMethodKWA(getDistance);
        $loc.paskaičiuokAtstumą = $loc.atstumas;
        $loc.distance = $loc.atstumas;

        // speach API
        $loc.sakyk = Utils.createMethod(showSpeechBubble);
        $loc.say = $loc.sakyk;
        $loc.sakykFone = Utils.createMethod(showSpeechBubbleNonBlocking);
        $loc.sayInBackground = $loc.sakykFone;
        $loc.paklausk = Utils.createMethod(showAskBubble);
        $loc.paklauskTeksto = $loc.paklausk;
        $loc.paklauskSkaičiaus = Utils.createMethod(showAskNumberBubble);

        const eventMethodsMap = {
            pelęUžvedus: "mouseover",
            pelęNuvedus: "mouseout",
            pelęPaspaudus: ["mousedown", "touchstart"], // PIXI.js fires "pointerdown" for both mouse and pointer events [ "mousedown", "pointerdown" ],
            pelęAtleidus: ["mouseup", "mouseupoutside", "touchend", "touchendoutside"], //[ "mouseup", "pointerup" ],
            pelęPaspaudusDešinįKlavišą: "rightdown",
            pelęAtleidusDešinįKlavišą: "rightup",
            pelęJudinant: ["mousemove", "touchmove"]
        };

        for (let method in eventMethodsMap)
            $loc[method] = Utils.createMethodKWA(makeEventCallback(eventMethodsMap[method], method));


            
        function showSpeechBubbleNonBlocking(){
            const [self, ...text] = arguments;
            const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");

            if (Sk.tracing) {
                Sk.tracer.emit('daiktas:showSpeechBubble', { target: self, text: textToShow }, SKTRACEID);
            }

            const closeable = Pixi.createSpeechBubble(self, textToShow);

            return Sk.misceval.callsimOrSuspendArray(Sk.Angis.PromiseCloser(mod), []).init(closeable, 10000);
        }

        function showSpeechBubble() {
            const [self, ...text] = arguments;
            const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");
    
            if (Sk.tracing) {
                Sk.tracer.emit('daiktas:showSpeechBubble', { target: self, text: textToShow }, SKTRACEID);
            }
    
            const closeable = Pixi.createSpeechBubble(self, textToShow);

            Sk.misceval.callsimOrSuspendArray(Sk.Angis.PromiseCloser(mod), []).init(closeable, 10000);

            return Sk.misceval.promiseToSuspension(closeable.promise);
        }

    }, "Daiktas", [mod.Plytelė]);

    Sk.VPŽaidimas.Daiktas.ctor = DaiktasConstructor;
    Sk.VPŽaidimas.Daiktas.attachToMouse = attachToMouse;

    function makeEventCallback(eventNames, methodName) {
        return function onEvent(kwa) {
            const { self, veiksmas } = Utils.mapArgs(arguments, kwa, [{ veiksmas: null }]);

            if (Sk.tracing) {
                Sk.tracer.emit('daiktas:on:' + methodName, { target: self, method: veiksmas, methodCall: __createTracerMethodCall(veiksmas) }, SKTRACEID);
            }

            __off(self.container, eventNames);
            if (veiksmas)
                __on(self.container, eventNames, __createCallbackFn(veiksmas, eventNames));
        };
    }

    function DaiktasConstructor(self, failoVardas, x, y, tag) {

        function __initialize(){

            Sk.VPŽaidimas.Plytelė.ctor(self, failoVardas, x, y, tag);
            //self.sprite.anchor.set(0.5, 1);

            self.container.vx = 0;
            self.container.vy = 0;
            self.container.zIndex = 10;
            self.container.interactive = true;

            self.show = () => show(self);
            self.hide = () => hide(self);
            self.hideZones = () => hideZones(self);
            self.teleportTo = (newX, newY, newZ) => teleportTo(self, newX, newY, newZ);
            self.moveTo = (newX, newY) => moveTo(self, newX, newY);
            self.setScale = (scale) => resize(self, scale);

            self.isDrawing = false;

            self.lineColor = 0xFF00FF;
            self.lineWidth = 2;

            self.rotation = 0;

            self.obstacleArea = {
                x: 0,
                y: 0,
                width: self.container.width,
                height: self.container.height
            };

            self.hitArea = {
                x: 0,
                y: 0,
                width: self.container.width,
                height: self.container.height
            };

            self.sprite.pivot.x = self.hitArea.width / 2;
            self.sprite.pivot.y = self.hitArea.height / 2;
            self.sprite.x = self.sprite.pivot.x;
            self.sprite.y = self.sprite.pivot.y;

            self.layer = null;

            self.isDragging = false;

            self.zonesVisible = false;

            if (Sk.tracing) {
                Sk.tracer.emit('daiktas:new', { target: self }, SKTRACEID);
            }

            Sk.VPŽaidimas.getOrCreateLayer().v.addElement(self, x, y);
        }

        if (Pixi.hasResource(failoVardas))
            return __initialize();

        return Utils.createSuspension( 
            Pixi.getResource(failoVardas).then(__initialize)
        );
    }

    function attachToMouse(self) {

        if (Sk.tracing) {
            Sk.tracer.emit('daiktas:attachToMouse', { target: self }, SKTRACEID);
        }

        self.isDragging = true;

        let firstMove = undefined;

        const onMouseOrTouchMove = (event) => {
			if (!self.isDragging)
                return;

            const { x, y } = event.data.global;
            let { newX, newY } = Pixi.translateCoords(x, y);

            newX -= (self.sprite.width / 2);
            newY -= (self.sprite.height / 2)

            if (!firstMove){
                firstMove = true;
                self.container.x = newX;
                self.container.y = newY;
            }else{
                moveTo(self, newX, newY);
            }
		};
		
        self.container.on("touchmove", onMouseOrTouchMove);
		self.container.on("mousemove", onMouseOrTouchMove);
    }

    function detachFromMouse(self) {

        if (Sk.tracing) {
            Sk.tracer.emit('daiktas:detachFromMouse', { target: self }, SKTRACEID);
        }

        if (!self.teleportTo(self.container.x, self.container.y))
            return;

        self.container.off("mousemove");
        self.container.off("touchmove");
        self.isDragging = false;
    }

    function teleport(kwa) {
        const { self, x, y, z } = Utils.mapArgs(arguments, kwa, [{ x: null }, { y: null }, {z: null}]);

        if (Sk.tracing) {
            Sk.tracer.emit('daiktas:teleport', { target: self, x: x, y: y }, SKTRACEID);
        }

        return new Sk.builtin.bool( self.teleportTo(x, y, z) );
    }

    function hide(self) {
        if (Sk.tracing) {
            Sk.tracer.emit('daiktas:hide', { target: self }, SKTRACEID);
        }

        self.sprite.visible = false;
        self.container.interactive = false;
        hideZones(self);
    }

    function show(self) {
        if (Sk.tracing) {
            Sk.tracer.emit('daiktas:show', { target: self }, SKTRACEID);
        }
        
        self.sprite.visible = true;
        self.container.interactive = true;
    }

    function moveTo(self, newX, newY){
        if (self.isDrawing)
            __drawLine(self, newX, newY);

        self.container.x = newX;
        self.container.y = newY;
        
        self.container.zOrder = self.layer.zIndex + newY + self.obstacleArea.y;
    }

    function showZones(self) {
        if (self.zone)
            return;

        self.zone = new PIXI.Graphics();
        self.container.addChild(self.zone);

        self.zone.lineStyle(2, 0x00FF00, 1);
        self.zone.beginFill(0x005A00, 0.25);

        self.zone.drawRect(self.hitArea.x, self.hitArea.y, self.hitArea.width, self.hitArea.height);
        self.zone.endFill();

        if (!self.obstacleArea.x && !self.obstacleArea.y && !self.obstacleArea.width && !self.obstacleArea.height)
            return;

        self.zone.lineStyle(2, 0xFF00FF, 1);
        self.zone.beginFill(0x650A5A, 0.25);

        self.zone.drawRect(self.obstacleArea.x, self.obstacleArea.y, self.obstacleArea.width, self.obstacleArea.height);

        self.zone.endFill();
    }

    function hideZones(self) {
        if (!self.zone)
            return;

        self.zone.clear();
        self.container.removeChild(self.zone);
        self.zone = null;
    }

    function penDown(self){
        self.isDrawing = true;
    }

    function stomp(self){
        self.layer.drawDot(self.container.x + self.obstacleArea.x + (self.obstacleArea.width/2), 
                            self.container.y + self.obstacleArea.y + (self.obstacleArea.height/2));
    }

    function penUp(self){
        self.isDrawing = false;
    }

    function setLineColor(kwa){
        const { self, spalva } = Utils.mapArgs(arguments, kwa, [{ spalva: null }]);

        if (spalva != null)
            self.lineColor = spalva -0;  

        return getLineColor(self);
    }

    function getLineColor(self){
        return Sk.builtin.int_(self.lineColor);
    }

    function getDistance(kwa){
        const { self, elementas } = Utils.mapArgs(arguments, kwa, [{ elementas: null }]);
        if (!elementas || !elementas.v || !elementas.v.container)
            return Sk.builtin.float_(100000);

        const dx = elementas.v.container.x - self.container.x;
        const dy = elementas.v.container.y - self.container.y;
        let distance = Math.sqrt( dx*dx + dy*dy );

        return Sk.builtin.float_(distance);
    }

    function showAskBubble(){
        const [self, ...text] = arguments;
        const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");

        if (Sk.tracing) {
            let answerHolder = { answer: "daiktas:showAskBubble" };
            Sk.tracer.emit('daiktas:showAskBubble', { target: self, text: textToShow, answerHolder }, SKTRACEID);
            return new Sk.builtin.str( answerHolder.answer);
        }

        return Sk.misceval.promiseToSuspension(
                    Pixi.createAskBubble(self, textToShow)
                        .then(Utils.remapToPyStr)
                );
    }

    function showAskNumberBubble(){
        const [self, ...text] = arguments;
        const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");

        function __toNumber(answer){
            try{
                return parseFloat(answer) || 0;
            }catch(err){
                return 0;
            }
        }

        if (Sk.tracing) {
            let answerHolder = { answer: "daiktas:showAskNumberBubble" };
            Sk.tracer.emit('daiktas:showAskNumberBubble', { target: self, text: textToShow, answerHolder }, SKTRACEID);
            return new Sk.builtin.int_( __toNumber(answerHolder.answer) );
        }

        return Sk.misceval.promiseToSuspension(
                    Pixi.createAskBubble(self, textToShow, NUMERIC_REGEX)
                        .then(Utils.remapToPyNumber)
                );
    }

    function teleportTo(self, newX, newY, newZ) {
        self.show();

        if (newX == null && newY == null && newZ == null)
            return true; // TODO: shoulnd't it be 'return false'? cannot  move to undefined coordinates!

        if (!self.layer){
            console.error("no layer!");
            return false;
        }

        if (newX != null && newY != null){
            if (! self.layer.canTeleportTo(self, newX, newY))
                return false;
        
            self.moveTo(newX, newY);
        }

        if (newZ != null)
            self.container.zOrder = newZ;
        else
            self.container.zOrder = self.layer.zIndex + newY + self.obstacleArea.y;

        return true;
    }

    function __drawLine(self, newX, newY){
        self.layer.drawLine(self.container.x + self.obstacleArea.x + (self.obstacleArea.width/2), 
                            self.container.y + self.obstacleArea.y + (self.obstacleArea.height/2), 
                            newX + self.obstacleArea.x + (self.obstacleArea.width/2),  
                            newY + self.obstacleArea.y + (self.obstacleArea.height/2),
                            self.lineWidth,
                            self.lineColor)
    }

    function getObstacleArea(self) {
        return new Sk.builtin.tuple([
            new Sk.builtin.int_(self.obstacleArea.x),
            new Sk.builtin.int_(self.obstacleArea.y),
            new Sk.builtin.int_(self.obstacleArea.width),
            new Sk.builtin.int_(self.obstacleArea.height),
        ]);
    }

    function setObstacleArea(kwa) {
        let { self, x, y, plotis, aukštis } =
            Utils.mapArgs(arguments, kwa,
                [{ x: -1 }, { y: -1 },
                { plotis: -1 }, { aukštis: -1 }]);
        if (x < 0)
            x = self.obstacleArea.x;
            
        if (y < 0)
            y = self.obstacleArea.y;

        if (plotis < 0)
            plotis = self.obstacleArea.width - x;

        if (aukštis < 0)
            aukštis = self.obstacleArea.height - y;

        self.obstacleArea = {
            x: x, y: y, width: plotis, height: aukštis
        };
    }

    function setAlpha(kwa){
        let { self, alfa } = Utils.mapArgs(arguments, kwa, [{ alfa: -1 }]);

        if (alfa > 1)
            alfa = 1;

        if (alfa > 0)
            self.container.alpha = alfa;

        return getAlpha(self);
    }

    function getAlpha(self){
        return Sk.builtin.float_(self.v.container.alpha);
    }

    function setScale(kwa){
        let { self, mastelis } = Utils.mapArgs(arguments, kwa, [{ mastelis: -1 }]);

        if (mastelis > 0)
            self.v.setScale(mastelis); // will call resize(), but can be overriden

        return getScale(self);
    }

    function resize(self, scaleFactor){
        if (scaleFactor <= 0)
            scaleFactor = 1;

        if (scaleFactor >= 20)
            scaleFactor = 20;

        // TODO: ensure image scalling  will respect new hit and obstacle areas never overlap with anything else on layer
        self.container.scale.x = scaleFactor;
        self.container.scale.y = scaleFactor;
    }

    function getScale(self){
        return Sk.builtin.float_(self.v.container.scale.x);
    }

    function isTouching(kwa){
        const { self, daiktas } = Utils.mapArgs(arguments, kwa, [{ daiktas: null }]);

        if (!daiktas || !daiktas.container || !daiktas.hitArea)
            return;

        const me =  { 
                        x: self.container.x + self.hitArea.x, 
                        y: self.container.y + self.hitArea.y, 
                        width: self.hitArea.width, 
                        height: self.hitArea.height 
                    };
        const you = { 
                        x: daiktas.container.x + daiktas.hitArea.x, 
                        y: daiktas.container.y + daiktas.hitArea.y,
                        width: daiktas.hitArea.width, 
                        height: daiktas.hitArea.height 
                    };

        return new Sk.builtin.bool(Pixi.isColliding(me, you));
    }

    function __off(container, eventNames){
        if (Array.isArray(eventNames))
            for (const eventName of eventNames)
                container.off(eventName);
        else
            container.off(eventNames);
    }

    function __on(container, eventNames, komanda){
        if (Array.isArray(eventNames))
            for (const eventName of eventNames)
                container.on(eventName, komanda);
        else
            container.on(eventNames, komanda);
    }

    function __createCallbackFn(komanda, eventNames) {
        return function (event) {
            const { x, y } = event.data.global;
            const { newX, newY } = Pixi.translateCoords(x, y);
            const pyX = new Sk.builtin.int_(newX);
            const pyY = new Sk.builtin.int_(newY);
            Sk.misceval.applyAsync(undefined, komanda, undefined, undefined, undefined, [pyX, pyY]);
            event.stopPropagation();
        };
    }

    function __createTracerMethodCall(komanda){
        return function(x, y){
            const pyX = new Sk.builtin.int_(x);
            const pyY = new Sk.builtin.int_(y);
            Sk.misceval.applyAsync(undefined, komanda, undefined, undefined, undefined, [pyX, pyY]).catch(err => console.error("error in tracer method call", err));
        }
    }

})();
