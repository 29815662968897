Sk.Angis = Sk.Angis || {};

(function() {
    const Utils = Sk.Angis.Utils;


    Sk.Angis.PromiseCloser = (mod) => Sk.misceval.buildClass(mod, function($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function(kwa) {
            const {self, closeable, delay} = 
                Utils.mapArgs(arguments, kwa, [{closeable: null}, {delay: -1}]);

            init(closeable, delay);

            // Workaround for remapToJs
            self.v = self;

            self.init = init;

            function init(closeable, delay){
                self.closeable = closeable;
                self.delay = delay;
                self.timeoutHandler = self.delay > 0 ? Utils.setTimeout(pyCallback(function(){ stop(self); } ), self.delay) : null;

                return self;
            };
        });

        $loc.stok = Utils.createMethod(stop);
        $loc.stop = $loc.stok;
        $loc.užsidaryk = $loc.stok;

        $loc.užsidarykPo = Utils.createMethodKWA(stopAfter);

        function stop(self) {
            if (self.timeoutHandler)
                Utils.clearTimeout(self.timeoutHandler);
            self.timeoutHandler = 0;

            try{
                if (self.closeable)
                    self.closeable.close();
                self.closeable = null;
            }catch(err){
                console.error("closeable already closed", err);
            }
        }

        function stopAfter(kwa){
            let { self, msec } = Utils.mapArgs(arguments, kwa, [{ msec: 10000}]);

            if (self.timeoutHandler)
                Utils.clearTimeout(self.timeoutHandler);
                
            self.delay = msec;
            self.timeoutHandler = self.delay > 0 ? Utils.setTimeout(pyCallback(function(){ stop(self); } ), self.delay) : null;
        }

        function pyCallback(pyFunc){
            return function(){
                Sk.misceval.applyAsync(undefined, pyFunc, undefined, undefined, undefined, []);
            };
        }

    }, "Taimeris", []);

})();
