Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function () {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    const NUMERIC_REGEX = {
        allowedValue: /^[-]?\d+(\.\d+)?$/,
        allowedChars: /^[-]?[0-9]*[\.]?[0-9]*$/
    };

    // Might merge Plytelė and Dekoracija to one thing.
    Sk.VPŽaidimas.Dekoracija = (mod) => Sk.misceval.buildClass(mod, function ($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function (kwa) {
            const { self, failoVardas, x, y, tag } = Utils.mapArgs(arguments, kwa, [{ failoVardas: null }, { x: -1 }, { y: -1 }, { tag: undefined }]);

            return DekoracijaConstructor(self, failoVardas, x, y, tag);
        });

    }, "Dekoracija", [mod.Daiktas]);

    Sk.VPŽaidimas.Dekoracija.ctor = DekoracijaConstructor;


    function DekoracijaConstructor(self, failoVardas, x, y, tag) {

        function __initialize(){
            Sk.VPŽaidimas.Daiktas.ctor(self, failoVardas, x, y, tag);

            self.obstacleArea = {
                x: 0,
                y: 0,
                width: 0,
                height: 0
            };

            self.hitArea = {
                x: 0,
                y: 0,
                width: 0,
                height: 0
            };

            self.clickableArea = {
                x: 0,
                y: 0,
                width: self.container.width,
                height: self.container.height
            };

            if (Sk.tracing) {
                Sk.tracer.emit('dekoracija:new', { target: self }, SKTRACEID);
            }
        }

        if (Pixi.hasResource(failoVardas))
            return __initialize();

        return Utils.createSuspension( 
            Pixi.getResource(failoVardas).then(__initialize)
        );
    }

})();
