Sk.Angis = Sk.Angis || {};

(function () {
    const Utils = Sk.Angis.Utils;

    let pixiAppInstance;
    let eventListeners = [];
    let animations = [];

    Sk.Angis.Pixi = {
        HEIGHT: 600,
        WIDTH: 600,
        filters: [],

        init: init,
        reset: reset,
        dispose: dispose,
        ensureInitialized: ensureInitialized,

        render: render,
        resize: resize,
        translateCoords: translateCoords,

        getStage: getStage,
        getCurrentlyVisibleContainer: getCurrentlyVisibleContainer,

        stop: stop,
        start: start,
        loadResources: loadResources,
        loadGameJson: loadGameJson,

        createContainer: createContainer,
        createSprite: createSprite,
        createDisplayGroup: createDisplayGroup,
        getTexture: getTexture,
        getResource: getResource,
        hasResource: hasResource,

        hideAllContainers: hideAllContainers,
        addContainer: addContainer,
        removeContainer: removeContainer,
        setStageSize: setStageSize,
        clearStage: clearStage,
        createGraphics: createGraphics,
        createAnimation: createAnimation,
        createAnimationSheet: createAnimationSheet,
        createAnimationFromSheet: createAnimationFromSheet,

        addTicker: addTicker,
        removeTicker: removeTicker,

        createKeyboardEventHandler: createKeyboardEventHandler,

        isColliding: isColliding,
        getAnimationSprite: getAnimationSprite,
        replaceContainerSpriteWithAnimation: replaceContainerSpriteWithAnimation,
        stopContainerAnimation: stopContainerAnimation,

        createAskBubble: createAskBubble,
        createSpeechBubble: createSpeechBubble,

        takeScreenshot: takeScreenshot,

        isVisible: isVisible,

        testProgressBar: testProgressBar
    };

    function __destroyPreviousPixiAppInstance(prevInstance) {
        Utils.clearTimeouts();
        Utils.clearIntervals();
        __executeDestructors();
        __clearEvents();
        __clearAnimations();
        __clearFilters();
        Sk.Angis.resetKeyboardEvents();

        if (!prevInstance)
            return null;

        console.log("destroying previous pixi");

        if (prevInstance.view && prevInstance.view.parent)
            prevInstance.view.parent.removeChild(prevInstance.view);

        prevInstance.stop();
        prevInstance.destroy({ removeView: true, stageOptions: { children: true } });

        return null;
    }

    function reset() {
        __destroyPreviousPixiAppInstance(pixiAppInstance);

        pixiAppInstance = null;

        let element = document.getElementById("gamePanelCanvas");
        if (element && element.pixiApp)
            element.pixiApp = null;
    }

    function init() {
        let element = document.getElementById("gamePanelCanvas");
        if (element == null) {
            console.log("couldn't find #gamePanelCanvas element ");
            return;
        }

        if (pixiAppInstance != null) {
            if (element.pixiApp === pixiAppInstance) {
                console.log("PIXI already initialized, returning");
                return pixiAppInstance;
            }
            else
                // turns we've had PIXI initialized already (pixiAppInstance exists)
                // but the game panel canvas DOM element is changed - this happens in SPA.
                // neet to reset.
                pixiAppInstance = __destroyPreviousPixiAppInstance(pixiAppInstance);
        }

        if (element.pixiApp)
            element.pixiApp = __destroyPreviousPixiAppInstance(element.pixiApp);

        console.log("creating pixi");

        if (!Sk.tracing && !PIXI.utils.isWebGLSupported()) {
            showFatalError("Netinkama naršyklė :-( Jūsų naršyklė nepalaiko WebGL");
            throw new WebGlNotSupported("Netinkama naršyklė");
        }

        pixiAppInstance = new PIXI.Application({
            width: Sk.Angis.Pixi.WIDTH,
            height: Sk.Angis.Pixi.HEIGHT
        });

        /////
        // pixi display plugin setup - allows z-order by y-coordinate
        // specify display list component
        pixiAppInstance.stage = new PIXI.display.Stage();
        // PixiJS v5 sorting - works on zIndex - and layer gets its zIndex from a group!
        pixiAppInstance.stage.sortableChildren = true;

        element.pixiApp = pixiAppInstance;
        element.appendChild(pixiAppInstance.view);
        pixiAppInstance.element = element;

        /////
        // resizing app on load and on window resize.
        // don't forget to call Sk.Angis.Pixi.resize() if you resize the canvas html element manually
        resize();
        window.addEventListener("resize", resize);

        //////
        // Pixi loader progressbar setup - need to setup only once per Pixi instance
        pixiAppInstance.progressScreen = new Sk.Angis.ProgressScreen(pixiAppInstance).attachToLoader(PIXI.Loader.shared);

        console.log("Pixie initialized");
    }

    function ensureInitialized() {
        if (pixiAppInstance) {
            return;
        }

        return init();
    }

    function dispose() {
        __destroyPreviousPixiAppInstance(pixiAppInstance);
        pixiAppInstance = undefined;

        let element = document.getElementById("gamePanelCanvas");
        if (element != null) {
            element.pixiApp = undefined;
        }
    }

    function loadResources(paths) {

        const loader = PIXI.Loader.shared;

        if (loader.loading){
            console.log("*** loader is busy, adding resources to queue");
            return new Promise(((resolve, rej) => {
                loader.onComplete.once( () => loadResourcesAndResolve(resolve) )
            }))
        }

        return new Promise(((resolve, rej) => {
            loadResourcesAndResolve(resolve);
        }));

        function addPathToLoad(path, loader) {
            try{
                if (Array.isArray(path)) {
                    const [name, filePath] = path;
                    if (!loader.resources[name]) {
                        loader.add(name, filePath);
                    }
                    return;
                }

                if (!loader.resources[path])
                    loader.add(path);
            }catch(err){
                console.error(err);
            }
        }

        function loadResourcesAndResolve(resolve){
            // NOTE: we need to set baseUrl and cors for all the loaders. 
            // must use single shared PIXI loader all across the app.

            for (const path of paths)
                addPathToLoad(path, loader);

            pixiAppInstance.progressScreen.show(paths.length);

            loader.load((_, resources) => {
                loader.resources = Object.assign(loader.resources, resources); // backward-compatible syntax  for {...loader.resources, ...resources }
                resolve(resources);

                if (pixiAppInstance.progressScreen)
                    pixiAppInstance.progressScreen.hide();
            });
        }

    }

    function testProgressBar() {
        return new Promise(((resolve, rej) => {
            pixiAppInstance.progressScreen.show(100);

            let current = 0;
            function increment() {
                pixiAppInstance.progressScreen.next();
                current++;
                if (current < 100)
                    return Utils.setTimeout(increment, 100);

                pixiAppInstance.progressScreen.hide();
                resolve();
            }
            Utils.setTimeout(increment, 100);
        }));
    }

    function loadGameJson(path) {
        return loadResources([{ name: "gameJson", url: path }]).then((resources) => resources.gameJson.data);
    }

    function createContainer() {
        return new PIXI.Container();
    }

    function createDisplayGroup() {
        const ret = new PIXI.display.Group(0, true);
        pixiAppInstance.stage.addChild(new PIXI.display.Layer(ret));
        return ret;
    }

    // NON-BLOCKING: returning sprite with texture if it's already loaded. 
    // If not, we throw an exception.
    function createSprite(assetPath) {
        const resource = PIXI.Loader.shared.resources[assetPath];

        if (resource && resource.texture)
            return new PIXI.Sprite(resource.texture);

        throw "Failas " + assetPath + " dar neužkrautas! " +
        "Pirmiau iškviesk angis.naudosiuFailus( ['" + assetPath + "'] ), ir tik po to kurk elementus su tais failais!";
    }

    function getCurrentlyVisibleContainer() {
        for (const child of pixiAppInstance.stage.children)
            if (child instanceof PIXI.Container)
                if (child.visible)
                    return child;
    }

    function hideAllContainers() {
        for (const child of pixiAppInstance.stage.children)
            if (child instanceof PIXI.Container)
                child.visible = false;
    }

    function addContainer(container) {
        pixiAppInstance.stage.addChild(container);
        return container;
    }

    function removeContainer(container) {
        if (pixiAppInstance && pixiAppInstance.stage) {
            pixiAppInstance.stage.removeChild(container);
        }
    }

    function setStageSize(width, height) {
        Sk.Angis.Pixi.WIDTH = width;
        Sk.Angis.Pixi.HEIGHT = height;

        resize();
    }

    function render() {
        pixiAppInstance.render();
    }

    function __calcNewSize() {
        if (!pixiAppInstance || !pixiAppInstance.element)
            return { height: Sk.Angis.Pixi.HEIGHT, width: Sk.Angis.Pixi.WIDTH, maxHeight: Sk.Angis.Pixi.HEIGHT, maxWidth: Sk.Angis.Pixi.WIDTH };

        // explained in https://github.com/michelfaria/pixijs-scaletofit 
        const viewportWidth = pixiAppInstance.element.clientWidth;  // Width of the viewports
        const viewportHeight = pixiAppInstance.element.clientHeight; // Height of the viewport

        if (!viewportWidth && !viewportHeight) // not yet initialized
            return { height: Sk.Angis.Pixi.HEIGHT, width: Sk.Angis.Pixi.WIDTH, maxHeight: Sk.Angis.Pixi.HEIGHT, maxWidth: Sk.Angis.Pixi.WIDTH };

        if (viewportHeight / viewportWidth < Sk.Angis.Pixi.HEIGHT / Sk.Angis.Pixi.WIDTH)
            // If height-to-width ratio of the viewport is less than the height-to-width ratio
            // of the game, then the height will be equal to the height of the viewport, and 
            // the width will be scaled.
            return {
                height: viewportHeight,
                width: (viewportHeight * Sk.Angis.Pixi.WIDTH) / Sk.Angis.Pixi.HEIGHT,
                maxHeight: viewportHeight,
                maxWidth: viewportWidth
            };

        // In the else case, the opposite is happening.
        return {
            height: (viewportWidth * Sk.Angis.Pixi.HEIGHT) / Sk.Angis.Pixi.WIDTH,
            width: viewportWidth,
            maxHeight: viewportHeight,
            maxWidth: viewportWidth
        };
    }

    function resize() {
        if (!pixiAppInstance || !pixiAppInstance.element)
            return;

        console.log("resizing");
        const { height, width, maxHeight, maxWidth } = __calcNewSize();

        console.log("re-scaling: width " + Sk.Angis.Pixi.WIDTH + " -> " + width + " height " + Sk.Angis.Pixi.HEIGHT + " -> " + height);

        // Set the game screen size to the new values.
        // This command only makes the screen bigger --- it does not scale the contents of the game.
        // There will be a lot of extra room --- or missing room --- if we don't scale the stage.
        pixiAppInstance.renderer.resize(maxWidth, maxHeight);

        // This command scales the stage to fit the new size of the game.
        pixiAppInstance.stage.scale.set(width / Sk.Angis.Pixi.WIDTH, height / Sk.Angis.Pixi.HEIGHT);

        // This moves stage to center on screen
        pixiAppInstance.stage.position.set((maxWidth - width) / 2, (maxHeight - height) / 2);
    }

    function translateCoords(browserX, browserY) {
        return {
            newX: (browserX - pixiAppInstance.stage.x) / pixiAppInstance.stage.scale.x,
            newY: (browserY - pixiAppInstance.stage.y) / pixiAppInstance.stage.scale.y
        };
    }

    function getStage() {
        return pixiAppInstance.stage;
    }

    function stop() {
        pixiAppInstance.stop();
    }

    function start() {
        pixiAppInstance.start();
    }

    function clearStage() {
        pixiAppInstance.stage.removeChildren();
    }

    function getTexture(assetPath) {
        return getResource(assetPath).then(resource => resource ? resource.texture : null);
    }

    function getResource(assetPath) {
        return new Promise(((resolve) => {
            if (!assetPath) {
                resolve(null);
                return;
            }

            if (PIXI.Loader.shared.resources[assetPath]) {
                resolve(PIXI.Loader.shared.resources[assetPath]);
                return;
            }

            loadResources([assetPath]).then((result) => resolve(result[assetPath]));
        }));
    }

    function hasResource(assetPath) {
        if (!assetPath)
            return false;

        return !!PIXI.Loader.shared.resources[assetPath];
    }

    function createGraphics() {
        return new PIXI.Graphics();
    }

    function createAnimation(jsonPath, name) {
        return getResource(jsonPath).then(resource => {
            let sheet = resource.spritesheet;

            if (!sheet || !sheet.animations)
                throw new Sk.builtin.ValueError('Nėra tokio animacijų failo "' + jsonPath + '", arba šis failas netinkamas')

            name = name || 0; // if no name specified, lets default to the first sequence
            const seq = sheet.animations[name];
            if (!seq)
                throw new Sk.builtin.ValueError('Neradau animacijos pavadinimu "' + name + '" faile "' + jsonPath + '"');

            let animatedSprite = new PIXI.AnimatedSprite(seq);
            animations.push(animatedSprite);
            return animatedSprite;
        });
    }

    function createAnimationSheet(jsonPath) {
        return getResource(jsonPath).then(resource => {
            const sheet = resource.spritesheet;

            if (!sheet || !sheet.animations)
                throw new Sk.builtin.ValueError('Nėra tokio animacijų failo "' + jsonPath + '", arba šis failas netinkamas')

            return sheet;
        });
    }

    function createAnimationFromSheet(sheet, name) {
        name = name || 0; // if no name specified, lets default to the first sequence
        let seq = sheet.animations[name];
        if (!seq)
            throw new Sk.builtin.ValueError('Neradau animacijos pavadinimu "' + name + '"');

        seq = __curateTextureArray(seq);

        let animatedSprite = new PIXI.AnimatedSprite(seq);
        animations.push(animatedSprite);
        return animatedSprite;
    }

    function __curateTextureArray(seq) {
        if (!seq || !seq.length)
            return seq;

        let i = 0;
        while (i < seq.length) {
            if (!seq[i]) {
                console.error("Klaidingas Spritesheet - nėra tekstūros nr #", i);
                seq.splice(i, 1);
            }
            else
                i++;
        }

        return seq;
    }

    function addTicker(ticker) {
        pixiAppInstance.ticker.add(ticker);
    }

    function removeTicker(ticker) {
        pixiAppInstance.ticker.remove(ticker);
    }

    // Source: https://github.com/kittykatattack/learningPixi#keyboard-movement
    function createKeyboardEventHandler(value) {
        let key = {};
        key.value = value;
        key.isDown = false;
        key.isUp = true;
        key.press = undefined;
        key.release = undefined;
        //The `downHandler`
        key.downHandler = event => {
            // Ignore events from IDE editor
            const ideContainer = document.querySelector("#ideContainer");
            if (ideContainer) {
                if (ideContainer.contains(event.target))
                    return;
            }

            // ignore events from popup dialogs
            const dlg = document.querySelector("simple-modal-holder");
            if (dlg) {
                if (dlg.contains(event.target))
                    return;
            }

            if (key.value === '<any>' || event.key === key.value) {
                if (key.isUp && key.press) {
                    key.press(event.key);
                }
                key.isDown = true;
                key.isUp = false;
                event.preventDefault();
            }
        };

        //The `upHandler`
        key.upHandler = event => {
            // Ignore events from IDE editor
            const ideContainer = document.querySelector("#ideContainer");
            if (ideContainer) {
                if (ideContainer.contains(event.target))
                    return;
            }

            // ignore events from popup dialogs
            const dlg = document.querySelector("simple-modal-holder");
            if (dlg) {
                if (dlg.contains(event.target))
                    return;
            }

            if (key.value === '<any>' || event.key === key.value) {
                if (key.isDown && key.release) {
                    key.release(event.key);
                }
                key.isDown = false;
                key.isUp = true;
                event.preventDefault();
            }
        };

        //Attach event listeners
        const downListener = key.downHandler.bind(key);
        const upListener = key.upHandler.bind(key);

        window.addEventListener(
            "keydown", downListener, false
        );
        window.addEventListener(
            "keyup", upListener, false
        );

        // Detach event listeners
        key.unsubscribe = () => {
            window.removeEventListener("keydown", downListener);
            window.removeEventListener("keyup", upListener);
        };

        eventListeners.push(key);

        return key;
    }

    function isColliding(element, target) {
        if (element.x < target.x + target.width &&
            element.x + element.width > target.x &&
            element.y < target.y + target.height &&
            element.y + element.height > target.y) {
            return true;
        }

        return false;
    }

    function getAnimationSprite(fileName) {
        const resource = PIXI.Loader.shared.resources[fileName];

        return resource.spritesheet ? resource.spritesheet.animations : null;
    }

    function replaceContainerSpriteWithAnimation(container, animation, animationSpeed) {
        if (!(container.children[0] instanceof PIXI.AnimatedSprite)) {
            container.removeChildren();
            const sprite = new PIXI.AnimatedSprite(animation);
            animations.push(sprite);
            sprite.animationSpeed = animationSpeed;
            sprite.loop = false;
            container.addChild(sprite);
        }
    }

    function stopContainerAnimation(container) {
        if (container.children[0] instanceof PIXI.AnimatedSprite) {
            container.children[0].stop();
        }
    }

    function takeScreenshot(format, quality) {
        if (!pixiAppInstance || !pixiAppInstance.stage)
            throw "Pirmiausia reikia paleisti programą!";

        return pixiAppInstance.renderer.plugins.extract.base64(pixiAppInstance.stage, format, quality);
    }

    function createAskBubble(target, text, restrictions) {
        return new Promise(function (resolve) {
            restrictions = restrictions || { allowedChars: /[\s\S]*/, allowedValue: /[\s\S]*/ };

            let container;
            let objectSize;
            let position;

            if (target) {
                container = target.container;
                objectSize = target.hitArea;
            } else {
                container = addContainer(createContainer());
                objectSize = { width: 10, height: 10, x: 50, y: 50 };
                position = "bottom-right";
            }

            const padding = 5;
            const paddingRight = 25;
            const arrowSize = 20;

            const bubbleContainer = new PIXI.Container();

            const textElement = createBubbleText(text, padding);

            const input = createBubbleInput(0, restrictions.allowedChars);
            input.x = padding;
            input.y = padding * 2 + textElement.height;

            const closeAskBubble = () => {
                input.destroy();
                pixiAppInstance.stage.removeChild(bubbleContainer);
            };

            const accept = (acceptResponse) => {
                let response = input.text;
                if (acceptResponse != null) {
                    response = acceptResponse;
                }

                if (restrictions.allowedValue) {
                    if (!restrictions.allowedValue.test(response)) {
                        return;
                    }
                }

                closeAskBubble();
                resolve(response);
            };

            const cancel = () => {
                closeAskBubble();
                resolve('');
            }

            const area = {
                width: Math.max(input.width, textElement.width),
                height: input.height + textElement.height + padding * 2
            }

            position = position || getBubblePosition(area, padding, paddingRight, arrowSize, container, objectSize);
            const graphics = createBubbleGraphics(padding, paddingRight, arrowSize, area, position);

            const crossElement = createBubbleCross(padding, graphics);
            crossElement.on("pointerdown", accept);

            bubbleContainer.addChild(graphics);
            bubbleContainer.addChild(textElement);
            bubbleContainer.addChild(crossElement);
            bubbleContainer.addChild(input);

            let { x, y } = getBubbleCoordinates(container, objectSize, bubbleContainer, arrowSize, padding, position);
            if (x < 0)
                x = 0;
            if (y < 0)
                y = 0;
            const maxY = (Sk.Angis.Pixi.HEIGHT) - 50;
            if (y > maxY)
                y = maxY / 2;
                
            const maxX = (Sk.Angis.Pixi.WIDTH) - 50;
            if (x > maxX)
                x = maxX / 2;
            bubbleContainer.x = x;
            bubbleContainer.y = y;

            window.setTimeout(() => input.focus(), 10);

            pixiAppInstance.stage.addChild(bubbleContainer);

            if (Sk.tracing) {
                Sk.tracer.emit('pixi:askBubble', { target: target, text: text, restrictions: restrictions, accept: accept, cancel: cancel }, SKTRACEID);
            }

            input.on('keyup', keycode => {
                if (keycode === 13)
                    accept();

                if (keycode === 27)
                    cancel();
            });

            input.on('blur', () => {
                accept();
            });

        });
    }

    function createSpeechBubble(target, text) {
        const result = {
            close: function(){},
            promise: null
        };

        result.promise = new Promise(function (resolve) {

            const container = target ? target.container : pixiAppInstance.stage;
            const objectSize = target.hitArea || { x: container.x, y: container.y, width: container.width || 100, height: container.height || 100 } ;

            const padding = 5;
            const paddingRight = 25;
            const arrowSize = 20;

            const bubbleContainer = new PIXI.Container();
            const close = () => {
                if (pixiAppInstance != undefined)
                    pixiAppInstance.stage.removeChild(bubbleContainer);
                resolve();
            };

            result.close = close;

            bubbleContainer.interactive = true;
            bubbleContainer.on("pointerdown", close);

            const textElement = createBubbleText(text, padding);
            textElement.on("pointerdown", close);

            const position = getBubblePosition(textElement, padding, paddingRight, arrowSize, container, objectSize);
            const graphics = createBubbleGraphics(padding, paddingRight, arrowSize, textElement, position);
            graphics.on("pointerdown", close);

            const crossElement = createBubbleCross(padding, graphics);
            crossElement.on("pointerdown", close);

            bubbleContainer.addChild(graphics);
            bubbleContainer.addChild(textElement);
            bubbleContainer.addChild(crossElement);

            let { x, y } = getBubbleCoordinates(container, objectSize, bubbleContainer, arrowSize, padding, position);
            if (x < 0)
                x = 0;
            if (y < 0)
                y = 0;
            const maxY = (Sk.Angis.Pixi.HEIGHT) - 50;
            if (y > maxY)
                y = maxY / 2;
                
            const maxX = (Sk.Angis.Pixi.WIDTH) - 50;
            if (x > maxX)
                x = maxX / 2;

            bubbleContainer.x = x;
            bubbleContainer.y = y;
            pixiAppInstance.stage.addChild(bubbleContainer);

            if (Sk.tracing) {
                Sk.tracer.emit('pixi:speechBubble', { target: target, text: text, close: close }, SKTRACEID);
            }
        });

        return result;
    }

    function getBubblePosition(textElement, padding, paddingRight, arrowSize, container, objectSize) {

        /*
         * TODO: The code below is technically incorrect.
         * The code below only translates local coordinates to container.container space.
         * Which is OK if container.container IS pixiAppInstance.stage.
         * If we were to have more levels of nesting then we need to use proper coordinate translation.
         * Even more if rotation/skewing/scaling is applied then the code below is not correct.
         * 
         * So what should we do?
         * One way is to use `stage.toLocal({x, y}, container)` - translate coordinates (x, y) in container space, to stage space.
         * But this might be complications with rotations/skewing/scaling, it depends on where we want 
         * to put the buble on. If near the head/feet - then this is OK.
         * 
         * `container.getBounds()` - returns global (not stage) bounding box, which then could be translated to stage space
         * by basically doing `stage.toLocal()`.
         * 
         * OR
         * We can always just add the speech bubble to the parent of the container.
         * 
         **/

        const bubbleHeight = textElement.height + (padding * 2) + arrowSize;
        const bubbleWidth = textElement.width + padding + paddingRight;
        const stageWidth = pixiAppInstance.stage.width || Sk.Angis.Pixi.WIDTH;
        const stageHeight = pixiAppInstance.stage.height || Sk.Angis.Pixi.HEIGHT;
        const topSpace = container.y + objectSize.y;
        const bottomSpace = stageHeight - topSpace - objectSize.height;
        const leftSpace = container.x + objectSize.x;
        const rightSpace = stageWidth - leftSpace - objectSize.width;

        const yAxis = topSpace > bubbleHeight ? "top" : bottomSpace > bubbleHeight ? "bottom" : "top";
        const xAxis = leftSpace > bubbleWidth ? "left" : rightSpace > bubbleWidth ? "right" : "left";

        return `${yAxis}-${xAxis}`;
    }

    function getBubbleCoordinates(container, objectSize, bubbleContainer, arrowSize, padding, position) {
        return {
            "top-left": {
                x: container.x + objectSize.x - bubbleContainer.width + arrowSize + (objectSize.width / 2),
                y: container.y + objectSize.y - bubbleContainer.height - padding
            },
            "top-right": {
                x: container.x + objectSize.x - arrowSize + (objectSize.width / 2),
                y: container.y + objectSize.y - bubbleContainer.height - padding
            },
            "bottom-left": {
                x: container.x + objectSize.x - bubbleContainer.width + arrowSize + (objectSize.width / 2),
                y: container.y + objectSize.y + objectSize.height + padding + arrowSize
            },
            "bottom-right": {
                x: container.x + objectSize.x - arrowSize + (objectSize.width / 2),
                y: container.y + objectSize.y + objectSize.height + padding + arrowSize
            },
        }[position];
    }

    function createBubbleText(text, padding) {
        const style = new PIXI.TextStyle({
            fontFamily: "Arial",
            fontSize: 20,
            wordWrap: true,
            wordWrapWidth: 120,
        });
        const textElement = new PIXI.Text(text, style);

        textElement.x = padding;
        textElement.y = padding;
        textElement.interactive = true;

        return textElement;
    }

    function createBubbleInput(padding, characterRegex) {
        const input = new PIXI.TextInput({
            input: {
                fontFamily: "Arial",
                fontSize: 20,
                padding: padding,
                width: "200px",
                color: '#26272E'
            }
            // ,
            // box: {
            //     default: {fill: 0xE8E9F3, rounded: 12, stroke: {color: 0xCBCEE0, width: 3}},
            //     focused: {fill: 0xE1E3EE, rounded: 12, stroke: {color: 0xABAFC6, width: 3}},
            //     disabled: {fill: 0xDBDBDB, rounded: 12}
            // }
        });

        input.placeholder = '...?';
        input.pivot.x = 0;
        input.pivot.y = 0;
        input.restrict = characterRegex;

        return input;
    }

    function createBubbleCross(padding, container) {
        const style = new PIXI.TextStyle({
            fontFamily: "Arial",
            fontSize: 20,
            wordWrap: true,
            wordWrapWidth: 120,
        });

        const crossElement = new PIXI.Text("×", style);
        crossElement.x = container.width - crossElement.width - padding;
        crossElement.y = 0;
        crossElement.defaultCursor = "pointer";
        crossElement.interactive = true;
        crossElement.buttonMode = true;

        return crossElement;
    }

    function createBubbleGraphics(padding, paddingRight, arrowSize, textElement, position) {
        const graphics = new PIXI.Graphics();
        const bubbleHeight = (padding * 2) + textElement.height;
        const bubbleWidth = padding + paddingRight + textElement.width;
        const path = {
            "top-left": [
                0, 0,
                bubbleWidth, 0,
                bubbleWidth, bubbleHeight,
                bubbleWidth - arrowSize, bubbleHeight,
                bubbleWidth - arrowSize, bubbleHeight + arrowSize,
                bubbleWidth - (arrowSize * 2), bubbleHeight,
                0, bubbleHeight
            ],
            "top-right": [
                0, 0,
                bubbleWidth, 0,
                bubbleWidth, bubbleHeight,
                (arrowSize * 2), bubbleHeight,
                arrowSize, bubbleHeight + arrowSize,
                arrowSize, bubbleHeight,
                0, bubbleHeight
            ],
            "bottom-left": [
                0, 0,
                bubbleWidth - (arrowSize * 2), 0,
                bubbleWidth - arrowSize, 0 - arrowSize,
                bubbleWidth - arrowSize, 0,
                bubbleWidth, 0,
                bubbleWidth, bubbleHeight,
                0, bubbleHeight
            ],
            "bottom-right": [
                0, 0,
                arrowSize, 0,
                arrowSize, 0 - arrowSize,
                arrowSize * 2, 0,
                bubbleWidth, 0,
                bubbleWidth, bubbleHeight,
                0, bubbleHeight
            ],
        }[position];

        graphics.lineStyle(2, 0x000000, 1);
        graphics.beginFill(0xFFFFFF, 1);
        graphics.drawPolygon(path);
        graphics.endFill();

        graphics.interactive = true;

        return graphics;
    }

    function isVisible(element) {
        if (!element)
            return false;

        if (!element.v)
            return false;

        if (element.v.isVisible)
            return element.v.isVisible();

        if (!element.v.container)
            return false;

        return element.v.container.visible;
    }

    function __executeDestructors() {
        Utils.executeDestructors();
    }

    function __clearEvents() {
        eventListeners.forEach(key => key.unsubscribe());
        eventListeners.length = 0;
    }

    function __clearAnimations() {
        animations.forEach(s => s.destroy());
        animations.length = 0;
    }

    function __clearFilters() {
        if (pixiAppInstance && pixiAppInstance.stage && pixiAppInstance.stage.children) {
            Sk.Angis.Pixi.filters.splice(0, Sk.Angis.Pixi.filters.length);

            const container = getCurrentlyVisibleContainer();
            if (container)
                container.filters = Sk.Angis.Pixi.filters;
        }
    }

    function showFatalError(txt) {
        if (!Sk.tracing) {
            $("#gamePanelCanvas").append($('<div />', {
                class: 'fatalPixiError',
                text: txt
            }));
        }
    }

    /**
     * @constructor
     * @extends Sk.builtin.StandardError
     * @param {...*} args
     */
    WebGlNotSupported = function (args) {
        var o;
        if (!(this instanceof WebGlNotSupported)) {
            o = Object.create(WebGlNotSupported.prototype);
            o.constructor.apply(o, arguments);
            return o;
        }
        Sk.builtin.StandardError.apply(this, arguments);
    };
    Sk.abstr.setUpInheritance("WebGlNotSupported", WebGlNotSupported, Sk.builtin.StandardError);
    Sk.exportSymbol("WebGlNotSupported", WebGlNotSupported);

})();
