Sk.Angis = Sk.Angis || {};

(function() {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    const NUMERIC_REGEX = {
        allowedValue: /^[-]?\d+(\.\d+)?$/,
        allowedChars: /^[-]?[0-9]*[\.]?[0-9]*$/
    };

    Sk.Angis.printPy = Utils.createMethod(printPy);
    Sk.Angis.printJs = printJs;

    Sk.Angis.askPy = Utils.createMethod(askPy);
    Sk.Angis.askNumberPy = Utils.createMethod(askNumberPy);

    const logs = [];

    const TEXT_STYLE = new PIXI.TextStyle({
        fontFamily: 'Arial',
        fontSize: 17,
        // fontStyle: 'italic',
        // fontWeight: 'bold',
        fill: ['#99ff99', '#40d04f'], // gradient   
        stroke: '#4a1850',
        strokeThickness: 2
    });

    function askPy(){
        const [ ...text] = arguments;
        const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");

        if (Sk.tracing) {
            let answerHolder = { answer: "angis:showAskBubble" };
            Sk.tracer.emit('angis:showAskBubble', { target: self, text: textToShow, answerHolder }, SKTRACEID);
            return new Sk.builtin.str( answerHolder.answer );
        }

        return Sk.misceval.promiseToSuspension(
                    Pixi.createAskBubble(null, Utils.tryRemapToJs(textToShow))
                        .then(Utils.remapToPyStr)
                );
    }

    function askNumberPy(){
        const [ ...text] = arguments;
        const textToShow = text.map(t => Utils.tryRemapToJs(t)).join(" ");

        function __toNumber(answer){
            try{
                return parseFloat(answer) || 0;
            }catch(err){
                return 0;
            }
        }

        if (Sk.tracing) {
            let answerHolder = { answer: "angis:showAskNumberBubble" };
            Sk.tracer.emit('angis:showAskNumberBubble', { target: self, text: textToShow, answerHolder }, SKTRACEID);
            return new Sk.builtin.int_(__toNumber(answerHolder.answer));
        }

        return Sk.misceval.promiseToSuspension(
                    Pixi.createAskBubble(null, Utils.tryRemapToJs(textToShow), NUMERIC_REGEX)
                        .then(Utils.remapToPyNumber)
                );
    }

    function printPy() {
        const [...texts] = arguments;

        const textToShow = texts.map( t => {
            let jsObj = Utils.tryRemapToJs(t);

            if (t instanceof Sk.builtin.float_)
                jsObj = roundUp(jsObj);

            return jsObj;
        } ).join(" ");

        if (Sk.tracing) {
            Sk.tracer.emit('standalone:print', { text: textToShow }, SKTRACEID);
        }

        return Sk.misceval.promiseToSuspension(printJs(textToShow));
    }

    function printJs(text){
        Pixi.ensureInitialized();

        return new Promise(function (resolve) {

            const container = Pixi.createContainer();
            Pixi.addContainer(container);
            logs.push(container);
            
            const textBox = new PIXI.Text(text, TEXT_STYLE);
            container.addChild(textBox);

            const w = Pixi.WIDTH;
            const h = Pixi.HEIGHT;
            textBox.x = (w / 2) - (textBox.width / 2);
            textBox.y = (h / 2) - (textBox.height) - 10;

            const close = () => {
                removeTextbox(container);
                resolve();
            };

            // don't want this timeout to be canceled ever! don't use Utils.setTimeout(close, 5000);
            window.setTimeout(close, 5000);

            scollPreviousTextboxesUp();

            if (Sk.tracing) {
                Sk.tracer.emit('pixi:print', { text: text, close: close }, SKTRACEID);
            }
        });
    }

    function removeTextbox(container){
        Pixi.removeContainer(container);
        const index = logs.indexOf(container);
        if (index > -1)
            logs.splice(index, 1);
    }

    function scollPreviousTextboxesUp(){
        for(let i = 0; i < logs.length - 1; i++)
            logs[i].y = logs[i].y - 30;
    }

    function isFloat(obj){
        return parseInt(obj) === obj;
    }

    function roundUp(floatNumber){
        if (!floatNumber)
            return "0";
            
        try{
            var ret = parseFloat(floatNumber);

            var str = "" + ret;
            if (str.length < 8)
                return str;

            var expPos = str.lastIndexOf(".");
            if (expPos < 0)
                return str;
                
            var expLength = str.length - expPos;
            if (expLength < 5)
            	return str;
                
            ret = ret.toFixed(8) + "";

            while (ret){
                var lastZero = ret.slice(-1);
                
                if (lastZero === '.')
                    return ret + '0';

                if (lastZero !== '0')
                    return ret;

                ret = ret.substring(0, ret.length - 1);
            }

            return ret;
        }catch(err){
            return "0";
        }
    }

})();
