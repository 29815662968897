Sk.Angis = Sk.Angis || {};

(function() {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.Angis.Garsas = (mod) => Sk.misceval.buildClass(mod, function($gbl, $loc) {

        $loc.__init__ = Utils.createMethod(function(self, filename) {
            var fname = Utils.tryRemapToJs(filename);

            self.destroy = function(){
                if (self.sound){
                    self.sound.stop();
                }
                self.sound = null;
            };

            Utils.registerDestructor(self);

            // Workaround for remapToJs
            self.v = self;

            return Utils.createSuspension(new Promise(function(resolve, reject){
                Pixi.getResource(fname).
                        then(createSound(self, fname)).
                        then(resolve).
                        catch(reject);
            }));
        });

        $loc.grok     = Utils.createMethod(soundPlayBlockingMethod);

        $loc.grokFone = Utils.createMethod(soundPlayInBackgroundMethod);

        $loc.pauzė    = Utils.createMethod(soundPauseMethod);
        $loc.pauze = $loc.pauzė;
        $loc.pause = $loc.pauzė;

        $loc.tęsk     = Utils.createMethod(soundResumeMethod);
        $loc.toliau = $loc.tęsk;
        $loc.continue = $loc.tęsk;
        $loc.resume = $loc.tęsk;

        $loc.stop     = Utils.createMethod(soundStopMethod);
        $loc.stok = $loc.stop;

        $loc.lygis    = Utils.createMethodKWA(soundVolumeKWA);
        $loc.volume = $loc.lygis;

        $loc.duokLygį = Utils.createMethod(soundGetVolumeMethod);
    });

    function createSound(self, filename){
        return function (result){
            self.sound = result.sound;
        };
    }

    function soundPlayBlockingMethod(self){
        return soundPlayBlocking(self);
    }

    function soundPlayInBackgroundMethod(self){
        soundPlayInBackground(self);
        return Sk.builtin.none.none$;
    }

    function soundStopMethod(self){
        soundStop(self);
        return Sk.builtin.none.none$;
    }
    
    function soundPauseMethod(self){
        soundPause(self);
        return Sk.builtin.none.none$;
    }

    function soundResumeMethod(self){
        soundResume(self);
        return Sk.builtin.none.none$;
    }

    function soundVolumeKWA(kwa){
        const { self, lygis } = Utils.mapArgs(arguments, kwa, [{ lygis: null }]);

        if (!self.sound)
            return new Sk.builtin.float_ (0);

        if (lygis != null)
            self.sound.volume = (lygis-0) / 100.0;

        return new Sk.builtin.float_(self.sound.volume * 100);
    }

    function soundGetVolumeMethod(self){
        if (!self.sound)
            return new Sk.builtin.float_(0);

        return new Sk.builtin.float_(self.sound.volume * 100);
    }

    function soundPlayBlocking(self){
        if (!self || !self.sound)
            return;
    
        return Utils.createSuspension(new Promise(function(resolve, reject){
                self.sound.play({complete: resolve});
            }));
    }
    
    function soundPlayInBackground(self){
        if (!self || !self.sound)
            return;
    
        self.sound.play();
    }
    
    function soundStop(self){
        if (!self || !self.sound)
            return;
    
        self.sound.stop();
    }

    function soundPause(self){
        if (!self || !self.sound)
            return;
    
        self.sound.pause();
    }
    
    function soundResume(self){
        if (!self || !self.sound)
            return;
    
        self.sound.resume();
    }

})();

