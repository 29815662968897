Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function() {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    Sk.VPŽaidimas.Sluoksnis = (mod) => Sk.misceval.buildClass(mod, function($gbl, $loc) {

        $loc.__init__ = Utils.createMethod(function(self, scena) {
            self.container = Pixi.createContainer();
            self.container.zIndex = 5;
            self.scena = scena;
            self.elementai = [];
            self.zIndex = 5;
            
            // z-index - ordable group (see pixi-layers plugin)
            self.displayGroup = Pixi.createDisplayGroup(); 
            self.container.displayGroup  = self.displayGroup;

            self.canTeleportTo = function(who, x, y){
                for (const el of self.elementai){
                    if (el !== who && __intersects(el, x, y, who))
                        return false;
                }
                return true;
            };

            self.getTeleportConflicts = function(who, x, y){
                const conflicts = [];
                for (const el of self.elementai){
                    if (el !== who && __intersects(el, x, y, who))
                        conflicts.push(el);
                }
                return conflicts;
            };

            self.getOrCreateGraphics = function(){
                if (!self.graphics){
                    self.graphics = Sk.Angis.Utils.createPy(Sk.VPŽaidimas.Grafika(mod), []);
                    self.container.addChild(self.graphics.v.container);
                }
                self.graphics.v.show();
                return self.graphics;
            };

            self.drawLine = function(x, y, x2, y2, width, color){
                self.getOrCreateGraphics().v.drawLine(x, y, x2, y2, width, color);
            };

            self.drawDot = function(x, y){
                self.getOrCreateGraphics().v.drawDot(x,y);
            };

            self.clear = function(){
                self.getOrCreateGraphics().v.clear();
            }

            self.addElement = function(elementas, x, y, z, stulpelis, eilutė){
                return addElement(self, elementas, x, y, z, stulpelis, eilutė);
            };

            self.getElement = function(x, y, stulpelis, eilutė){
                return getElement(self, x, y, eilutė, stulpelis);
            };

            self.getElements = function(x, y, dx, dy, stulpelis, eilutė, zona){
                return getElements(self, x, y, dx, dy, eilutė, stulpelis, zona);
            };

            self.removeElement = function(el){
                return removeElement(self, el);
            };

            // Workaround for remapToJs
            self.v = self;

            // self.container shall be attached to Pixi stage by my parent, i.e. Scena.
        });

        $loc.pridėkElementą = Utils.createMethodKWA(addElementKWA);

        $loc.duokElementą = Utils.createMethodKWA(getElementKWA);
        $loc.duokElementus = Utils.createMethodKWA(getElementsKWA);

        $loc.pieškLiniją = Utils.createMethodKWA(drawLineKWA);
        $loc.drawLine = $loc.pieškLiniją;

        $loc.pieškTašką = Utils.createMethodKWA(drawDotKWA);
        $loc.drawDot = $loc.pieškTašką;

        $loc.išvalykGrafiką = Utils.createMethod(clear);
        $loc.clearGraphics = $loc.išvalykGrafiką;

        $loc.duokGrafiką = Utils.createMethod(getGraphics);
        $loc.getGraphics = $loc.duokGrafiką;

        $loc.naudokGrafiką = Utils.createMethodKWA(useGraphicsKWA);
        $loc.useGraphics = $loc.naudokGrafiką;

        function getGraphics(self){
            return self.getOrCreateGraphics();
        }

        function useGraphicsKWA(kwa){
            const { self, grafika } = Utils.mapArgs(arguments, kwa, [{grafika: null}]);
            if (!grafika || !grafika.v || !grafika.v.container)
                return;

            if (self.graphics === grafika)
                return;

            if (self.graphics){
                self.graphics.v.hide();
                self.container.removeChild(self.graphics.v.container);
            }

            self.graphics = grafika;
            self.container.addChild(grafika.v.container);
            grafika.v.show();
        }

        function addElementKWA(kwa){
            const { self, elementas, x, y, z, stulpelis, eilutė } = Utils.mapArgs(arguments, kwa, [{ elementas: null }, 
                                                                             {x: undefined}, {y: undefined},
                                                                             {z: undefined}, 
                                                                             {stulpelis: undefined}, {eilutė: undefined}]);

            return self.addElement(elementas, x, y, z, stulpelis, eilutė);
        }


        function addElement(self, elementas, x, y, z, stulpelis, eilutė){
            if (!elementas || !elementas.v || !elementas.v.container)
                return;
            
            __setXOrStulpelis(self, elementas, x, stulpelis);

            __setYOrEilute(self, elementas, y, eilutė);

            if (__isOutOfBounds(elementas.v.container.x, elementas.v.container.y))
                __hideElement(elementas);
            else
                __showElement(elementas);

            if (elementas.v.layer)
                elementas.v.layer.removeElement(elementas);
            self.container.addChild(elementas.v.container);
            elementas.v.layer = self;

            // adding element's sprite to a z-index - ordable group (see pixi-layers plugin)
            elementas.v.container.parentGroup = self.displayGroup;
            elementas.v.container.zOrder = z || __calcZorder(self, elementas);

            self.elementai.push(elementas);
        }

        function removeElement(self, el){
            self.container.removeChild(el.v.container);

            for (var i=0; i< self.elementai.length; i++)
                if (self.elementai[i] === el)
                    self.elementai.splice(i, 1);
        }

        function drawLineKWA(kwa){
            let {self, x1, y1, x2, y2 } = Utils.mapArgs(arguments, kwa, 
                                                                [{x1: undefined}, {y1: undefined}, 
                                                                 {x2: undefined}, {y2: undefined}]);

            if (x1 != null && x2 != null && y1 != null && y2!=null)
                self.drawLine(x1, y1, x2, y2);
        }

        function drawDotKWA(kwa){
            let {self, x, y } = Utils.mapArgs(arguments, kwa, [{x: undefined}, {y: undefined}]);

            if (x != null && y != null)
                self.drawDot(x, y);
        }

        function clear(self){
            self.clear();
        }

        function getElementKWA(kwa){
            let {self, x, y, stulpelis, eilutė } = Utils.mapArgs(arguments, kwa, 
                                                                    [{x: undefined}, {y: undefined}, 
                                                                     {stulpelis: undefined}, {eilutė: undefined}]);

            return self.getElement(x, y, stulpelis, eilutė );
        }

        function getElementsKWA(kwa){
            let {self, x, y, dx, dy, stulpelis, eilutė, zona } = Utils.mapArgs(arguments, kwa, 
                                                                    [{x: undefined}, {y: undefined}, 
                                                                     {dx: undefined}, {dy: undefined},
                                                                     {stulpelis: undefined}, {eilutė: undefined},
                                                                     {zona:undefined} ]);

            const ret = self.getElements(x, y, dx, dy, stulpelis, eilutė, zona );

            return Utils.tryRemapToPy(ret);
        }

        function getElement(self, x, y, stulpelis, eilutė){
            if (x === undefined && y === undefined && stulpelis === undefined && eilutė === undefined)
                return null;

            if (!x && stulpelis)
                x = self.scena.tileWidth * stulpelis;

            if (!y && eilutė)
                y = self.scena.tileHeight * eilutė;

            for (const el of self.elementai)
                if (__isAt(el, x, y))
                    return el;

            return Sk.builtin.none.none$;
        }

        function getElements(self, x, y, dx, dy, stulpelis, eilutė, zona){
            if (zona !== undefined)
                return __getElementsFromArea(self, zona);

            if (x !== undefined && y !== undefined)
                return __getElementsFromCoordinates(self, x, y, dx, dy);

            if (stulpelis !== undefined && eilutė !== undefined)
                return __getElementsFromCoordinates(self, self.scena.tileWidth * stulpelis, self.scena.tileHeight * eilutė, dx, dy);

            return self.elementai.slice();

            function __getElementsFromCoordinates(self, x, y, dx, dy){
                const ret = []

                for (const el of self.elementai)
                    if (__isVisible(el))
                        if (__isAround(el, x, y, dx, dy))
                            ret.push(el);

                return ret;
            }

            function __getElementsFromArea(self, zona){
                const ret = []
                for (const el of self.elementai)
                    if (__isVisible(el))
                        if (__isInZone(el, zona))
                            ret.push(el);

                return ret;
            }
        }

        function __hideElement(el){
            if (el.hide)
                return el.hide();

            el.v.container.visible = false;
        }

        function __showElement(el){
            if (el.show)
                return el.show();

            el.v.container.visible = true;
        }

        function __isVisible(el){
            if (el.v && el.v.isVisible)
                return el.v.isVisible();

            if (el.v && el.v.container)
                return el.v.container.visible;

            return el.visible;
        }

        function __calcZorder(self, elementas){
            const containerY = elementas.v.container? elementas.v.container.y : 0;
            const obstacleY = elementas.v.obstacleArea? elementas.v.obstacleArea.y : 0;
            return self.container.zIndex + containerY + obstacleY;
        }

        function __setXOrStulpelis(self, elementas, x, stulpelis){
            if (x !== undefined){
                elementas.v.container.x=x;
                return;
            }
            
            if (!self.scena || !self.scena.tileWidth)
                return;

            if (stulpelis !== undefined)
                elementas.v.container.x = self.scena.tileWidth * stulpelis;
        }

        function __setYOrEilute(self, elementas, y, eilute){
            if (y !== undefined){
                elementas.v.container.y=y;
                return;
            }
            
            if (!self.scena || !self.scena.tileHeight)
                return;

            if (eilute !== undefined)
                elementas.v.container.y = self.scena.tileHeight * eilute;
        }

        function __isOutOfBounds(x, y){
            return (x <0 && y< 0) || (x === undefined && y === undefined);
        }

        function __isObstacleSet(element){
            if (!element || !element.v || !element.v.obstacleArea)
                return false;
                
            return element.v.obstacleArea.x || element.v.obstacleArea.y || element.v.obstacleArea.width || element.v.obstacleArea.height;
        }

        function __intersects(elementA, nLeftX, nLeftY, elementB){
            if (!Pixi.isVisible(elementA) || !Pixi.isVisible(elementB))
                return false;

            if (elementA.v.layer !== elementB.v.layer)
                return;

            if (!__isObstacleSet(elementA) || !__isObstacleSet(elementB))
                return false;

            const rectA = {
                x: elementA.v.container.x + elementA.v.obstacleArea.x, 
                y: elementA.v.container.y + elementA.v.obstacleArea.y,
                w: elementA.v.obstacleArea.width,
                h: elementA.v.obstacleArea.height
            };

            const rectB = {
                x: nLeftX + elementB.v.obstacleArea.x, 
                y: nLeftY + elementB.v.obstacleArea.y,
                w: elementB.v.obstacleArea.width,
                h: elementB.v.obstacleArea.height
            };

            if (rectA.x + rectA.w < rectB.x)
                return false;

            if (rectB.x + rectB.w < rectA.x)
                return false;

            if (rectA.y + rectA.h < rectB.y)
                return false;
                
            if (rectB.y + rectB.h < rectA.y)
                return false;
                
            return true;
        }

        function __isAt(element, x1, y1){
            if (!element || !element.v || !element.v.visible || !element.v.container.visible)
                return false;

            let elementArea = element.v.clickableArea || element.v.hitArea;

            const { x, y, width, height } = elementArea;

            const tx = element.v.container.x + x;
            const ty = element.v.container.y + y;

            return x1 >= tx && x1 < tx + width && 
                   y1 >= ty && y1 < ty + height;
        }

        function __isAround(element, x1, y1, dx, dy ){
            if (!element || !element.v || !element.v.visible || !element.v.container.visible)
                return false;

            const { x, y, width, height } = element.v.hitArea;
            const elDimensions = { x: element.v.container.x + x, 
                                   y: element.v.container.y + y, 
                                   width, 
                                   height
                                 };
            return Pixi.isColliding(elDimensions, 
                                            { x: x1, 
                                              y: y1, 
                                              width: dx, 
                                              height: dy });
        }

        function __isInZone(element, zona ){
            if (!element || !element.v || !element.v.visible || !element.v.container.visible)
                return false;

            const { x, y, width, height } = element.v.hitArea;
            const elDimensions = { x: element.v.container.x + x, 
                                   y: element.v.container.y + y, 
                                   width, 
                                   height
                                 };

            const x1 = zona[0];
            const y1 = zona[1];
            const kryptis = zona[2];
            const plotis  = zona[3];
            const ilgis   = zona[4];

            const p1 = //__convertToPolygon(elDimensions.x, elDimensions.y, elDimensions.width, elDimensions.height, 0);
                    [{x: elDimensions.x, y: elDimensions.y}, 
                    {x: elDimensions.x, y: elDimensions.y + height}, 
                    {x: elDimensions.x + elDimensions.width, y: elDimensions.y + height}, 
                    {x: elDimensions.x + elDimensions.width, y: elDimensions.y}];

            const p2 = __convertToPolygon(x1, y1, plotis, ilgis, kryptis);
            return  __doPolygonsIntersect(p1, p2);
        }


        function __convertToPolygon(x, y, width, height, ang){
              ang = (90 - ang) * (Math.PI / 180); // convert to rads
        
              var sinAng = Math.sin(ang);	
              var cosAng = Math.cos(ang);
              const halfW = width / 2;
        
              var upDiff = sinAng * halfW;
              var sideDiff = cosAng * halfW;
                    
              var first = {x: x + sideDiff, y: y + upDiff};
              var sec   = {x: x - sideDiff, y: y - upDiff};
              
              var upDiff2   = cosAng * height;
              var sideDiff2 = sinAng * height;
              var third =  {x: first.x + sideDiff2, y: first.y - upDiff2};
              
              var fourth = {x: sec.x + sideDiff2, y: sec.y - upDiff2};
        
              return [first, sec, fourth, third];
        }

        ////
        // @param a an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
        // @param b an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
        // @return true if there is any intersection between the 2 polygons, false otherwise

        function __doPolygonsIntersect (a, b) {
            var polygons = [a, b];
        
            for (var i = 0; i < polygons.length; i++) {
                var polygon = polygons[i];
                for (var i1 = 0; i1 < polygon.length; i1++) {
        
                    var i2 = (i1 + 1) % polygon.length;
        
                    var normal = { x: polygon[i2].y - polygon[i1].y, 
                                   y: polygon[i1].x - polygon[i2].x };
        
                    var minA = undefined;
                    var maxA = undefined;
        
                    for (var j = 0; j < a.length; j++) {
                        var projected = normal.x * a[j].x + normal.y * a[j].y;
                        if (minA == null || projected < minA)
                            minA = projected;
        
                        if (maxA == null || projected > maxA)
                            maxA = projected;
                    }
        
                    var minB = undefined;
                    var maxB = undefined;
        
                    for (var j = 0; j < b.length; j++) {
                        var projected = normal.x * b[j].x + normal.y * b[j].y;
        
                        if (minB == null || projected < minB)
                            minB = projected;
        
                        if (maxB == null || projected > maxB) 
                            maxB = projected;
                    }
        
                    if (maxA < minB || maxB < minA)
                        return false;
                }
            }
            return true;
        };

    }, "Sluoksnis", []);

})();
