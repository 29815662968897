Sk.VPŽaidimas = Sk.VPŽaidimas || {};

(function () {
    const Pixi = Sk.Angis.Pixi;
    const Utils = Sk.Angis.Utils;

    // a Daiktas with extra features. 
    Sk.VPŽaidimas.Veikėjas = (mod) => Sk.misceval.buildClass(mod, function ($gbl, $loc) {

        $loc.__init__ = Utils.createMethodKWA(function (kwa) {
            const { self, failoVardas, x, y, tag } = Utils.mapArgs(arguments, kwa, [{ failoVardas: null }, { x: -1 }, { y: -1 }, { tag: undefined }]);

            return VeikėjasConstructor(self, failoVardas, x, y, tag);
        });

        // collision API        
        $loc.susidūrus = Utils.createMethodKWA(onCollision);
        // liečia(daiktas)

        // universal move API
        $loc.judėk = Utils.createMethodKWA(move);
        $loc.move =$loc.judėk;

        $loc.keliaukĮ = Utils.createMethodKWA(walkTo);
        $loc.moveTo = $loc.keliaukĮ;

        $loc.stok = Utils.createMethod(stop);
        $loc.stop = $loc.stok;

        $loc.įsiminkAnimaciją = Utils.createMethodKWA(registerMoveAnimation);
        $loc.animuok = $loc.įsiminkAnimaciją; // legacy! should be removed, name is misleading
        $loc.registerAnimation = $loc.įsiminkAnimaciją;

        $loc.grok = Utils.createMethodKWA(playAnimationBlocking);
        $loc.rodykAnimaciją = $loc.grok;
        $loc.play = $loc.grok;
        $loc.showAnimation = $loc.grok;

        $loc.grokFone = Utils.createMethodKWA(playAnimationInBackground);
        $loc.rodykAnimacijąFone = $loc.grokFone;
        $loc.playInBackground = $loc.grokFone;
        $loc.showAnimationInBackground = $loc.grokFone;

        // more specialised move functions => 'turtle grapchis' API
        $loc.greitis = Utils.createMethodKWA(setMoveSpeed);
        $loc.speed = $loc.greitis;

        $loc.žingsnioIlgis = Utils.createMethodKWA(setStepSize);
        $loc.stepSize = $loc.žingsnioIlgis;

        $loc.žiūrėk = Utils.createMethodKWA(setFaceDirection);
        $loc.žiūrėkĮ = $loc.žiūrėk;

        $loc.pasisuk = Utils.createMethodKWA(turnTo);
        $loc.pasisukĮ = Utils.createMethodKWA(turnTo);
        $loc.turnTo = $loc.pasisuk;

        $loc.judėjimoKryptis = Utils.createMethodKWA(setMoveDirection);
        $loc.setMoveDirection = $loc.judėjimoKryptis;

        // override
        $loc.sukis  = Utils.createMethodKWA(rotate);
        $loc.rotate = $loc.sukis;

        $loc.pirmyn = Utils.createMethodKWA(forward);
        $loc.priekin = $loc.pirmyn;
        $loc.forward = $loc.pirmyn;
        $loc.fw = $loc.pirmyn;

        $loc.atgal = Utils.createMethodKWA(back);
        $loc.back = $loc.atgal;

        $loc.kairėn = Utils.createMethodKWA(left);
        $loc.kairen = $loc.kairėn;
        $loc.left = $loc.kairėn;

        $loc.dešinėn = Utils.createMethodKWA(right);
        $loc.desinen = $loc.dešinėn;
        $loc.right = $loc.dešinėn;

        $loc.rodykVektorių = Utils.createMethod(showVector);
        $loc.showVector = $loc.rodykVektorių;
        $loc.slėpkVektorių = Utils.createMethod(hideVector);
        $loc.hideVector = $loc.slėpkVektorių;

        $loc.duokKoordinates = Utils.createMethod(getCoordinates);
        $loc.getCoordinates = $loc.duokKoordinates;
        $loc.duokPuolimoZoną = Utils.createMethodKWA(getAttackArea);
        $loc.getAttackArea  = $loc.duokPuolimoZoną;


        $loc.naudokPaveiksliuką = Utils.createMethodKWA(naudokPaveiksliuką);
        $loc.fonas = $loc.naudokPaveiksliuką;
        $loc.background = $loc.naudokPaveiksliuką;

        // overriden methods
        $loc.prisikabinkPriePelės = Utils.createMethod(attachToMouse);
        $loc.attachToMouse = $loc.prisikabinkPriePelės;

    }, "Veikėjas", [mod.Daiktas]);

    Sk.VPŽaidimas.Veikėjas.ctor = VeikėjasConstructor;

    function VeikėjasConstructor(self, failoVardas, x, y, tag) {
        function __initialize(){
            Sk.VPŽaidimas.Daiktas.ctor(self, failoVardas, x, y, tag);

            self.moveSpeed = 3;
            self.stepSize = 10;
            self.moveDistance = 0;
            self.moveDirection = 0;
            self.faceDirection = 0;

            self.obstacleArea = {
                x: self.hitArea.width / 4,
                y: self.hitArea.height * 2 / 3,
                width: self.hitArea.width / 2,
                height: self.hitArea.height / 3
            };

            self.sprite.pivot.x = self.obstacleArea.x + self.obstacleArea.width / 2;
            self.sprite.pivot.y = self.obstacleArea.y + self.obstacleArea.height / 2;
            self.sprite.x = self.sprite.pivot.x;
            self.sprite.y = self.sprite.pivot.y;

            self.activity = "idle"; // can be "idle" "walk" "fight" "die" - used for animations

            self.currentAnimation = null; // Angis Animation object
            self.animations = {};   // all registered Angis Animation objects

            // virtual (overriden) methods - see Plytelė c'tor
            self.show = () => show(self);
            self.hide = () => hide(self);
            self.isVisible = function(){ return self.container.visible; };
            self.teleportTo = (newX, newY) => teleportTo(self, newX, newY);

            self.collisionTargets = [];

            Utils.setInterval(function() { __rememberToIdle(self); }, 6000);

            if (Sk.tracing) {
                Sk.tracer.emit('veikėjas:new', { target: self }, SKTRACEID);
            }
        }

        if (Pixi.hasResource(failoVardas))
            return __initialize();

        return Utils.createSuspension( 
            Pixi.getResource(failoVardas).then(__initialize)
        );
    }

    function attachToMouse(self) {
        stop(self);
        Sk.VPŽaidimas.Daiktas.attachToMouse(self);
    }

    function naudokPaveiksliuką(kwa){
        const { self, failoVardas } = Utils.mapArgs(arguments, kwa, [{ failoVardas: "" }]);

        if (Sk.tracing) {
            Sk.tracer.emit('veikėjas:useImage', { target: self, failoVardas: failoVardas }, SKTRACEID);
        }

        if (!failoVardas || !failoVardas.length)
            return Sk.builtin.none$;

        __stopCurrentAnimation(self);

        self.failoVardas = failoVardas;
        Pixi.getTexture(failoVardas).then(texture => self.sprite.texture = texture);

        return Sk.builtin.none$;
    }

    function registerMoveAnimation(kwa) {
        let { self, animacija, judesys } = Utils.mapArgs(arguments, kwa, [{ animacija: null }, { judesys: 'idle' }]);

        if (animacija != null && judesys != null)
            self.animations[judesys] = animacija;
    }

    function playAnimationInBackground(kwa){
        let { self, pavadinimas, greitis } = Utils.mapArgs(arguments, kwa, [{ pavadinimas: 'idle' }, { greitis: null }]);

        pavadinimas = pavadinimas || 'idle';

        const animation = __getAnimation(self.animations, self.faceDirection, pavadinimas);
        if (!animation)
            return;
        self.activity = pavadinimas;

        __stopCurrentAnimation(self);
        __clearActivityTimeout(self);
        self.currentAnimation = animation;
        self.sprite.visible = false;
        if (greitis)
            self.currentAnimation.animationSpeed = greitis;
        self.currentAnimation.attachTo(self, 0, 0);
        self.currentAnimation.stop();
        self.currentAnimation.playInBackground(false);
    }

    function playAnimationBlocking(kwa){
        // same as playInBackground
        let { self, pavadinimas, greitis } = Utils.mapArgs(arguments, kwa, [{ pavadinimas: 'idle' }, { greitis: null }]);

        pavadinimas = pavadinimas || 'idle';

        const animation = __getAnimation(self.animations, self.faceDirection, pavadinimas);
        if (!animation)
            return;

         self.activity = pavadinimas;
         
        __stopCurrentAnimation(self);
        __clearActivityTimeout(self);
        self.currentAnimation = animation;
        self.sprite.visible = false;
        self.currentAnimation.attachTo(self, 0, 0);

        self.currentAnimation.stop();
        if (greitis)
            self.currentAnimation.animationSpeed = greitis;

        // ... only at the end we're calling a blocking method animation.play()
        return self.currentAnimation.play(false);
    }

    function setMoveSpeed(kwa) {
        let { self, greitis } = Utils.mapArgs(arguments, kwa, [{ greitis: null }]);

        if (greitis != null)
            self.moveSpeed = __limitTo(greitis, 0, 20);

        return Sk.builtin.int_(self.stepSize);
    }

    function setStepSize(kwa){
        let { self, atstumas } = Utils.mapArgs(arguments, kwa, [{ atstumas: null }]);

        if (atstumas != null)
            self.stepSize = __limitTo(atstumas, 0, 200);

        __redrawVector(self);

        return Sk.builtin.int_(self.stepSize);
    }

    function hide(self) {
        __stopCurrentAnimation(self);
        self.container.visible = false;
        self.container.interactive = false;
        self.hideZones();
    }

    function show(self) {
        const spriteIsVisible = self.sprite.visible;
        const animationIsVisible = !!self.currentAnimation;

        if (self.container.visible && (spriteIsVisible || animationIsVisible))
            return;

        self.sprite.visible = (!self.currentAnimation);
        self.container.interactive = true;
        self.container.visible = true;

        __setActivity(self, "idle", 0);
    }

    function showVector(self) {
        if (self.vector)
            return;

        self.vector = new PIXI.Graphics();
        
        __drawVectorArrow(self.vector, self.stepSize);

        self.vector.pivot.x = 0;
        self.vector.pivot.y = 15;
        self.vector.position.x = self.obstacleArea.x + (self.obstacleArea.width / 2);
        self.vector.position.y = self.obstacleArea.y + (self.obstacleArea.height / 2);

        self.vector.angle = -1* self.faceDirection;

        self.container.addChild(self.vector);
    }

    function __drawVectorArrow(graphics, length){
        graphics.lineStyle(2, 0xeeFFee, 0.75);
        graphics.beginFill(0xeeeeee, 0.1);

        graphics.drawPolygon(
                      [0, 0,
                       length, 0,
                       length, -5,
                       length + 20, 15,
                       length, 35,
                       length, 30,
                       0, 30]);

        graphics.endFill();
    }

    function __redrawVector(self) {
        if (!self.vector)
            return;

        self.vector.clear();
        __drawVectorArrow(self.vector, self.stepSize);
    }

    function hideVector(self) {
        if (!self.vector)
            return;

        self.vector.clear();
        self.container.removeChild(self.vector);
        self.vector = null;
    }

    function getCoordinates(self) {
        return new Sk.builtin.tuple([
            new Sk.builtin.int_(self.container.x),
            new Sk.builtin.int_(self.container.y)
        ]);
    }

    function getAttackArea(kwa){
        const { self, plotis, ilgis } = Utils.mapArgs(arguments, kwa, [{ plotis: 25 }, { ilgis: 50 }]);

        const xx = self.container.x + self.obstacleArea.x + (self.obstacleArea.width/2);
        const yy = self.container.y + self.obstacleArea.y + (self.obstacleArea.height/2);

        return new Sk.builtin.tuple([
            new Sk.builtin.int_(xx),
            new Sk.builtin.int_(yy),
            new Sk.builtin.float_(self.faceDirection),
            new Sk.builtin.float_(plotis),
            new Sk.builtin.float_(ilgis)
        ]);
    }

    function onCollision(kwa) {
        const { self, daiktas, veiksmas } = Utils.mapArgs(arguments, kwa, [{ daiktas: null }, { veiksmas: null }]);
        self.collisionTargets.push([daiktas, veiksmas]);
    }

    function __triggerCollisions(self, obstacles) {
        var result = false;

        self.collisionTargets.forEach(([target, callback]) => {
            if (!target || !target.container || !target.hitArea || !Pixi.isVisible(target))
                return;

            if (target.v.layer !== self.v.layer)
                return;

            const me =  { 
                            x: self.container.x + self.hitArea.x, 
                            y: self.container.y + self.hitArea.y, 
                            width: self.hitArea.width, 
                            height: self.hitArea.height 
                        };
            const you = { 
                            x: target.container.x + target.hitArea.x, 
                            y: target.container.y + target.hitArea.y,
                            width: target.hitArea.width, 
                            height: target.hitArea.height 
                        };

            if (__includes(obstacles, target) || Pixi.isColliding(me, you) ) {
                const { x, y } = self.container;

                if (Sk.tracing) {
                    Sk.tracer.emit('veikėjas:collision', { target: self, other: target }, SKTRACEID);
                }

                const pyX = new Sk.builtin.int_(x);
                const pyY = new Sk.builtin.int_(y);

                Sk.misceval.applyAsync(undefined, callback, undefined, undefined, undefined, [target, pyX, pyY]);

                result = true;
            }
        });

        return result;
    }

    //////////
    // turn / set-face-direction stuff

    function setMoveDirection(kwa){
        let { self, laipsniai, x, y } = Utils.mapArgs(arguments, kwa, [{ laipsniai: null }, {x: null}, {y: null}]);

        if (laipsniai === null && x !== null && y !== null)
            laipsniai = __xy2degrees(x - self.obstacleArea.x - self.container.x, y - self.obstacleArea.y - self.container.y);

        const newDirection = Utils.normalizeAngle(Utils.normalizeAngle(laipsniai));
        self.moveDirection = newDirection;

        if (self.vector)
            self.vector.angle = -1* newDirection;

        __updateCurrentAnimation(self);
    }

    function setFaceDirection(kwa) {
        let { self, laipsniai, x, y } = Utils.mapArgs(arguments, kwa, [{ laipsniai: null }, {x: null}, {y: null}]);

        if (Sk.tracing) {
            Sk.tracer.emit('veikėjas:look', { target: self, degrees: laipsniai, label: 'look' }, SKTRACEID);
        }

        if (laipsniai === null && x !== null && y !== null)
            laipsniai = __xy2degrees(x - self.obstacleArea.x - self.container.x, y - self.obstacleArea.y - self.container.y);

        return __setFaceDirection(self, Utils.normalizeAngle(laipsniai));
    }

    function __setFaceDirection(self, newDirection){
        newDirection = Utils.normalizeAngle(newDirection);
        self.faceDirection = newDirection;
        self.moveDirection = newDirection;

        self.sprite.angle = -1* newDirection;
        if (self.vector)
            self.vector.angle = self.sprite.angle;

        __updateCurrentAnimation(self);
    }

    function turnTo(kwa) {
        let { self, laipsniai } = Utils.mapArgs(arguments, kwa, [{ laipsniai: 270 }]);

        if (Sk.tracing) {
            Sk.tracer.emit('veikėjas:turn', { target: self, degrees: laipsniai, label: 'turn' }, SKTRACEID);
        }

        laipsniai = Utils.normalizeAngle(laipsniai);

        const delta = Math.abs(laipsniai - self.faceDirection);
        const clockwise = laipsniai > self.faceDirection ? 1 : -1;

        return __turnBy(self, clockwise * delta);
    }

    function rotate(kwa){
        let { self, laipsniai } = Utils.mapArgs(arguments, kwa, [{ laipsniai: 270 }]);

        if (Sk.tracing) {
            Sk.tracer.emit('veikėjas:rotate', { target: self, degrees: laipsniai, label: 'rotate' }, SKTRACEID);
        }

        laipsniai = Utils.normalizeAngle(laipsniai);

        const delta = laipsniai;
        const clockwise = laipsniai > 0 ? 1 : -1;

        return __turnBy(self, clockwise * delta);
    }

    function __turnBy(self, degrees){
        // "blocking turn"
        return Sk.misceval.promiseToSuspension(new Promise(function (resolve) {
            __setTurn(self, __createLimitedTurnFunction(self, self.faceDirection, degrees, self.moveSpeed * 2, resolve));
        }));
    }

    function __cancelTurn(self) {
        if (!self.turnFn)
            return;

        const fn = self.turnFn;
        self.turnFn = null;
        Pixi.removeTicker(fn);
    }

    function __setTurn(self, fn) {
        __cancelTurn(self);

        self.turnFn = fn;
        Pixi.addTicker(fn);
    }

    function __createLimitedTurnFunction(self, currentDegrees, degrees, speed, resolve){
        if (!degrees)
            return function(){ resolve(); }

        const targetDegrees = currentDegrees + degrees;
        const clockwise = degrees < 0 ? -1 : 1;

        return function(){
            let delta = Math.abs(targetDegrees - currentDegrees);
            if (delta <= speed){     
                __setFaceDirection(self, targetDegrees);
                __cancelTurn(self);
                resolve();
                return;
            }

            currentDegrees += clockwise * speed;
            __setFaceDirection(self, currentDegrees);
        };
    }

    //////
    // move stuff

    function move(kwa) {
        let { self, atstumas, kampas, greitis, dx, dy, animacija } =
            Utils.mapArgs(arguments, kwa, [{ atstumas: null }, { kampas: null }, { greitis: null }, { dx: null }, { dy: null }, {animacija: null}]);

        if (greitis === null)
            greitis = self.moveSpeed;

        if (!greitis)
            return;

        greitis = __limitTo(greitis, 0, 20);

        if (kampas === null)
            if (dx || dy)
                kampas = __xy2degrees(dx, dy);
            else
                kampas = self.moveDirection;

        kampas = Utils.normalizeAngle(kampas);

        return __walk(self, kampas, atstumas, greitis, animacija || 'walk');
    }

    function walkTo(kwa) {
        let { self, x, y, greitis, animacija } =
            Utils.mapArgs(arguments, kwa, [{ x: null }, { y: null }, { greitis: null }, {animacija: null}]);

        greitis = greitis || self.moveSpeed || 10;

        greitis = __limitTo(greitis, 0, 20);

        x = x || self.container.x;
        y = y || self.container.y;

        const dx = x - self.container.x;
        const dy = y - self.container.y;
        const atstumas = Math.sqrt(dx*dx + dy*dy);

        let kampas = __xy2degrees(dx , dy);
        kampas = Utils.normalizeAngle(kampas);

        __setFaceDirection(self, kampas);

        return __walk(self, kampas, atstumas, greitis, animacija || 'walk');
    }

    function forward(kwa) {
        let { self, žingsniai, greitis } = Utils.mapArgs(arguments, kwa, [{ žingsniai: 1 }, { greitis: null }]);

        self.moveSpeed = __limitTo(Math.abs(greitis), 0, 20) || self.moveSpeed;
        self.moveDistance = __limitTo(žingsniai * self.stepSize, -1000, 1000);

        return __walk(self, self.moveDirection, self.moveDistance, self.moveSpeed, 'walk');
    }

    function back(kwa) {
        let { self, žingsniai, greitis } = Utils.mapArgs(arguments, kwa, [{ žingsniai: 1 }, { greitis: null }]);

        self.moveSpeed = __limitTo(Math.abs(greitis), 0, 20) || self.moveSpeed;
        self.moveDistance = __limitTo(žingsniai * self.stepSize, -1000, 1000);

        return __walk(self, self.moveDirection, -1 * self.moveDistance, self.moveSpeed, 'walk');
    }

    function left(kwa) {
        let { self, laipsniai } = Utils.mapArgs(arguments, kwa, [{ laipsniai: 90 }]);

        if (Sk.tracing) {
            Sk.tracer.emit('veikėjas:look', { target: self, degrees: laipsniai, label: 'left' }, SKTRACEID)
        }

        return __turnBy(self, laipsniai);
    }

    function right(kwa) {
        let { self, laipsniai } = Utils.mapArgs(arguments, kwa, [{ laipsniai: 90 }]);

        if (Sk.tracing) {
            Sk.tracer.emit('veikėjas:look', { target: self, degrees: laipsniai, label: 'right' }, SKTRACEID)
        }

        return __turnBy(self, -1* laipsniai);
    }

    function stop(self) {
        if (Sk.tracing) {
            Sk.tracer.emit('veikėjas:stop', { target: self }, SKTRACEID)
        }

        __cancelMove(self);
        __cancelTurn(self);
        __setActivity(self, "idle", 100);
    }

    function __walk(self, degrees, distance, speed, label) {
        if (Sk.tracing) {
            Sk.tracer.emit('veikėjas:walk', { target: self, degrees: degrees, distance: distance, speed: speed, label: label }, SKTRACEID)
        }

        if (distance) {

            // "blocking move"
            return Sk.misceval.promiseToSuspension(new Promise(function (resolve) {
                __setMove(self, __createLimitedWalkFunction(self, degrees, distance, speed, label), resolve);
            }));
        }

        // "infinite, non-blocking" move
        __setMove(self, __createInfiniteWalkFunction(self, degrees, speed));
    }

    function __cancelMove(self) {
        if (!self.moveFn)
            return;

        const resolve = self.moveResolution;
        self.moveResolution = null;

        const fn = self.moveFn;
        self.moveFn = null;
        if (fn)
            Pixi.removeTicker(fn);

        __setActivity(self, "idle", 100);

        if (resolve)
            resolve();
    }

    function __setMove(self, fn, resolve) {
        __cancelMove(self);

        self.moveResolution = resolve;
        self.moveFn = fn;
        if (fn)
            Pixi.addTicker(fn);
    }

    function teleportTo(self, newX, newY) {
        __setActivity(self, "idle", 100);

        self.show();

        if (newX == null && newY == null)
            return true; // TODO: shoulnd't it be 'return false'? cannot  move to undefined coordinates!

        if (!self.layer){
            console.error("no layer!");
            return false;
        }

        const foundConflicts = self.layer.getTeleportConflicts(self, newX, newY);
        if (foundConflicts.length){
            __triggerCollisions(self, foundConflicts);
            return false;
        }

        self.moveTo(newX, newY);

        if (__triggerCollisions(self))
            return true;

        return true;
    }

    function __createLimitedWalkFunction(self, degrees, distance, speed, activity) {
        const container = self.container;

        container.moveDirection = degrees;

        const startX = container.x;
        const startY = container.y;
        const rads = degrees / 180 * Math.PI;
        const cos = Math.cos(rads);
        const sin = Math.sin(rads);

        const endX = startX + distance * cos;
        const endY = startY - distance * sin;

        const vector = distance > 0 ? 1 : -1;
        distance = Math.abs(distance);

        const dx = vector * speed * cos;
        const dy = vector * speed * sin;

        __setActivity(self, activity);

        let step = 0;

        return function () {

            if (__hasAchieved(endX, endY, container.x, container.y)) {
                __cancelMove(self);
                __setActivity(self, "idle", 100);
                return;
            }

            step++;

            let newX = __advance(startX, step * dx, endX);
            let newY = __advance(startY, - step * dy, endY);
            const success = self.teleportTo(newX, newY);

            if (Sk.tracing) {
                Sk.tracer.emit('veikėjas:walk:step', { target: self, x: newX, y: newY, success: success }, SKTRACEID);
            }

            if (!success) {
                __cancelMove(self);
                __setActivity(self, "idle", 50);
                return;
            }
        }

        function __hasAchieved(endX, endY, currentX, currentY){
            if (endX === currentX && endY === currentY)
                return true;

            return Math.abs(currentX - endX) <=0.5 && Math.abs(currentY - endY) <= 0.5;
        }

        function __advance(start, delta, end){
            const plannedEnd = start + delta;
            if (delta > 0)
                return plannedEnd > end ? end : plannedEnd;

            if (delta === 0)
                return end;

            return plannedEnd < end ? end : plannedEnd;
        }
    }

    function __createInfiniteWalkFunction(self, degrees, speed) {
        const container = self.container;

        container.moveDirection = degrees;

        const startX = container.x;
        const startY = container.y;

        let step = 0;
        const rads = degrees / 180 * Math.PI;
        const dx = speed * Math.cos(rads);
        const dy = speed * Math.sin(rads);

        __setActivity(self, "walk");

        return function () {
            step++;

            let newX = startX + step * dx;
            let newY = startY - step * dy;
            const success = self.teleportTo(newX, newY);

            if (Sk.tracing) {
                Sk.tracer.emit('veikėjas:walk:step', { target: self, x: newX, y: newY, success: success }, SKTRACEID);
            }

            __triggerCollisions(self);

            if (!success) {
                __cancelMove(self);
                __setActivity(self, "idle", 50);
                return;
            }
        }
    }

    /////
    // animation stuff

    function __setActivity(self, activityName, afterMsec) {
        if (self.activity === activityName)
            return;

        __clearActivityTimeout(self);

        self.activityTimeout = Utils.setTimeout(function(){
                                                self.activity = activityName;
                                                __clearActivityTimeout(self);
                                                __updateCurrentAnimation(self);
                                            }, afterMsec);
    }

    function __clearActivityTimeout(self){
        if(!self.activityTimeout)
            return;

        Utils.clearTimeout(self.activityTimeout);
        self.activityTimeout = null;
    }

    function __updateCurrentAnimation(self){
        const animation = __getAnimation(self.animations, self.faceDirection, self.activity);
        if (self.currentAnimation === animation)
            return;

        __stopCurrentAnimation(self);
        __setCurrentAnimation(self, animation);
    }

    function __setCurrentAnimation(self, animation){
        if (!animation) {
            self.sprite.visible = true;
            return;
        }

        self.currentAnimation = animation;
        self.sprite.visible = false;

        let loop = false;
        if (self.activity === "walk" || self.activity === "run")
            loop = true;

        self.currentAnimation.attachTo(self, 0, 0);
        self.currentAnimation.playInBackground(loop);
    }

    function __stopCurrentAnimation(self) {
        if (!self.currentAnimation){
            self.sprite.visible = true;
            return;
        }

        self.currentAnimation.stop();
        self.currentAnimation.hide();
        self.currentAnimation.detachFrom(self);
        self.currentAnimation = null;
        self.stopper = null;
    }

    function __rememberToIdle(self){
        if (!self.container.visible)
            return;

        if (self.container.x < 0 && self.container.y < 0)
            return;

        if (self.activityTimeout)
            return;

        if (self.activity !== "idle")
            return;

        const animation = __getAnimation(self.animations, self.faceDirection, "idle");

        __stopCurrentAnimation(self);
        __setCurrentAnimation(self, animation);
    }

    function __getAnimation(animations, faceDirection, activity) {
        let prefix = activity;
        if (prefix === "walk")
            prefix = "";
        else
            prefix = (prefix || "idle") + "_" ;

        if (__isWithin(faceDirection, -15, 23) || __isWithin(faceDirection, 337, 360))
            return animations[prefix + "east"] || animations[activity] || animations["idle"];

        if (__isWithin(faceDirection, 23, 67))
            return animations[prefix + "northEast"] || animations[prefix + "east"] || animations[activity] || animations["idle"];

        if (__isWithin(faceDirection, 67, 113))
            return animations[prefix + "north"] || animations[activity] || animations["idle"];

        if (__isWithin(faceDirection, 113, 157))
            return animations[prefix + "northWest"] || animations[prefix + "west"] || animations[activity] || animations["idle"];

        if (__isWithin(faceDirection, 157, 203))
            return animations[prefix + "west"] || animations[activity] || animations["idle"];

        if (__isWithin(faceDirection, 203, 247))
            return animations[prefix + "southWest"] || animations[prefix + "west"] || animations[activity] || animations["idle"];

        if (__isWithin(faceDirection, 247, 292))
            return animations[prefix + "south"] || animations[activity] || animations["idle"];

        if (__isWithin(faceDirection, 292, 337))
            return animations[prefix + "southEast"] || animations[prefix + "east"] || animations[activity] || animations["idle"];

        console.error("direction error: degree is ", faceDirection);
        return animations[activity] || animations["idle"];
    }

    function __isWithin(value, min, max) {
        return value >= min && value <= max;
    }


    ///////////
    // math stuff

    function __xy2degrees(dx, dy) {
        let ret;

        if (dx)
            ret = Math.atan2(-1 * dy, dx) * 180 / Math.PI;

        else
            ret =  (dy > 0) ? 270 : 90;

        return ret;
    }
    
    function __limitTo(value, min, max) {
        if (value === null)
            return value;

        if (value < min)
            return min;

        if (value > max)
            return max;

        return value;
    }

    function __includes(obstacles, target){
        if (!obstacles || !obstacles.length)
            return false;

        return obstacles.indexOf(target) >= 0;
    }

})();
